import { Theme } from '@mui/material';

export const MuiDataGrid = {
  defaultProps: {
    density: 'compact',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      border: 'none',
      borderRadius: 0,
      '& .MuiDataGrid-columnHeaders': {
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[500],
      },
      ' & .MuiDataGrid-pinnedColumnHeaders': {
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        backgroundColor: `${theme.palette.grey[50]} !important`,
        color: `${theme.palette.grey[500]} !important`,
      },
      '& .MuiDataGrid-cell:hover': {
        color: theme.palette.primary.main,
      },
    }),
  },
};
