import { useEffect } from 'react';

import { useIntercom } from 'react-use-intercom';
import { isEmpty } from 'lodash-es';
import { isProd, isTesting } from 'utils/environment';
import { useCurrentUser } from 'features/Auth/hook/session';

export const Init = () => {
  const { boot: bootIntercom, shutdown: shutdownIntercom } = useIntercom();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    // Whenever the user changes, shutdown Intercom and boot it again
    // tried to use the `update` method but it doesn't work. No intercom call is sent.
    shutdownIntercom();
    if (isProd() || isTesting()) {
      if (!isEmpty(currentUser)) {
        bootIntercom({
          name: `${currentUser.profile.name} ${currentUser.profile.last_name}`,
          email: currentUser.email,
          userId: currentUser.pk.toString(),
        });
      } else {
        // Anonymous user
        bootIntercom();
      }
    }
  }, [currentUser, bootIntercom, shutdownIntercom]);
  return null;
};
