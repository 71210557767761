import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { isEmpty } from 'lodash-es';
import { BaseUnitWithSavedComponentBaseUnits } from 'types/Component';
import { queryKeys } from 'utils/reactQuery';

type saveComponentBaseUnitsCountParams = {
  componentId: number;
  baseUnit: BaseUnitWithSavedComponentBaseUnits;
  milestones: { id: number }[];
  projectId: number;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const useSaveComponentBaseUnitsCount = (onSuccess = () => {}) => {
  // Definition: all the design milestones have the same base unit count.
  // At some point we'll implement the base unit per milestone.
  const queryClient = useQueryClient();

  const {
    mutate: saveComponentBaseUnitsCount,
    isLoading: isComponentBaseUnitsCountLoading,
  } = useMutation(
    ({
      componentId,
      baseUnit,
      milestones,
      projectId,
    }: saveComponentBaseUnitsCountParams) => {
      if (!componentId || !baseUnit || isEmpty(baseUnit) || !milestones) {
        throw Error('Expected projectId, componentId, baseUnit and milestones arguments');
      }

      return Promise.all(
        milestones.map(({ id }) => {
          const savedBaseUnit = baseUnit.savedBaseUnits?.find(
            (baseUnit) => baseUnit.design_milestone === id,
          );
          if (!savedBaseUnit) {
            // The base unit doesn't exists for this milestone. Create it.
            const endPoint = Resources.COMPONENT_BASE_UNITS.replace(
              '<int:pk>',
              componentId.toString(),
            );
            return ApiService.post(endPoint, {
              base_unit_count: baseUnit.count,
              design_milestone: id,
            });
          }
          // Update the base unit count for this milestone.
          const endPoint = Resources.COMPONENT_BASE_UNIT.replace(
            '<int:pk>',
            componentId.toString(),
          );
          const baseUnitEndPoint = endPoint.replace(
            '<base_unit:id>',
            savedBaseUnit.id.toString(),
          );

          return ApiService.put(baseUnitEndPoint, {
            base_unit_count: baseUnit.count,
            design_milestone: id,
          });
        }),
        // eslint-disable-next-line promise/always-return
      ).then(() => {
        queryClient.invalidateQueries(
          queryKeys.componentBaseUnitCount(componentId).details,
        );
        queryClient.invalidateQueries(queryKeys.project(projectId).components);
      });
    },
    { onSuccess },
  );

  return { saveComponentBaseUnitsCount, isComponentBaseUnitsCountLoading };
};
