export const Permissions = {
  VIEW_SUBTOTALS_FILE: 'view_subtotalsfile',
  CHANGE_SCENARIO_ITEM: 'change_scenarioitem',
  ADD_SCENARIO_SUB_ITEM: 'add_scenariosubitem',
  CHANGE_SCENARIO_SUB_ITEM: 'change_scenariosubitem',
  DELETE_SCENARIO_SUB_ITEM: 'delete_scenariosubitem',
  APPROVE_SCENARIO: 'approve_scenario',
  APPROVE_SCENARIO_ITEM: 'approve_scenarioitem',
  ADD_DESIGN_MILESTONE: 'add_designmilestone',
  CHANGE_DESIGN_MILESTONE: 'change_designmilestone',
  ADD_PROJECT: 'add_project',
  CHANGE_PROJECT: 'change_project',
  MANAGE_PROJECT: 'manage_project',
  ADD_COMPONENT: 'add_component',
  CHANGE_COMPONENT: 'change_component',
  DELETE_COMPONENT: 'delete_component',
  VIEW_MARKUP: 'view_markup',
  CHANGE_MARKUP: 'change_markup',
  COMPANY_VIEW_ALL_USER_PROFILES: 'company_view_all_userprofile',
  CHANGE_COMPANY: 'change_company',
  DELETE_USER: 'delete_usertenantpermissions',
  CHANGE_TVD_DIMENSION_MEMBER_PRAMS: 'change_dimensionmemberparams',
};
