import { useState, MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, SxProps, Theme, Tooltip } from '@mui/material';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { NotificationPopover } from 'features/Notification/components/NotificationPopover';
import {
  useMarkReadNotifications,
  useUnReadNotifications,
} from 'features/Notification/hooks/notifications';
import { Mixpanel } from 'services/Mixpanel';
import { joinSx } from 'utils/helpers';

type NotificationButtonProps = {
  sx: SxProps<Theme>;
};

export const NotificationButton = ({ sx }: NotificationButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const unReadNotifications = useUnReadNotifications();
  const { mutate: markReadNotifications } = useMarkReadNotifications();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    Mixpanel.click('Notifications icon', 'navigation bar');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (unReadNotifications && unReadNotifications.length > 0)
      markReadNotifications(unReadNotifications.map((n) => n.id));
  };

  const open = true;
  const id = open ? 'notification-popover' : undefined;
  return (
    <>
      <Tooltip title="Notifications" placement="bottom">
        <IconButton
          sx={joinSx(
            {
              position: 'relative',
            },
            sx,
          )}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faBell} />
          {unReadNotifications && unReadNotifications.length > 0 ? (
            <Box sx={{ color: 'cautionRed.500', position: 'absolute', right: 6, top: 0 }}>
              <FontAwesomeIcon icon={faCircle} size="2xs" width="8px" />
            </Box>
          ) : null}
        </IconButton>
      </Tooltip>
      <NotificationPopover id={id} anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
};
