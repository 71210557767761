import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Menu, MenuItem, SxProps, Theme, Tooltip } from '@mui/material';
import { faLifeRing } from '@fortawesome/pro-regular-svg-icons';
import { PrivateURL } from 'Urls';

type HelpIconButtonProps = {
  sx?: SxProps<Theme>;
};
export const HelpIconButton = (props: HelpIconButtonProps) => {
  const [menuElementRef, setMenuElementRef] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(menuElementRef);
  const handleUserButtonClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuElementRef(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setMenuElementRef(null);
  };

  return (
    <>
      <Tooltip title="Help" placement="bottom">
        <IconButton onClick={handleUserButtonClick} sx={props.sx}>
          <FontAwesomeIcon icon={faLifeRing} />
        </IconButton>
      </Tooltip>

      <Menu
        id="help-menu"
        anchorEl={menuElementRef}
        open={open}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <a href={PrivateURL.TERMS_AND_CONDITIONS} target="_blank">
          <MenuItem onClick={handleCloseUserMenu}>Terms and conditions</MenuItem>
        </a>
        <a href={PrivateURL.PRIVACY_POLICY} target="_blank">
          <MenuItem onClick={handleCloseUserMenu}>Privacy Policy</MenuItem>
        </a>
      </Menu>
    </>
  );
};
