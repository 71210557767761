import newLogo from 'assets/img/concntric-logo-dark.svg';
import smallLogo from 'assets/img/concntric-logo-sm-dark.svg';
import { useSidebar } from 'hooks/ui';
import { Collapse, ListItem } from '@mui/material';
import { Box } from '@mui/system';

export const LogoListItem = () => {
  const { isSidebarCollapsed } = useSidebar();
  return (
    <ListItem
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: (theme) => `0 ${theme.spacing(1)} ${theme.spacing(0.5)}`,
      }}
    >
      <Collapse orientation="horizontal" in={isSidebarCollapsed}>
        <Box
          component="img"
          src={smallLogo}
          alt="Small Concentric logo"
          sx={{ height: '2rem' }}
        />
      </Collapse>
      <Collapse orientation="horizontal" in={!isSidebarCollapsed} sx={{ pl: 1 }}>
        <Box
          component="img"
          src={newLogo}
          alt="Concentric logo"
          sx={{ height: '2.5rem' }}
        />
      </Collapse>
    </ListItem>
  );
};
