export const Resources = {
  // Authentication Resources.
  AUTH: 'api/token/',
  USER_SIGNUP: 'api/users/signup/',
  USER_INVITE: 'api/users/invite/',
  USER_RESET: 'api/users/reset/',
  REFRESH_AUTH: 'api/token/refresh/',
  CURRENT_USER: 'api/current_user/',
  CURRENT_USER_PROFILE: 'api/current_user/profile/',
  CURRENT_USER_COMPANIES: 'api/current_user/companies/',

  // Permission Roles
  PERMISSION_ROLES: '/api/roles_permissions/',
  PERMISSION_ROLE: '/api/roles/<int:pk>/',
  PERMISSION_ROLE_PERMISSIONS: '/api/roles/<int:pk>/permissions/',
  PERMISSION_ROLE_CLONE: '/api/roles/<int:pk>/clone/',

  // Access Scopes
  ACCESS_SCOPES: '/api/access_scopes/',
  ACCESS_SCOPE: '/api/access_scopes/<int:pk>/',

  // Settings
  SETTINGS: '/api/settings/<str:key>',
  FEATURE_FLAGS: '/api/flags',

  // Categories Resources.
  ALL_CATEGORIES: 'api/categories_2/',

  // User & Company Resources.
  COMPANY_BY_ID: 'api/companies/<int:pk>/',
  COMPANY_USERS: 'api/company/users/',
  COMPANY_USER: 'api/company/users/<int:user_id>',

  // Projects Resources.
  ALL_PROJECTS: 'api/v2/projects/',
  PROJECT_BY_ID: 'api/v2/projects/<int:pk>/',
  PROJECT_TEAM_CONTACT: 'api/project/<int:pk>/team/',
  FORESITE_COST_BY_ID_V2: 'api/v2/foresite-cost/<int:pk>/',
  FORESITE_PROGRAM_BY_ID_V2: 'api/v2/foresite-program/<int:pk>/',
  FORESITE_SUMMARY_BY_ID: 'api/foresite-summary/<int:pk>/',
  PROJECT_COMPARISON_DETAIL_BY_ID: 'api/calibrate/detail/<int:project_id>/',
  PROJECT_COMPARISON_BY_ID: 'api/calibrate/<int:project_id>/',
  PROJECT_COMPARE_COSTS: 'api/projects/compare',
  PROJECT_UPDATE_STATUS: '/api/v2/projects/<int:project_id>/update-status/',
  PROJECT_DETAIL_FIELD: '/api/v2/third-party/',

  NORMALIZATION_LOCATION: '/api/normalization/location/<int:city_id>/',
  NORMALIZATION_TIMES: '/api/normalization/times/<int:project_id>/',
  SAVE_NORMALIZATION_TIMES: '/api/normalization/times/',

  NOTIFICATIONS: '/api/notifications/',
  NOTIFICATIONS_READ: '/api/notifications/read/',

  // Target Value Design
  TVD_FORESITE_BY_ID: 'api/tvd/foresite/<int:pk>/',
  TVD_CALIBRATE_BY_ID: 'api/tvd/calibrate/<int:pk>/',
  TVD_DIMENSION_MEMBER_PARAMS:
    'api/tvd/<int:project_pk>/dimension-member-params/<str:dimension_type>/<int:dimension_member_id>/',
  TVD_DIMENSION_MEMBERS_PARAMS:
    'api/tvd/<int:project_pk>/dimension-member-params/<str:dimension_type>/',
  TVD_DIMENSIONS_USAGE: 'api/tvd/<int:project_pk>/dimensions-data-status/',
  TVD_COST_GROUP_SYSTEMS: 'api/tvd/cost-group-systems/',

  // Legacy projects
  PROJECT_LEGACY: 'api/v2/projects/legacy/',
  PROJECT_LEGACY_BY_ID: 'api/v2/projects/legacy/<int:pk>/',

  // Project members Resources.
  PROJECT_TEAM_INVITE: 'api/project/<int:project_pk>/team/invite/',
  PROJECT_MEMBER_BY_ID: 'api/project/<int:project_pk>/team/<int:user_pk>/',

  // Scenarios Resources.
  ALL_SCENARIOS_BY_PROJECT_ID: 'api/scenarios/<int:project_pk>/',
  SCENARIO_BY_ID: 'api/scenario/<int:pk>/',
  APPROVE_SCENARIO_BY_ID: 'api/scenario-approve/<int:pk>/',
  REJECT_SCENARIO_BY_ID: 'api/scenario-reject/<int:pk>/',

  ENTITY_ATTACHMENTS: 'api/attachments/<str:entity_name>/<int:entity_id>/',
  ATTACHMENT_BY_ID: 'api/attachments/<int:pk>/',
  // Scenario Items Resources.
  ALL_ITEMS_BY_PROJECT_ID: 'api/scenario-items/<int:project_pk>/',
  ITEM_BY_ID: 'api/scenario-item/<int:pk>/',
  ITEM_MARKUP_BY_ITEM_ID: 'api/scenario-item/<int:pk>/markup',
  PROJECT_MARKUP_BY_PROJECT_ID: 'api/project/<int:pk>/markup',
  APPROVE_ITEM_BY_ID: 'api/scenario-item-approve/<int:pk>/',
  REJECT_ITEM_BY_ID: 'api/scenario-item-reject/<int:pk>/',
  REVERT_ITEM_BY_ID: 'api/scenario-item-revert/<int:pk>/',
  BATCH_ASSIGN_BY_SCENARIO_ID: 'api/scenario-items-batch-assign/<int:scenario_pk>/',
  ASSIGN_ITEM_TO_SCENARIO_BY_ID: 'api/scenario-item-assign/<int:scenario_pk>/<int:pk>/',
  UNASSIGN_ITEM_FROM_SCENARIO_BY_ID:
    'api/scenario-item-unassign/<int:scenario_pk>/<int:pk>/',
  ITEMS_SEARCH: 'api/scenario-items/search/<int:pk>/',
  ITEM_CLONE: '/api/scenario-item/<int:pk>/clone/',
  MUTUALLY_EXCLUSIVE_LIST: 'api/scenario-items/<int:pk>/mutually-exclusive/',
  MUTUALLY_EXCLUSIVE: 'api/scenario-items/<int:pk>/mutually-exclusive/<int:pk2>/',

  // Entity Comments
  ENTITY_COMMENTS: 'api/comments/<str:entity_name>/<int:entity_id>/',
  COMMENTS: 'api/comments/<int:pk>/',

  // Entity Links
  ENTITY_LINKS: 'api/links/<str:entity_name>/<int:entity_id>/',
  LINKS: 'api/links/<int:pk>/',

  // Scenario Sub Items Resources.
  ALL_SUBITEMS_BY_ITEM_ID: 'api/scenario-items-subitems/<int:scenario_item_pk>/',
  SUBITEM_BY_ID: 'api/scenario-item-subitem/<int:pk>/',

  // Design Milestone Resources.
  ALL_MILESTONES_BY_PROJECT_ID: 'api/design-milestones/<int:project_pk>/',
  MILESTONE_BY_ID: 'api/design-milestone/<int:pk>/',
  ALL_DESIGN_MILESTONE_COSTS_BY_MILESTONE_ID:
    'api/design-milestone-costs/<int:design_milestone_pk>/',
  DESIGN_COST_BY_ID: 'api/design-milestone-cost/<int:pk>/',
  ALL_USAGES_BY_MILESTONE_AND_COMPONENT_ID:
    'api/design-milestone-usages/<int:pk>/<int:componentId>/',
  NEXT_DESIGN_MILESTONE_DATE: 'api/next-design-milestone-date/<int:pk>/',

  RISK_BY_ID: 'api/projects/<int:project_id>/risks/<int:risk_id>/',
  PROJECT_RISKS: 'api/projects/<int:project_id>/risks/',
  RISK_MITIGATION_BY_ID: 'api/risks/<int:risk_id>/mitigations/<int:mitigation_id>/',
  RISK_CATEGORIES: 'api/risks/categories',
  OWNER_COSTS: 'api/design-milestones/<int:milestone_id>/owner-costs/',
  OWNER_COST_BY_ID: 'api/design-milestones/<int:milestone_id>/owner-costs/<int:cost_id>/',

  // Units Resources.
  ALL_UNITS: 'api/units/',
  ALL_BASE_UNITS: 'api/base-units/',

  // Contacts resources.
  CONTACTS: 'api/contacts/',
  CONTACT_BY_ID: 'api/contact/<int:pk>/',
  CONTACTS_BY_PROJECT_ID: 'api/projects/<int:project_pk>/contacts/',
  CONTACTS_BY_PROJECT_ID_AND_TYPE: 'api/projects/<int:project_pk>/contacts/<str:type>/',
  PROJECT_CONTACT_BY_ID: 'api/projects/<int:project_pk>/contacts/<int:contact_pk>/',

  // Components Resources.
  ALL_COMPONENTS_BY_PROJECT_ID: 'api/<int:pk>/components/',
  SEARCH_COMPONENTS_BY_PROJECT_ID: 'api/<int:pk>/components/<str:search>',
  COMPONENT_BY_ID: 'api/components/<int:pk>/',
  COMPONENT_BASE_UNITS: 'api/v2/components/<int:pk>/base-units/',
  COMPONENT_BASE_UNIT: 'api/v2/components/<int:pk>/base-units/<base_unit:id>/',

  // Cost Group Resources
  ALL_COST_GROUPS: 'api/cost-groups/',
  COST_GROUP_BY_ID: 'api/cost-groups/<int:pk>/',
  COST_GROUP_CATEGORIES: 'api/cost-group-categories/',
  SUBTOTALS_BY_MILESTONE_ID: 'api/design-milestones/<int:pk>/sub-totals/',

  // Components Usages Resources.
  ALL_USAGES_BY_COMPONENT_ID: 'api/components/<int:pk>/usages/',
  USAGE_BY_ID: 'api/usages/<int:pk>/',
  ALL_USAGES: 'api/usages/',

  // Subtotals Upload Resources.
  SUBTOTALS_UPLOAD: 'api/subtotals-upload/<int:pk>/',
  SUBTOTALS_UPLOAD_BECK_DESTINI: 'api/subtotals-upload/bd/<int:pk>/',
  SUBTOTALS_UPLOAD_WINESTXLS: 'api/subtotals-upload/winestxls/<int:pk>/',
  SUBTOTALS_UPLOAD_SAGE: 'api/subtotals-upload/sage/<int:pk>/',
  ESTIMATE_MARKUPS: 'api/markups/',
  ESTIMATE_MARKUP_BY_ID: 'api/markups/<int:pk>/',
  ESTIMATE_CUSTOM_TEMPLATE: 'api/company/estimate-template/',
  ESTIMATE_CLEAR: '/api/design-milestone-costs/<int:design_milestone_pk>',
  COPY_MARKUPS: 'api/markups/copy/',
  COPY_OWNER_ITEMS: '/api/design-milestones/<milestone>/owner-costs/copy/',

  // PDF FILE DOWNLOAD
  FORESITE_PDF_DOWNLOAD: 'api/v2/foresite/<int:pk>/export',

  // Cities Resources.
  ALL_CITIES: 'api/cities/',

  // File Storage Resources.
  FILES_BY_MILESTONE: 'files/v1/folder/<int:project_pk>/<int:milestone_pk>',
  FILES_BY_FOLDER: 'files/v1/folder/<int:project_pk>/<int:milestone_pk>/<int:folder_pk>',
  FILES_BY_ID:
    'files/v1/file/<int:project_pk>/<int:milestone_pk>/<int:folder_pk>/<int:file_pk>',

  // Delivery Contract Types
  DELIVERY_CONTRACT_TYPES: 'api/project_delivery_contract_types/',

  // Portfolio
  PORTFOLIO_SUMMARY: 'api/portfolio/summary',
  PORTFOLIO_PROJECTS_BUDGET_DELTA: 'api/portfolio/projects_budget_delta',
  PORTFOLIO_FORESITE_IDEAS: 'api/portfolio/foresite_ideas',
  PORTFOLIO_FEE_OVER_TIME: 'api/portfolio/fee_over_time',
  PORTFOLIO_DIVERSITY: 'api/portfolio/portfolio_diversity',

  // Library
  LIBRARY_CREATE_IDEA: 'api/ideas/clone/',
  LIBRARY_IDEAS: 'api/ideas/',
  LIBRARY_IDEA_BY_ID: 'api/ideas/<int:idea_pk>/',
  LIBRARY_INSTANTIATE_IDEA: 'api/ideas/<int:idea_pk>/instantiate/',
  LIBRARY_IDEA_IMAGES: '/api/ideas/<int:idea_pk>/images/',

  // Area setup
  COMPONENT_AREAS: 'api/v2/projects/<int:project_pk>/component-areas/',
  COMPONENT_AREAS_BY_ID:
    'api/v2/projects/<int:project_pk>/component-areas/<int:area_pk>/',

  // Custom Formats
  CUSTOM_CODE_UPLOAD_TEMPLATE: 'api/custom-cost-group/definitions/upload/',
  CUSTOM_CODE_DEFINITIONS: 'api/custom-cost-group/definitions/',
  CUSTOM_CODE_DEFINITION_BY_ID: 'api/custom-cost-group/definitions/<int:ccd_pk>/',

  // Procurement
  PROCUREMENT_BID_COST_GROUPS:
    'api/projects/<int:project_id>/procurement/bid/cost-group/',
  PROCUREMENT_BID_PACKAGES: 'api/projects/<int:project_id>/procurement/bid/packages/',
  PROCUREMENT_BID_PACKAGES_BY_ID:
    'api/projects/<int:project_id>/procurement/bid/packages/<int:bid_package_id>/',
  PROCUREMENT_DOCUMENT_SPECIFICATIONS:
    'api/projects/<int:project_id>/procurement/documents/specs/',
  PROCUREMENT_SPECIFICATIONS: 'api/projects/<int:project_id>/procurement/specs/',
  PROCUREMENT_BOARD_STATUSES: 'api/projects/<int:project_id>/procurement/board/statuses/',
} as const;
