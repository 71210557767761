import log from 'loglevel';
import { History, PublicRoutes, PrivateRoutes } from 'Urls';
import * as SentryReact from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isEmpty, pick } from 'lodash-es';
import { matchPath } from 'react-router';
import { useCurrentUser } from 'features/Auth/hook/session';
import { GIT_LONG_HASH, isDev } from 'utils/environment';
import { ReactNode, useRef } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

// Sentry setup

const routes = [...PrivateRoutes, ...PublicRoutes].map((route) => pick(route, ['path']));
SentryReact.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: SentryReact.reactRouterV5Instrumentation(
        History,
        routes,
        matchPath,
      ),
    }) as never,
  ],
  // Default tracesSampleRate to 1.0 to capture 100%
  // Set REACT_APP_SENTRY_TRACES_SAMPLE_RATE env var
  // To adjust this value in production
  tracesSampleRate:
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE === undefined
      ? 1.0
      : parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  // Default to develpmont environment. Explcitly set to prod/test via REACT_APP_SENTRY_ENVIRONMENT env var.
  environment: !import.meta.env.VITE_SENTRY_ENVIRONMENT
    ? 'development'
    : import.meta.env.VITE_SENTRY_ENVIRONMENT,
  // Disable sending events by default.
  // Needs to be explicitly enabled with REACT_APP_SENTRY_ENABLED env var
  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
  // Set a unique sentry release for each build
  release: GIT_LONG_HASH,
});

export const Sentry = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useCurrentUser();
  const { isPWAEnabled } = useFeatureFlags();
  const { updateServiceWorker } = useRegisterSW();
  const lastEventRef = useRef<string | null>(null);

  if (!import.meta.env.VITE_SENTRY_DSN || !import.meta.env.VITE_SENTRY_ENABLED) {
    log.warn('Sentry is not enabled');
  }

  if (!isEmpty(currentUser)) {
    // Add user data to Sentry
    SentryReact.setUser({ email: currentUser?.email, id: currentUser?.pk + '' });
  }
  // Capture react tree errors and display sentry default error handler dialog to ask for additional information from the user.
  // We would like probably to add a custom error screen / fallback Component. I created a ticket for that definition CPE-1188
  // See https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
  return (
    <SentryReact.ErrorBoundary
      onError={(error, _, eventId) => {
        if (
          ['Failed to fetch dynamically imported module', 'Failed to load resource'].some(
            (m) => error.message.includes(m),
          ) &&
          !isDev()
        ) {
          if (isPWAEnabled) {
            updateServiceWorker(true);
          }
          window.location.reload();
          return;
        }

        if (lastEventRef.current !== eventId) {
          lastEventRef.current = eventId;
          SentryReact.showReportDialog({ eventId });
        }
      }}
    >
      {children}
    </SentryReact.ErrorBoundary>
  );
};
