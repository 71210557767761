import { useMilestones } from 'features/Estimate/hooks/milestone';
import { useProject } from 'features/Projects/hook/project';
import moment from 'moment';
import { ComponentSeries, ForesiteAPIResult } from 'types/ForeSite';
import { useMemo } from 'react';

export const usePlaceholderData = (): ForesiteAPIResult => {
  const { project } = useProject();
  const { milestones } = useMilestones();

  return useMemo(() => {
    const design_milestones = milestones.map(({ id, name, date }) => ({
      id,
      name,
      has_estimate: false,
      date: moment.utc(date).valueOf(),
      data: [
        {
          name: '',
          x: moment.utc(date).valueOf(),
          y: project?.target_budget,
        },
      ],
    }));

    const otherMilestones = [
      {
        name: 'PC Start',
        date: moment.utc(project?.precon_start_date).valueOf(),
      },
      {
        name: 'PC End',
        date: moment.utc(project?.precon_end_date).valueOf(),
      },
      {
        name: 'GMP',
        date: moment.utc(project?.gmp_lock_date).valueOf(),
      },
      {
        name: 'Construction',
        date: moment.utc(project?.construction_start_date).valueOf(),
      },
    ];
    const series = otherMilestones.concat(design_milestones).map((milestone, index) => ({
      index,
      type: 'column',
      name: 'placeholder',
      showInLegend: false,
      id: index,
      component_target: 0,
      data: [
        {
          name: '',
          x: milestone.date,
          y: project?.target_budget,
        },
      ],
    })) as ComponentSeries[];
    return {
      series,
      target: 0,
      pending_high: {},
      pending_low: {},
      budget_adjusted: 0,
      milestone_components_totals: {},
      approved_series: [],
      current_milestone_id: -1,
      previous_milestone_id: -1,
      design_milestones,
      other_milestones: otherMilestones,
      today: moment.utc().valueOf(),
    };
  }, [
    milestones,
    project?.construction_start_date,
    project?.gmp_lock_date,
    project?.precon_end_date,
    project?.precon_start_date,
    project?.target_budget,
  ]);
};
