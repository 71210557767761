import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import reportRepresentation1 from 'assets/img/report-representation-1.png';
import reportRepresentation2 from 'assets/img/report-representation-2.png';
import reportRepresentation3 from 'assets/img/report-representation-3.png';

import { ReportDownloadButtons } from 'features/Reports/components/ReportDownloadButtons';
import { ReportListProps } from 'types/Reports';

export const ReportCardList = ({ reports }: ReportListProps) => {
  const images = [reportRepresentation1, reportRepresentation2, reportRepresentation3];
  return (
    <Grid container sx={{ pt: 2.5 }} gap={8} justifyContent="start">
      {reports.map((report, index) => (
        <Grid key={report.id} item xs={4} md={4} lg={3}>
          <Paper sx={{ height: '100%', p: 2 }}>
            <Box
              component="img"
              src={images[index % 3]}
              sx={{ height: 160, width: '100%', mb: 3 }}
            />
            <Stack gap={2}>
              <Stack direction="row">
                <Typography
                  variant="textExtraLargeSemiBold"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {report.name}
                </Typography>
              </Stack>

              <Stack direction="row">
                <Typography
                  variant="textDefault"
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {report.description}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="end">
                <ReportDownloadButtons report={report} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
