import { Theme, SxProps } from '@mui/material';
import {
  flatMap,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  keyBy,
  map,
  round,
  xorWith,
} from 'lodash-es';
import { UserCompany } from 'types/Company';
import { Role, UserProfile } from 'types/User';
import { ADMIN_GROUP_NAME } from './constants';

type AddSearchParamsToUrlProps = {
  url: string;
  initialSearchParams?: string;
  searchParams?: Record<
    string,
    boolean | number | string | number[] | string[] | undefined
  >;
};

export const addSearchParamsToUrl = ({
  url,
  initialSearchParams,
  searchParams,
}: AddSearchParamsToUrlProps) => {
  if (!searchParams && !initialSearchParams) {
    return url;
  }
  const urlSearchParams = new URLSearchParams(initialSearchParams);

  map(searchParams, (paramValue, paramKey) => {
    if (Array.isArray(paramValue)) {
      map(paramValue, (item) =>
        urlSearchParams.append(
          `${paramKey}[]`,
          typeof item !== 'string' ? String(item) : item,
        ),
      );
    } else if (paramValue !== undefined) {
      urlSearchParams.set(
        paramKey,
        typeof paramValue !== 'string' ? String(paramValue) : paramValue,
      );
    }
  });
  if (Array.from(urlSearchParams.keys()).length === 0) return url;

  return `${url}?${urlSearchParams.toString().replaceAll('%5B%5D=', '[]=')}`;
};

export const getRangedHashFromNumber = (seed: number, start: number, openEnd: number) => {
  const getHashCodeNumber = function (n: number) {
    const arr = new ArrayBuffer(8);
    const dv = new DataView(arr);
    dv.setFloat64(0, n);
    const c = dv.getInt32(0);
    const d = dv.getInt32(4);
    return c ^ d;
  };
  return (Math.abs(getHashCodeNumber(seed)) % (openEnd - start)) + start;
};

// https://en.wikipedia.org/wiki/Metric_prefix
export const metricPrefixFormatter = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const truncateString = (input: string, max = 10) => {
  if (input.length > max) {
    return input.substring(0, max) + '...';
  }
  return input;
};

export const convertBytesToMegaBytes = (bytes: number) => bytes / Math.pow(1024, 2);

export const concatIfNotEqualBy = <K>(original: K[], toAdd: K[], attr: keyof K) => {
  const originalHash = keyBy(original, attr);
  const response = [...original];
  toAdd.forEach((object) => {
    const key = object[attr] as string | number;
    if (!isEqual(object, originalHash[key])) {
      response.push(object);
    }
  });
  return response;
};

export const compareFloatNumbers = (
  a: number | null | undefined,
  b: number | null | undefined,
  precision = 6,
) => {
  return typeof a === 'number' && typeof b === 'number'
    ? round(a, precision) === round(b, precision)
    : a === b;
};

export const isNumeric = (value: string | number) =>
  isNumber(value) || (!isEmpty(value) && !isNaN(parseFloat(value)));

export const betterParseInt = (value: string | number) =>
  typeof value === 'string' ? parseInt(value) : value;

const validFileExtensions = {
  image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'],
  excel: ['xlsx', 'xls'],
};

export const isValidFileType = (
  fileName: string | undefined | null,
  fileType: keyof typeof validFileExtensions,
) => {
  if (!fileName) return false;
  const splitted = fileName.split('.');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return splitted.length > 1 && validFileExtensions[fileType].includes(splitted.at(-1)!);
};

export function joinWithCommasAndAnd(strings: string[]) {
  const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
  return formatter.format(strings);
}

export const isArrayEqual = <T = unknown>(x: T[], y: T[]) =>
  isEmpty(xorWith(x, y, isEqual));

const toForcedArray = (objectOrArray: unknown) => {
  if (Array.isArray(objectOrArray)) {
    return objectOrArray;
  }
  if (isNil(objectOrArray)) {
    return [];
  }
  return [objectOrArray];
};

export const joinSx = (...sxs: (SxProps<Theme> | undefined)[]) =>
  flatMap(sxs.map((sx) => toForcedArray(sx)));

type PartialMember = { name: string; last_name: string; email?: string };
export const getMemberFullnameOrEmail = (member: PartialMember) =>
  member.name || member.last_name
    ? `${member.name} ${member.last_name}`.trim()
    : member.email || '';

type GenericFilter<T> = (item: T) => boolean;
export const combineFilters =
  <T = unknown>(...filters: (GenericFilter<T> | null)[]) =>
  (item: T) =>
    filters.filter(Boolean).every((filter) => filter?.(item));

export const getInitials = (fullName: string, maxLength = 3) =>
  fullName
    .match(/(\b\S)?/g)
    ?.join('')
    .toUpperCase()
    .substring(0, maxLength) ?? '';

export const getCurrentTenantSlug = () => {
  const domains = new URL(window.location.href).host.split('.');
  // We expect an URL formed by the tenant slug subdomain, an environment subdomain,
  // the main domain and top level domain. E.g.: concntric.app-dev.concntric.com
  return domains.length === 4 ? domains[0] : null;
};

export const getProfileFullName = (profile: Pick<UserProfile, 'name' | 'last_name'>) => {
  const { name, last_name } = profile;
  return `${name ?? ''} ${last_name ?? ''}`.trim();
};

export const isExternalUser = (
  company: UserCompany | undefined,
  email: string | undefined,
) =>
  !company ||
  !email ||
  email.split('@').length < 2 ||
  email.split('@')[1] !== company.tld;

export const isAdminRole = (role?: Role) =>
  !!role && role.name === ADMIN_GROUP_NAME && role.is_system_role;
