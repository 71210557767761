import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { BudgetAdjusted } from 'types/TargetValueDesign';
import { queryKeys } from 'utils/reactQuery';

export const useBudgetAdjustedQuery = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const budgetAdjustedQuery = useQuery(
    queryKeys.project(selectedProjectId).tvdForesiteBudgetAdjusted,
    ({ signal }) => {
      const endPoint = `${Resources.TVD_FORESITE_BY_ID}budget_adjusted/`.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => {
        return res.data as BudgetAdjusted;
      });
    },
    {
      staleTime: Infinity,
      enabled: !!selectedProjectId,
    },
  );

  return { budgetAdjustedQuery };
};
