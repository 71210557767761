import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';

export const useInvalidateTVDQueries = () => {
  const queryClient = useQueryClient();
  const invalidateTVDQueries = (selectedProjectId: number | null) => {
    queryClient.invalidateQueries(queryKeys.project(selectedProjectId).tvdForesite);
    queryClient.invalidateQueries(queryKeys.project(selectedProjectId).foresiteCost);
    queryClient.invalidateQueries(queryKeys.project(selectedProjectId).foresiteSummary);
    queryClient.invalidateQueries(queryKeys.project(selectedProjectId).foresiteProgram);
  };

  return invalidateTVDQueries;
};
