import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSession } from 'features/Auth/hook/session';
import { useActiveMilestone } from 'features/Estimate/hooks/milestone';
import { replaceProjectIdPath, useRedirectHome } from 'hooks/navigate';
import { atomWithStorage } from 'jotai/utils';
import { isNil, pick } from 'lodash-es';
import {
  DetailFieldOption,
  DetailFieldType,
  Project,
  ProjectStatus,
} from 'types/Project';
import log from 'loglevel';
import { History, PrivateURL } from 'Urls';
import { useRouteMatch } from 'react-router';
import { useMemo } from 'react';
import { useScenariosFocus } from 'features/Foresite/hooks/scenarios';
import { useItemsFocus } from 'features/Foresite/hooks/itemsFocus';
import { useChartComponentFocusId } from 'features/ProjectComponents/hooks/components';
import { isLegacyOrDraftProject } from 'mappings/project';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';

export const useProjects = () => {
  const projectsQuery = useQuery(
    queryKeys.projects,
    ({ signal }) =>
      ApiService.get(Resources.ALL_PROJECTS, { signal }).then(
        (res) => res.data as Project[],
      ),
    {
      staleTime: Infinity,
    },
  );

  return {
    projectsQuery,
    projects: projectsQuery.data || [],
    isProjectsLoading: projectsQuery.isLoading,
    isProjectsFetching: projectsQuery.isFetching,
    isProjectsFetched: projectsQuery.isFetched,
  };
};

const selectedProjectIdAtom = atomWithStorage<number | null>(
  'concntric-selected-project-id',
  null,
);
selectedProjectIdAtom.debugLabel = 'selectedProjectIdAtom';

export const useSelectedProjectId = () => {
  const projectMatch = useRouteMatch<{ projectId: string }>(PrivateURL.PROJECT_UPDATE);
  const legacyProjectMatch = useRouteMatch<{ projectId: string }>(
    PrivateURL.PROJECT_LEGACY_UPDATE,
  );
  const projectId =
    projectMatch?.params?.projectId === 'legacy'
      ? legacyProjectMatch?.params?.projectId
      : projectMatch?.params?.projectId;

  const selectedProjectId = useMemo(() => {
    if (!projectId) return null;

    const parsed = parseInt(projectId);
    if (isNaN(parsed)) return null;
    return parsed;
  }, [projectId]);

  return {
    selectedProjectId,
  };
};

export const useProject = () => {
  const { hasSession } = useSession();
  const { selectedProjectId } = useSelectedProjectId();
  const {
    data: project,
    isLoading: isProjectLoading,
    isFetching: isProjectFetching,
    ...rest
  } = useQuery(
    queryKeys.project(selectedProjectId).details,
    ({ signal }) => {
      const endPoint = Resources.PROJECT_BY_ID.replace(
        '<int:pk>',
        (selectedProjectId ?? '') as string,
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Project);
    },
    {
      staleTime: Infinity,
      placeholderData: {} as Project,
      retryOnMount: false,
      enabled: hasSession && !!selectedProjectId,
    },
  );

  return { ...rest, project, isProjectLoading, isProjectFetching };
};

export type DeliveryContractType = {
  id: number;
  name: string;
  description: string;
};

export const useDeliveryContractTypes = () => {
  const { data: deliveryContractTypes } = useQuery(
    queryKeys.deliveryContractTypes,
    () =>
      ApiService.get(Resources.DELIVERY_CONTRACT_TYPES).then(
        (res) => res.data as DeliveryContractType[],
      ),
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );
  return { deliveryContractTypes };
};

export const useUpdateProjectStatus = () => {
  const queryClient = useQueryClient();
  const { project } = useProject();

  const { mutate: updateProjectStatus } = useMutation(
    ({ projectId, status }: { projectId: number; status: ProjectStatus }) => {
      const endPoint = Resources.PROJECT_UPDATE_STATUS.replace(
        '<int:project_id>',
        projectId.toString(),
      );
      const payload = {
        // eslint-disable-next-line camelcase
        target_status: status,
      };

      return ApiService.patch(endPoint, payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.projects);
      },
      onSettled: (response, _error, { projectId }: { projectId: number }) => {
        if (project && 'id' in project && String(projectId) === String(project.id)) {
          queryClient.setQueryData(
            queryKeys.project(response?.data.id).details,
            response?.data,
          );
        }
      },
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );

  return { updateProjectStatus };
};

export const useClearProjectData = () => {
  const { scenariosFocusClear } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { cleanActiveMilestone } = useActiveMilestone();
  const { unfocusComponentId } = useChartComponentFocusId();

  const clearProjectData = () => {
    scenariosFocusClear();
    resetItemsFocus();
    cleanActiveMilestone();
    unfocusComponentId();
  };

  return {
    clearProjectData,
  };
};

export const useSwitchProject = () => {
  const { clearProjectData } = useClearProjectData();
  const { redirectHome } = useRedirectHome();

  const switchToProject = (
    projectId: number,
    options?: { to?: string; redirect?: boolean },
  ) => {
    log.debug(
      'Switching to project ID: ',
      projectId,
      '. With redirect: ',
      options?.redirect,
    );

    clearProjectData();

    if (options?.to) {
      History.push(options.to);
    } else if (options?.redirect && redirectHome) {
      log.debug('Redirecting project home');
      redirectHome({ projectId });
    }
  };
  return { switchToProject };
};

type ProjectData = Partial<Project> & { id?: number | null; pictureFile?: File };

const isNewProject = (projectData: ProjectData) =>
  !projectData || projectData.id === null || projectData.id === undefined;

export const useSaveProject = () => {
  const queryClient = useQueryClient();
  const { switchToProject } = useSwitchProject();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: saveProject, isLoading: isSavingProject } = useMutation(
    (projectData: ProjectData) => {
      let method;
      let endPoint;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      let serializedProjectData = projectData;
      if (isLegacyOrDraftProject(projectData)) {
        serializedProjectData = pick(projectData, [
          'id',
          'address',
          'status',
          'name',
          'delivery_contract_type',
          'construction_category',
          'target_square_footage',
          'target_budget',
          'picture',
          'pictureFile',
          'construction_start_date',
          'completion_date',
          'is_under_contract',
          'custom_cost_group_definition',
        ]);
      }

      if (isNewProject(serializedProjectData)) {
        method = ApiService.post;
        endPoint = isLegacyOrDraftProject(serializedProjectData)
          ? Resources.PROJECT_LEGACY
          : Resources.ALL_PROJECTS;
      } else {
        method = ApiService.patch;
        endPoint = isLegacyOrDraftProject(serializedProjectData)
          ? Resources.PROJECT_LEGACY_BY_ID.replace(
              '<int:pk>',
              serializedProjectData.id + '',
            )
          : Resources.PROJECT_BY_ID.replace('<int:pk>', serializedProjectData.id + '');
      }

      const form = new FormData();
      (
        Object.keys(serializedProjectData) as Array<keyof typeof serializedProjectData>
      ).forEach((key) => {
        if (key === 'pictureFile' && serializedProjectData.pictureFile) {
          form.append(
            'picture',
            serializedProjectData.pictureFile,
            serializedProjectData.pictureFile.name,
          );
        } else if (!isNil(serializedProjectData[key]) && key !== 'picture') {
          form.append(key, (serializedProjectData[key] as number | string).toString());
        } else if (['custom_cost_group_definition', 'completion_date'].includes(key)) {
          // At this point, the value of the key is null and we send it to the API as the empty string
          form.append(key, '');
        }
      });
      return method(endPoint, form, config).then((res) => res.data);
    },
    {
      onSuccess: (responseData: Project, projectData) => {
        if (isNewProject(projectData)) {
          switchToProject(responseData.id, {
            to: replaceProjectIdPath(PrivateURL.VISION, responseData.id),
          });
        } else {
          queryClient.setQueryData(queryKeys.project(responseData.id).details, () => ({
            ...responseData,
            status: projectData.status || responseData.status,
          }));
        }
        queryClient.invalidateQueries(queryKeys.projects);
        invalidateTVDQueries(responseData.id);
      },
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { saveProject, isSavingProject };
};

export const useDetailFieldOptions = (detailFieldName: DetailFieldType) => {
  const { data: detailFieldsOptions, isLoading: isDetailFieldsOptionsLoading } = useQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKeys.projectDetailField(detailFieldName),
    ({ signal }) => {
      const endPoint = `${Resources.PROJECT_DETAIL_FIELD}?type=${detailFieldName}`;

      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as DetailFieldOption[],
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { detailFieldsOptions, isDetailFieldsOptionsLoading };
};

export const useCreateNewDetailFieldValue = () => {
  const queryClient = useQueryClient();
  const { mutate: createNewDetailFieldValue, isLoading: isCreatingNewDetailFieldValue } =
    useMutation(
      (newValue: { type: DetailFieldType; name: string }) => {
        return ApiService.post(Resources.PROJECT_DETAIL_FIELD, newValue).then(
          (res) => res.data as DetailFieldOption,
        );
      },
      {
        onMutate: async (newValue) => {
          const queryKey = queryKeys.projectDetailField(newValue.type);
          await queryClient.cancelQueries(queryKey);
          const previousDetailValues: DetailFieldOption[] | undefined =
            queryClient.getQueryData(queryKey);
          if (previousDetailValues) {
            queryClient.setQueryData(queryKey, (oldDetailValues?: DetailFieldOption[]) =>
              oldDetailValues?.concat({ ...newValue, id: 0 }),
            );
          }

          return previousDetailValues;
        },
        onError: (error, newValue, previousDetailValues) => {
          if (previousDetailValues) {
            queryClient.setQueryData(
              queryKeys.projectDetailField(newValue.type),
              previousDetailValues,
            );
          }
          log.error(error instanceof Error ? error.message : error);
        },
        onSettled: (_data, _error, newValue) => {
          queryClient.invalidateQueries(queryKeys.projectDetailField(newValue.type));
        },
      },
    );
  return {
    createNewDetailFieldValue,
    isCreatingNewDetailFieldValue,
  };
};
