import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { ProjectMember } from 'types/ProjectMember';
import { useSelectedProjectId } from './project';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';
import { keyBy } from 'lodash-es';
import { queryKeys } from 'utils/reactQuery';

export const useProjectMembers = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { permissionRolesQuery } = usePermissionRoles();

  const rolesMap = permissionRolesQuery.data
    ? keyBy(permissionRolesQuery.data, 'id')
    : {};

  const { data: projectMembers, ...rest } = useQuery(
    queryKeys.project(selectedProjectId).members,
    ({ signal }) => {
      const endPoint = Resources.PROJECT_TEAM_CONTACT.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) =>
        (res.data as ProjectMember[]).map(
          (member) =>
            ({
              ...member,
              rolesObjects: member.roles.map((roleId) => rolesMap[roleId]),
            } as ProjectMember),
        ),
      );
    },
    {
      staleTime: Infinity,
      enabled: !!selectedProjectId && !permissionRolesQuery.isLoading,
    },
  );
  const signedUpMembers = projectMembers
    ? projectMembers.filter((member) => !member.is_invite_pending)
    : [];
  return { ...rest, projectMembers, signedUpMembers };
};

export const useDeleteProjectMember = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();

  const { mutate: deleteProjectMember } = useMutation(
    (userId: number) => {
      if (!selectedProjectId) {
        throw Error('There is no selected project id');
      }

      const endPoint = Resources.PROJECT_MEMBER_BY_ID.replace(
        '<int:project_pk>',
        selectedProjectId.toString(),
      ).replace('<int:user_pk>', userId.toString());

      return ApiService.delete(endPoint).then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).members);
        queryClient.invalidateQueries(queryKeys.companyUsers);
      },
    },
  );
  return { deleteProjectMember };
};
