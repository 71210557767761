import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { useProject } from 'features/Projects/hook/project';

export const CurrentProjectItem = () => {
  const { project } = useProject();

  return (
    <ListItem sx={{ pl: 1.5 }}>
      <Tooltip title={project?.name} placement="top">
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Box
            sx={{
              backgroundColor: 'grey.400',
              boxShadow: (theme) => `0px 2px 8px ${theme.palette.grey[700]}`,
              height: '1.5rem',
              width: '1.5rem',
              borderRadius: '4px',
            }}
          >
            <Box
              component={FontAwesomeIcon}
              icon={faBuilding}
              sx={{ fontSize: '1rem', color: 'grey.700', py: 0.5, px: 0.75 }}
            />
          </Box>

          <Stack sx={{ ml: 1.5, width: 'calc(100% - 40px)' }}>
            <Typography variant="textExtraSmall">PROJECT</Typography>
            <Typography
              variant="textDefaultBold"
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {project?.name}
            </Typography>
          </Stack>
        </Box>
      </Tooltip>
    </ListItem>
  );
};
