import { useSession } from 'features/Auth/hook/session';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Layout, PrivateURL, PublicURL } from 'Urls';
import PublicLayout from 'layouts/Public.layout';
import log from 'loglevel';

export const PublicRoute = (props: RouteProps) => {
  const { hasSession } = useSession();
  const location = useLocation();
  let redirectToPath: string | null = null;
  if (hasSession) {
    if ([Layout.ROOT, PublicURL.LOGIN].includes(location.pathname)) {
      log.debug('Set redirect as there is session');
      redirectToPath = PrivateURL.PROJECT_LIST;
    }
  }

  return (
    <Route
      {...props}
      render={(renderProps) =>
        redirectToPath ? (
          <Redirect
            to={{
              pathname: redirectToPath,
              state: { from: renderProps.location },
            }}
          />
        ) : (
          <PublicLayout />
        )
      }
    />
  );
};
