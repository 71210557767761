import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useState } from 'react';
import { queryKeys } from 'utils/reactQuery';

export const useFeatureFlags = () => {
  const [isNotificationEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_NOTIFICATION?.toLowerCase() === 'true',
  );
  const [isCustomNormalizationEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_NORMALIZATION?.toLowerCase() === 'true',
  );
  const [isForeSiteChartZoomEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_FORESITE_CHART_ZOOM?.toLowerCase() === 'true',
  );
  const [isCustomCodesEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_CUSTOM_CODES?.toLowerCase() === 'true',
  );
  const [isProcurementEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_PROCUREMENT?.toLowerCase() === 'true',
  );
  const [isBidPackageDetailsEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_PROCUREMENT_BID_PACKAGE_DETAILS_ENABLED?.toLowerCase() ===
      'true',
  );
  const [isReportingEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_REPORTING?.toLowerCase() === 'true',
  );
  const [isPWAEnabled] = useState(
    import.meta.env.VITE_FEATUREFLAG_PWA?.toLowerCase() === 'true',
  );

  const flags = {
    isNotificationEnabled,
    isCustomNormalizationEnabled,
    isForeSiteChartZoomEnabled,
    isCustomCodesEnabled,
    isProcurementEnabled,
    isBidPackageDetailsEnabled,
    isPWAEnabled,
    isReportingEnabled,
  };

  return flags;
};

export type FeatureFlagsKeys = keyof ReturnType<typeof useFeatureFlags>;

export type TenantFeatureFlags = {
  beck_destini_configured: boolean;
  winest_configured: boolean;
  sage_configured: boolean;
  procurement_available: boolean;
  TENANT_IS_USING_CUSTOM_TEMPLATE: boolean;
};

export type TenantFeatureFlagsKeys = keyof TenantFeatureFlags;

type TenantFeatureFlagsDatum = {
  is_active: boolean;
  name: TenantFeatureFlagsKeys;
};

export const useTenantFeatureFlags = () => {
  const {
    data: tenantFeatureFlags,
    isLoading: isTenantFeatureFlagsLoading,
    isFetching: isTenantFeatureFlagsFetching,
  } = useQuery(
    queryKeys.tenantFeatureFlags,
    ({ signal }) => {
      return ApiService.get(Resources.FEATURE_FLAGS, { signal }).then(
        (res) => res.data as TenantFeatureFlagsDatum[],
      );
    },
    {
      staleTime: Infinity,
      placeholderData: [],
    },
  );

  const mappedTenantFeatureFlags =
    tenantFeatureFlags?.reduce(
      (acc: Partial<TenantFeatureFlags>, featureFlag: TenantFeatureFlagsDatum) => ({
        ...acc,
        [featureFlag.name]: featureFlag.is_active,
      }),
      {},
    ) ?? {};

  return {
    tenantFeatureFlags: mappedTenantFeatureFlags,
    isTenantFeatureFlagsLoading,
    isTenantFeatureFlagsFetching,
  };
};
