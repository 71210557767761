import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Tooltip,
  MenuList,
  Typography,
  Divider,
} from '@mui/material';
import { faFileCirclePlus, faGear, faUserGear } from '@fortawesome/pro-regular-svg-icons';
import { APP_VERSION, GIT_HASH } from 'utils/environment';
import { PrivateURL, PublicURL } from 'Urls';
import { Link } from 'react-router-dom';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import usePermissions from 'features/Auth/hook/usePermissions';
import { MenuItemWithIcon } from './MenuItemWithIcon';

type SettingsIconButtonProps = {
  sx?: SxProps<Theme>;
};
export const SettingsIconButton = (props: SettingsIconButtonProps) => {
  const [menuElementRef, setMenuElementRef] = useState<HTMLButtonElement | null>(null);
  const { HasPerm, Permissions } = usePermissions();
  const open = Boolean(menuElementRef);

  const handleUserButtonClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuElementRef(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setMenuElementRef(null);
  };

  const { isCustomCodesEnabled } = useFeatureFlags();
  return (
    <>
      <Tooltip title="Settings" placement="bottom">
        <IconButton onClick={handleUserButtonClick} sx={props.sx}>
          <FontAwesomeIcon icon={faGear} />
        </IconButton>
      </Tooltip>

      <Menu
        id="settings-menu"
        anchorEl={menuElementRef}
        open={open}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock={true}
      >
        <MenuList sx={{ width: '21rem', p: 0 }}>
          <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
            Account
          </Typography>

          <HasPerm permission={Permissions.COMPANY_VIEW_ALL_USER_PROFILES}>
            <Link to={PrivateURL.MANAGE_USERS} onClick={handleCloseUserMenu}>
              <MenuItemWithIcon
                icon={faUserGear}
                label="User management"
                description="Organize your workspace structure by adding & managing scopes"
              />
            </Link>
          </HasPerm>

          {isCustomCodesEnabled && (
            <HasPerm permission={Permissions.MANAGE_PROJECT}>
              <Link to={PrivateURL.CUSTOM_CODES} onClick={handleCloseUserMenu}>
                <MenuItemWithIcon
                  icon={faFileCirclePlus}
                  label="Format settings"
                  description="Add custom formats for estimate upload."
                />
              </Link>
            </HasPerm>
          )}
          <Divider sx={{ my: '.25rem !important' }} />
          <Typography variant="textSmallBold" sx={{ pl: 1, color: 'grey.500' }}>
            Other
          </Typography>
          <a href={PublicURL.ABOUT} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>About us</MenuItem>
          </a>
          <a href={PublicURL.SOLUTIONS} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>Solutions</MenuItem>
          </a>
          <a href={PublicURL.CONTACTS} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>Contacts</MenuItem>
          </a>
          <a href={PublicURL.OMNICLASS} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>Omniclass Disclaimer</MenuItem>
          </a>
          <a href={PublicURL.LANDING} target="_blank">
            <MenuItem onClick={handleCloseUserMenu}>
              &copy; {new Date().getFullYear()} ConCntric, Inc &nbsp;
              {`v${APP_VERSION}(${GIT_HASH})`}
            </MenuItem>
          </a>
        </MenuList>
      </Menu>
    </>
  );
};
