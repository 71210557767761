import axios from 'axios';
import queryString from 'query-string';
import { TOKEN_SS_KEY } from 'features/Auth/hook/session';
import { History, Layout, PublicURL } from 'Urls';
import Cookies from 'js-cookie';
import { getCurrentTenantSlug } from 'utils/helpers';

const getBaseUrl = () => {
  const replacement = getCurrentTenantSlug() ?? '';
  const pattern = `subdomain${replacement ? '' : '.'}`;
  return import.meta.env.VITE_BACKEND_URL.replace(pattern, replacement);
};

export const ApiService = axios.create({
  baseURL: getBaseUrl(),
  timeout: import.meta.env.VITE_BACKEND_TIMEOUT || 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Build the error message if the API returns something like:
// {"quantity":["This field is required."]} on response.data
const buildFieldsErrorMessage = (fieldErrors?: Record<string, string[]>) => {
  if (!fieldErrors || typeof fieldErrors !== 'object') {
    return null;
  }

  return Object.keys(fieldErrors).reduce((acc, field) => {
    return `${acc} ${field}: ${fieldErrors[field].join('. ')}`;
  }, '');
};

// Attaching the JWT token to all requests,
// if the user is authenticated.
ApiService.interceptors.request.use(function (config) {
  const bearerToken = Cookies.get(TOKEN_SS_KEY);
  if (bearerToken) {
    config.headers.Authorization = 'Bearer ' + bearerToken;
  }
  return config;
});

ApiService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // If the status code is 401, the user is redirected to the login page.
    if ((error.response ? error.response.status : '') === 401) {
      const path = History.location?.pathname || '';
      const query = queryString.parse(History.location?.search || '');
      if (
        (!path.startsWith(Layout.PUBLIC) && query.redirect === undefined) ||
        !Cookies.get(TOKEN_SS_KEY)
      ) {
        // Ok, we tried to hit a protected API but the user is not authenticated.
        // Clear the session tokens then...
        History.push(PublicURL.LOGOUT);
      }
    }
    const message =
      error.response?.data?.message ||
      error.response?.data?.detail ||
      buildFieldsErrorMessage(error.response?.data) ||
      error.message;
    return Promise.reject(new Error(message, { cause: error }));
  },
);
