import { Box, Button, Stack, Typography } from '@mui/material';
import { useRedirectHome } from 'hooks/navigate';

export const NotFoundView = () => {
  const { redirectHome } = useRedirectHome();

  return (
    <Stack
      sx={{
        width: '100%',
        height: `80vh`,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack spacing={2} sx={{ maxWidth: '34.5rem' }}>
        <Typography variant="h2">
          {"We can't find the page you're looking for"}
        </Typography>
        <Typography>
          It may have been deleted, its name may have been changed, or it could be
          temporarily unavailable.
        </Typography>
        <Typography>
          Please check the spelling in the address bar to ensure it is correct.
          Alternatively, you can try opening the home page of this site to search or
          browse for the content you are looking for.
        </Typography>
        <Typography variant="textDefaultBold" fontSize={16} sx={{ color: 'grey.400' }}>
          Error code: 404
        </Typography>

        <Box display="flex" gap="0.5rem">
          <Button onClick={() => redirectHome?.()}>Go to the homepage</Button>
        </Box>
      </Stack>
    </Stack>
  );
};
