import {
  faFileChartColumn,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@mui/material';
import { useProject } from 'features/Projects/hook/project';
import { ReportItem } from 'types/Reports';

type ReportDownloadButtonsProps = {
  report: ReportItem;
};

export const ReportDownloadButtons = ({ report }: ReportDownloadButtonsProps) => {
  const { project } = useProject();
  return (
    <Box display={'flex'} gap={2}>
      {report.formats.map((format) => {
        let icon = faFileChartColumn;
        let label = 'File';
        if (format === 'xlsx') {
          icon = faFileExcel;
          label = 'Excel';
        } else if (format === 'pdf') {
          icon = faFilePdf;
          label = 'PDF';
        }

        return (
          <Button
            key={format}
            variant="blueGhost"
            size="extraSmall"
            startIcon={<FontAwesomeIcon icon={icon} />}
            onClick={() => report.onDownload(project!, format)}
            className="selectButton"
          >
            {label}
          </Button>
        );
      })}
    </Box>
  );
};
