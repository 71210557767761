import { PrivateURL, History } from 'Urls';
import { isEmpty } from 'lodash-es';
import { useProject, useSelectedProjectId } from 'features/Projects/hook/project';
import { Project } from 'types/Project';

export const replaceProjectIdPath = (path: string, id: string | number) =>
  path.replace(':projectId', String(id));

let pendingRedirect: string | null = null;
export const usePathWithSelectedProjectId = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const getPathWithSelectedProjectId = (path: string, waitForProjectRefresh = false) => {
    if (selectedProjectId && !waitForProjectRefresh) {
      pendingRedirect = null;
      return replaceProjectIdPath(path, selectedProjectId);
    } else if (waitForProjectRefresh) {
      // Set the pending redirect
      pendingRedirect = path;
    }
    return '';
  };
  return { getPathWithSelectedProjectId };
};

export const useRedirectWithSelectedProject = () => {
  const { getPathWithSelectedProjectId } = usePathWithSelectedProjectId();
  if (pendingRedirect) {
    const pendingRedirectWithProjectId = getPathWithSelectedProjectId(pendingRedirect);
    if (pendingRedirectWithProjectId) {
      // Consume the pending redirect
      History.push(pendingRedirectWithProjectId);
    }
  }
  const redirectWithSelectedProject = (path: string, waitForProjectRefresh = false) => {
    const pathWithProjectId = getPathWithSelectedProjectId(path, waitForProjectRefresh);
    if (pathWithProjectId) {
      // Plain and simple redirect, normal use case
      History.push(pathWithProjectId);
    }
  };

  return { redirectWithSelectedProject };
};

export const getProjectCurrentStep = (project: Project) => {
  if (project.status === 'LEGACY') {
    return PrivateURL.OVERVIEW;
  } else if (project.status === 'DRAFT_LEGACY') {
    return PrivateURL.PROJECT_LEGACY_UPDATE;
  }
  // CPE-2173 always go to project dashboard. All the other steps are optional now.
  return PrivateURL.VISION;
};

let isPendingRedirect = false;
export function useRedirectHome() {
  const { project } = useProject();
  const { redirectWithSelectedProject } = useRedirectWithSelectedProject();
  const { selectedProjectId } = useSelectedProjectId();

  const redirectHome = (options: { projectId?: number } | undefined = {}) => {
    if (
      options.projectId &&
      (isEmpty(project) ||
        project.id !== options.projectId ||
        project.id !== selectedProjectId)
    ) {
      isPendingRedirect = true;
      return;
    }
    if (project && selectedProjectId) {
      const redirectTo = options.projectId
        ? (path: string) => History.push(replaceProjectIdPath(path, options.projectId!))
        : redirectWithSelectedProject;

      const redirectPath = getProjectCurrentStep(project);
      redirectTo(redirectPath);
    } else {
      History.push(PrivateURL.PROJECT_LIST);
    }
  };

  if (isPendingRedirect && project?.id === selectedProjectId) {
    isPendingRedirect = false;
    redirectHome();
    return {};
  }

  return { redirectHome };
}
