import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { atom, useAtom } from 'jotai';
import { keyBy, pick } from 'lodash-es';
import log from 'loglevel';
import { useCallback, useMemo } from 'react';
import { DesignMilestone } from 'types/DesignMilestones';
import { ProjectMilestoneSummary } from 'types/Project';
import { useQueryParam } from 'hooks/useQueryParam';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';

const isDesignMilestone = (
  milestone: DesignMilestone | ProjectMilestoneSummary,
): milestone is DesignMilestone =>
  (milestone as DesignMilestone).total_cost !== undefined;

const sortMilestoneByDateFn = (
  a: Partial<DesignMilestone>,
  d: Partial<DesignMilestone>,
) => (a.date && d.date ? new Date(a.date).getTime() - new Date(d.date).getTime() : 0);

export function getActiveMilestone<T extends DesignMilestone | ProjectMilestoneSummary>(
  milestones: T[],
) {
  const lastMilestoneWithEstimateIndex = milestones.findLastIndex(
    (milestone) =>
      milestone.has_estimate === true ||
      (isDesignMilestone(milestone) && milestone.total_cost !== 0),
  );

  if (
    lastMilestoneWithEstimateIndex > 0 &&
    lastMilestoneWithEstimateIndex + 1 < milestones.length
  ) {
    return milestones[lastMilestoneWithEstimateIndex + 1];
  } else {
    const today = new Date();
    const afterToday = milestones
      .filter((milestone) => new Date(milestone.date) >= today)
      .sort(sortMilestoneByDateFn);
    if (afterToday.length) {
      return afterToday[0];
    } else if (milestones.length) {
      return milestones[0];
    }
  }
  return null;
}

const activeMilestoneAtom = atom<DesignMilestone | ProjectMilestoneSummary | null>(null);
activeMilestoneAtom.debugLabel = 'activeMilestoneAtom';

export const useActiveMilestone = ({
  autoAssign = true,
}: { autoAssign?: boolean } = {}) => {
  const query = useQueryParam<{ activeMilestoneId?: string }>();
  const activeMilestoneIdParam = parseInt(query?.activeMilestoneId || '0') || null;

  const [stateActiveMilestone, setActiveMilestone] = useAtom(activeMilestoneAtom);
  const cleanActiveMilestone = useCallback(
    () => setActiveMilestone(null),
    [setActiveMilestone],
  );
  const { milestones } = useMilestones();

  const activeMilestone: DesignMilestone | null = useMemo(() => {
    if (stateActiveMilestone) {
      return milestones.find(
        (milestone) => milestone.id === stateActiveMilestone.id,
      ) as DesignMilestone;
    }

    if ((!stateActiveMilestone && activeMilestoneIdParam) || activeMilestoneIdParam) {
      return (
        milestones.find((milestone) => milestone.id === activeMilestoneIdParam) ||
        ({
          id: activeMilestoneIdParam,
        } as DesignMilestone)
      );
    }

    if (autoAssign && milestones.length > 0) {
      return getActiveMilestone(milestones);
    }
    return null;
  }, [activeMilestoneIdParam, autoAssign, milestones, stateActiveMilestone]);

  return { activeMilestone, setActiveMilestone, cleanActiveMilestone };
};

export const useMilestones = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const {
    data: milestones,
    isLoading: isMilestonesLoading,
    isFetching: isMilestonesFetching,
    isInitialLoading: isMilestonesInitialLoading,
    isFetched: isMilestonesFetched,
    fetchStatus: milestonesFetchStatus,
  } = useQuery(
    queryKeys.project(selectedProjectId).milestones,
    ({ signal }) => {
      const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );

      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data.sort(sortMilestoneByDateFn) as DesignMilestone[],
      );
    },
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
      onSuccess: () => {
        invalidateTVDQueries(selectedProjectId);
      },
    },
  );

  const milestonesMapById = Array.isArray(milestones) ? keyBy(milestones, 'id') : {};

  return {
    milestones: milestones ?? [],
    milestonesMapById,
    isMilestonesLoading,
    isMilestonesInitialLoading,
    isMilestonesFetching,
    isMilestonesFetched,
    milestonesFetchStatus,
  };
};

const isMilestone = (
  milestoneData: Partial<DesignMilestone>,
): milestoneData is DesignMilestone => !!milestoneData && !!milestoneData.id;

export const useSaveMilestone = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { setActiveMilestone } = useActiveMilestone();
  const queryClient = useQueryClient();

  const { mutate: saveMilestone, isLoading: isSavingMilestone } = useMutation(
    (milestoneData: Partial<DesignMilestone> | DesignMilestone) => {
      if (selectedProjectId) {
        let request;
        if (isMilestone(milestoneData)) {
          const endPoint = Resources.MILESTONE_BY_ID.replace(
            '<int:pk>',
            milestoneData.id.toString(),
          );
          request = ApiService.patch(endPoint, milestoneData);
        } else {
          const payload = {
            ...pick(milestoneData, ['date', 'name', 'short_name']),
            project_id: selectedProjectId,
            project: selectedProjectId,
          };
          const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
            '<int:project_pk>',
            selectedProjectId.toString(),
          );
          request = ApiService.post(endPoint, payload);
        }
        return request.then((res) => res.data);
      } else {
        log.error('Milestone could not be save: no project selected');
        return Promise.reject(Error('Milestone could not be save: no project selected'));
      }
    },
    {
      onMutate: async (milestoneData) => {
        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).milestones);
        const previousMilestones = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).milestones,
        );

        if (previousMilestones) {
          if (isMilestone(milestoneData)) {
            queryClient.setQueryData(
              queryKeys.project(selectedProjectId).milestones,
              (oldMilestones?: DesignMilestone[]) =>
                oldMilestones?.map((oldMilestone) =>
                  oldMilestone.id === milestoneData.id
                    ? { ...oldMilestone, milestoneData }
                    : oldMilestone,
                ),
            );
          } else {
            queryClient.setQueryData(
              queryKeys.project(selectedProjectId).milestones,
              (oldMilestones?: Partial<DesignMilestone>[]) =>
                oldMilestones
                  ?.concat({ ...milestoneData, id: 0 })
                  ?.sort(sortMilestoneByDateFn),
            );
          }
          // TODO: Insert created milestone in the right place
        }
        return previousMilestones;
      },
      onSuccess: (result, milestoneData) => {
        if (!isMilestone(milestoneData)) {
          setActiveMilestone(result);
        }
        queryClient.invalidateQueries(queryKeys.projects);
      },
      onError: (error, _milestoneData, previousMilestones) => {
        if (previousMilestones) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).milestones,
            previousMilestones,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).milestones);
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).tvdForesite);
      },
    },
  );

  return {
    saveMilestone,
    isSavingMilestone,
  };
};

export const useDeleteMilestone = () => {
  const { activeMilestone, cleanActiveMilestone } = useActiveMilestone();
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();

  const { mutate: deleteMilestone, isLoading: isDeletingMilestone } = useMutation(
    (milestoneId: number) => {
      const endpoint = Resources.MILESTONE_BY_ID.replace(
        '<int:pk>',
        milestoneId.toString(),
      );
      return ApiService.delete(endpoint).then((res) => res.data);
    },
    {
      onMutate: async (milestoneId) => {
        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).milestones);
        const previousMilestones = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).milestones,
        );

        if (previousMilestones) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).milestones,
            (oldMilestones?: DesignMilestone[]) =>
              oldMilestones?.filter((oldMilestone) => oldMilestone.id !== milestoneId),
          );
        }
        return previousMilestones;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.projects);
      },
      onError: (error, _scenarioId, previousMilestones) => {
        if (previousMilestones) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).milestones,
            previousMilestones,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: (_response, _error, milestoneId) => {
        if (milestoneId === activeMilestone?.id) {
          cleanActiveMilestone();
        }
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).milestones);
      },
    },
  );

  return {
    deleteMilestone,
    isDeletingMilestone,
  };
};

export const useProjectLegacyMilestone = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const {
    data: legacyProjectMilestone,
    isLoading: isLegacyProjectMilestoneLoading,
    isFetching: isLegacyProjectMilestoneFetching,
    refetch: refetchLegacyProjectMilestone,
  } = useQuery(
    queryKeys.legacyProject(selectedProjectId).milestone,
    ({ signal }) => {
      const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        (selectedProjectId as unknown as number).toString(),
      );

      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data.at(0) as DesignMilestone,
      );
    },
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );

  return {
    legacyProjectMilestone,
    isLegacyProjectMilestoneLoading,
    isLegacyProjectMilestoneFetching,
    refetchLegacyProjectMilestone,
  };
};
