import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import log from 'loglevel';
import { queryKeys } from 'utils/reactQuery';
import {
  Attachment,
  EntityTypeName,
  FileAttachment,
  imagesMimeTypes,
} from 'types/Common';
import { Idea } from 'types/Idea';
import { useCurrentCompany } from 'features/Company/hooks/company';

export const useAttachmentsByEntity = ({
  entityName,
  entityId,
}: {
  entityName: EntityTypeName;
  entityId: number | undefined;
}) => {
  const attachmentsByEntityQuery = useQuery(
    queryKeys.attachmentsByEntity(entityName, entityId || 0),
    ({ signal }) => {
      if (!entityId) {
        return Promise.resolve([]);
      }

      const url = Resources.ENTITY_ATTACHMENTS.replace(
        '<str:entity_name>',
        entityName,
      ).replace('<int:entity_id>', entityId.toString());
      return ApiService.get(url, { signal }).then((res) =>
        ((res.data || []) as Attachment[]).map(
          (att) => ({ ...att, data: att.url, type: 'attachment' } as FileAttachment),
        ),
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(entityName && entityId),
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { attachmentsByEntityQuery };
};

export const useRemoveAttachment = () => {
  const queryClient = useQueryClient();
  const { currentCompany } = useCurrentCompany();

  const removeAttachmentMutation = useMutation(
    ({
      attachment,
    }: {
      attachment: Pick<Attachment, 'id' | 'entity_id'>;
      entityName: EntityTypeName;
    }) => {
      const url = Resources.ATTACHMENT_BY_ID.replace(
        '<int:pk>',
        attachment.id.toString(),
      );
      return ApiService.delete(url);
    },
    {
      onMutate: async ({ attachment, entityName }) => {
        const queryKey = queryKeys.attachmentsByEntity(entityName, attachment.entity_id);
        await queryClient.cancelQueries(queryKey);

        const oldAttachments = queryClient.getQueryData<Attachment[]>(queryKey);
        if (oldAttachments) {
          queryClient.setQueryData(queryKey, (attachments?: Attachment[]) => {
            return attachments?.filter((att) => att.id !== attachment.id);
          });
        }
        const newAttachments = queryClient.getQueryData<Attachment[]>(queryKey);

        let oldIdeas;
        if (entityName === 'idea') {
          oldIdeas = queryClient.getQueryData(
            queryKeys.company(currentCompany?.id).ideas,
          );
          queryClient.setQueryData(
            queryKeys.company(currentCompany?.id).ideas,
            (oldIdeas?: Idea[]) =>
              oldIdeas?.map((oldIdea) =>
                oldIdea.id === attachment.entity_id
                  ? {
                      ...oldIdea,
                      picture: newAttachments?.find((att) =>
                        (imagesMimeTypes as readonly string[]).includes(att.file_type),
                      )?.url,
                    }
                  : oldIdea,
              ),
          );
        }

        return { oldAttachments, oldIdeas };
      },
      onError: (error, { attachment, entityName }, context) => {
        if (context?.oldAttachments) {
          queryClient.setQueryData(
            queryKeys.attachmentsByEntity(entityName, attachment.entity_id),
            context.oldAttachments,
          );
        }
        if (context?.oldIdeas) {
          queryClient.setQueryData(
            queryKeys.company(currentCompany?.id).ideas,
            context.oldIdeas,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
    },
  );
  return { removeAttachmentMutation };
};

export type UploadAttachment =
  | File
  | {
      file: File;
      name?: string;
      metadata?: Record<string, unknown>;
    };

export const useUploadAttachments = () => {
  const queryClient = useQueryClient();
  const { currentCompany } = useCurrentCompany();
  const uploadAttachmentsMutation = useMutation(
    ({
      entityName,
      entityId,
      files,
    }: {
      entityName: EntityTypeName;
      entityId: number;
      files: UploadAttachment[];
    }) => {
      function uploadAttachment(uploadFile: UploadAttachment) {
        const form = new FormData();
        let name, file, metadata;
        if (uploadFile instanceof File) {
          file = uploadFile;
          name = uploadFile.name;
        } else {
          file = uploadFile.file;
          name = uploadFile.name || uploadFile.file.name;
          metadata = uploadFile.metadata;
        }
        form.append(name, file, name);
        if (metadata) {
          form.append('attachments_metadata', JSON.stringify({ [name]: metadata }));
        }
        const url = Resources.ENTITY_ATTACHMENTS.replace(
          '<str:entity_name>',
          entityName,
        ).replace('<int:entity_id>', entityId.toString());
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        return ApiService.post(url, form, config).then(
          (result) => result.data as Attachment,
        );
      }

      return Promise.all(files.map((file) => uploadAttachment(file))).then((result) =>
        result.flat(),
      );
    },
    {
      onSuccess: (result, { entityName, entityId }) => {
        queryClient.invalidateQueries(
          queryKeys.attachmentsByEntity(entityName, entityId),
        );

        if (entityName === 'idea') {
          queryClient.setQueryData(
            queryKeys.company(currentCompany?.id).ideas,
            (oldIdeas?: Idea[]) =>
              oldIdeas?.map((oldIdea) =>
                oldIdea.id === entityId
                  ? {
                      ...oldIdea,
                      picture:
                        oldIdea.picture ??
                        result.find((att) =>
                          (imagesMimeTypes as readonly string[]).includes(att.file_type),
                        )?.url,
                    }
                  : oldIdea,
              ),
          );
        }
      },
      onError: (error) => {
        log.error(error instanceof Error ? error.message : error);
      },
    },
  );
  return { uploadAttachmentsMutation };
};
