import usePermissions from 'features/Auth/hook/usePermissions';
import { Permissions } from 'features/Auth/Permissions';
import { useProject } from 'features/Projects/hook/project';
import {
  OptionalProjectData,
  useProjectMissingData,
} from 'features/Projects/hook/useProjectMissingData';
import { useFeatureFlags, useTenantFeatureFlags } from 'hooks/useFeatureFlags';
import { PrivateRoutes, projectSections, Section } from 'Urls';
import log from 'loglevel';

type Route = Section & {
  isDataMissing?: boolean;
};
type SectionRoute = {
  sectionLabel: string;
  routes: Route[];
};

function getNewProjectRoutes({
  hasPerm,
  missingData,
  isProcurementEnabled,
  isReportingEnabled,
}: {
  hasPerm: (permission: string) => boolean;
  missingData: OptionalProjectData[];
  isProcurementEnabled: boolean;
  isReportingEnabled: boolean;
}) {
  return [
    {
      sectionLabel: 'SOLUTIONS',
      routes: [
        projectSections.vision,
        projectSections.foresite,
        projectSections.tvd,
        projectSections.calibrate,
        isProcurementEnabled ? projectSections.procurement : null,
        projectSections.risks,
        isReportingEnabled && hasPerm(Permissions.MANAGE_PROJECT)
          ? projectSections.reports
          : null,
      ].filter((section) => {
        if (!section) {
          return false;
        }
        const route = PrivateRoutes.find((route) => route.path === section.url);

        if (!route) {
          log.warn('Route not found for section: ', section);
          return false;
        }

        return !route.requiredPermission || hasPerm(route.requiredPermission);
      }),
    },
    {
      sectionLabel: 'MANAGE PROJECT',
      routes: [
        projectSections.details,
        {
          ...projectSections.timeline,
          isDataMissing: missingData.includes('TIMELINE'),
        },
        {
          ...projectSections.components,
          isDataMissing: missingData.includes('COMPONENTS'),
        },
        hasPerm(Permissions.VIEW_SUBTOTALS_FILE)
          ? {
              ...projectSections.estimateUpdate,
              isDataMissing: missingData.includes('ESTIMATE'),
            }
          : null,
        hasPerm(Permissions.MANAGE_PROJECT) ? projectSections.area : null,
      ].filter(Boolean),
    },
  ] as SectionRoute[];
}

function getLegacyProjectRoutes({
  hasPerm,
  isReportingEnabled,
}: {
  hasPerm: (permission: string) => boolean;
  isReportingEnabled: boolean;
}) {
  return [
    {
      sectionLabel: 'Overview',
      routes: [
        projectSections.overview,
        hasPerm(Permissions.MANAGE_PROJECT) ? projectSections.calibrate : null,
        isReportingEnabled && hasPerm(Permissions.MANAGE_PROJECT)
          ? projectSections.reports
          : null,
      ].filter(Boolean),
    },
    {
      sectionLabel: 'Manage project',
      routes: [
        projectSections.details,
        projectSections.components,
        hasPerm(Permissions.VIEW_SUBTOTALS_FILE) ? projectSections.estimateUpdate : null,
      ].filter(Boolean),
    },
  ] as SectionRoute[];
}

export function useSectionRoutes() {
  const { project } = useProject();
  const { hasPerm } = usePermissions();
  const { isMissingDataLoading, missingData } = useProjectMissingData();
  const { isProcurementEnabled, isReportingEnabled } = useFeatureFlags();
  const { tenantFeatureFlags } = useTenantFeatureFlags();
  if (!project || project.status === 'DRAFT_LEGACY') return [];

  if (project.status === 'LEGACY') {
    return getLegacyProjectRoutes({ hasPerm, isReportingEnabled });
  } else {
    return getNewProjectRoutes({
      hasPerm,
      missingData: isMissingDataLoading ? [] : missingData,
      isProcurementEnabled:
        isProcurementEnabled && !!tenantFeatureFlags.procurement_available,
      isReportingEnabled,
    });
  }
}
