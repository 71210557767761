import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useRedirectHome } from 'hooks/navigate';
import { History } from 'Urls';

export const ForbiddenView = () => {
  const { redirectHome } = useRedirectHome();

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        width: '100%',
        height: `80vh`,
      }}
    >
      <Box display="flex" gap="3rem" sx={{ maxWidth: '500px' }} alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          sx={{
            width: '7rem',
            height: '7rem',
            fontSize: '2.5rem',
            backgroundColor: 'grey.200',
            borderRadius: '200px',
          }}
        >
          <FontAwesomeIcon icon={faLockKeyhole} />
        </Box>
        <Stack spacing={2}>
          <Typography variant="h1">You&apos;ve stumbled on a restricted page</Typography>
          <Typography>
            There&apos; s nothing for you to see here, so let&apos; s get you back to
            where you came from.
          </Typography>
          <Box display="flex" gap="0.5rem">
            <Button onClick={() => History.goBack()}>Previous page</Button>
            <Button onClick={() => redirectHome?.()} variant="blueGhost">
              Go Home
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
