import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { queryKeys } from 'utils/reactQuery';

type InviteParams = {
  projectId: number;
  email: string;
  roleId: number;
  accessScopeId: number | null;
};

export const useProjectMemberInvite = () => {
  const queryClient = useQueryClient();
  const sendInviteMutation = useMutation(
    ({ projectId, email, roleId, accessScopeId }: InviteParams) => {
      const endPoint = Resources.PROJECT_TEAM_INVITE.replace(
        '<int:project_pk>',
        projectId.toString(),
      );

      return ApiService.post(endPoint, {
        email,
        role_id: roleId,
        access_scope_id: accessScopeId,
      }).then((res) => res.data);
    },
    {
      onSettled: (_response, _error, { projectId }) => {
        queryClient.invalidateQueries(queryKeys.project(projectId).members);
        queryClient.invalidateQueries(queryKeys.companyUsers);
      },
    },
  );
  return { sendInviteMutation };
};
