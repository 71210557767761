import { useSession } from 'features/Auth/hook/session';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Layout, PrivateURL, PublicURL } from 'Urls';
import PrivateLayout from 'layouts/Private.layout';
import {
  getProjectCurrentStep,
  replaceProjectIdPath,
  usePathWithSelectedProjectId,
} from 'hooks/navigate';
import queryString from 'query-string';
import { PENDING_PROJECT_REDIRECT } from 'utils/constants';
import { isEmpty, omit } from 'lodash-es';
import { useProject, useSwitchProject } from 'features/Projects/hook/project';
import { LoadingPage } from 'components/LoadingPage';
import log from 'loglevel';
import { NotFoundView } from 'views/auth/NotFound404View';

const Redirects = {
  SIGNUP: 'signup',
  RESET: 'reset',
  LOGIN: 'login',
  PROJECT: 'project',
};

export const PrivateRoute = (props: RouteProps) => {
  const { hasSession } = useSession();
  const location = useLocation();
  const { getPathWithSelectedProjectId } = usePathWithSelectedProjectId();
  const {
    project,
    isProjectFetching,
    isProjectLoading,
    status: projectStatus,
  } = useProject();
  let redirectToPath: string | null = null;
  let queryParams: string | null = null;
  let token: string | (string | null)[] = '';
  const query = queryString.parse(location.search);
  const { switchToProject } = useSwitchProject();
  const pendingRedirect = localStorage.getItem(PENDING_PROJECT_REDIRECT);

  if (projectStatus === 'error') {
    return <NotFoundView />;
  }
  const isBusy = isProjectLoading || isProjectFetching;

  if (query?.redirect === Redirects.SIGNUP) {
    redirectToPath = PublicURL.COMPLETE_SIGNUP;
    queryParams = new URLSearchParams(
      omit(query, ['redirect']) as Record<string, string>,
    ).toString();
    log.debug('Would redirect to ', Redirects.SIGNUP);
  } else if (query?.redirect === Redirects.RESET) {
    redirectToPath = PublicURL.PASSWORD;
    token = query?.token ?? '';
    log.debug('Would redirect to ', Redirects.RESET);
  } else if (query?.redirect === Redirects.LOGIN) {
    redirectToPath = PublicURL.LOGIN;
    log.debug('Would redirect to ', Redirects.LOGIN);
  } else if (hasSession) {
    if (pendingRedirect || (query.redirect === Redirects.PROJECT && query.id)) {
      if (pendingRedirect) {
        log.debug('There is a pending redirect');
        localStorage.removeItem(PENDING_PROJECT_REDIRECT);
      }

      const projectId = query.id || pendingRedirect;
      if (projectId && !isNaN(parseInt(projectId as string))) {
        log.debug('Would redirect to project ID ', projectId);
        switchToProject(parseInt(projectId as string), {
          to: replaceProjectIdPath(PrivateURL.PROJECT_UPDATE, projectId as string),
        });
      }
    } else if (location.pathname === Layout.ROOT) {
      if (!isBusy) {
        if (!isEmpty(project)) {
          const projectPath = getProjectCurrentStep(project);
          log.debug('Would redirect to project ID path: ', projectPath);
          redirectToPath = getPathWithSelectedProjectId(projectPath);
        } else {
          log.debug('Would redirect projects list');
          redirectToPath = PrivateURL.PROJECT_LIST;
        }
      } else {
        log.debug('This should be a transient state...');
      }
    }
  } else {
    log.debug('There is no session, redirecting to login');
    redirectToPath = PublicURL.LOGIN;
  }
  if (redirectToPath) log.debug('redirectToPath: ', redirectToPath);

  return hasSession && isBusy ? (
    <LoadingPage />
  ) : (
    <Route
      {...props}
      render={(renderProps) =>
        redirectToPath ? (
          <Redirect
            to={{
              pathname: redirectToPath,
              state: { from: renderProps.location, token },
              search: queryParams ?? '',
            }}
          />
        ) : (
          <PrivateLayout />
        )
      }
    />
  );
};
