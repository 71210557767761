import { useCallback, useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { IconButton, List, Tooltip, styled, useTheme, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { CurrentProjectItem } from './CurrentProjectItem';
import { NavigationLinkItems } from './NavigationLinkItems';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useSidebar } from 'hooks/ui';
import { LogoListItem } from './LogoListItem';
import { isEmpty } from 'lodash-es';
import { useQueryParam } from 'hooks/useQueryParam';
import { useProject, useSelectedProjectId } from 'features/Projects/hook/project';
import { useAllProjectSectionsEnabled } from 'hooks/useAllProjectSectionsEnabled';
import { useDebouncedCallback } from 'use-debounce';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    const { sidebarWidth } = useSidebar();

    return {
      '& .MuiDrawer-paper': {
        borderRadius: 0,
        border: 0,
        position: 'relative',
        whiteSpace: 'nowrap',
        width: sidebarWidth + '!important',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    };
  },
);

export const SideBar = () => {
  const [isDrawerButtonVisible, setIsDrawerButtonVisible] = useState(false);
  const { project, isProjectLoading, isProjectFetching } = useProject();
  const { selectedProjectId } = useSelectedProjectId();
  const {
    isSidebarCollapsed,
    toggleSidebar,
    sidebarWidth,
    setSidebarState,
    isSidebarHidden,
  } = useSidebar();

  const theme = useTheme();
  const lgBreakpoint = theme.breakpoints.values.xl;

  const { HasRoutePerm } = usePermissions();
  const query = useQueryParam<{ showStepper?: string }>();
  const showStepperQueryParam = query?.showStepper;
  const isStepperComplete = useAllProjectSectionsEnabled();

  const isProjectLoadingFetching = isProjectLoading || isProjectFetching;
  const statusShouldHaveDrawerHidden =
    !selectedProjectId || isEmpty(project) || !isStepperComplete;

  const updateSidebarState = useCallback(() => {
    if (isProjectLoadingFetching) return;

    if (statusShouldHaveDrawerHidden || showStepperQueryParam) {
      setSidebarState('hidden');
      return;
    }

    const shouldCollapseSidebarIfPossible = window.innerWidth <= lgBreakpoint;
    if (shouldCollapseSidebarIfPossible) {
      setSidebarState('collapsed');
    } else {
      setSidebarState('expanded');
    }
  }, [
    isProjectLoadingFetching,
    lgBreakpoint,
    setSidebarState,
    showStepperQueryParam,
    statusShouldHaveDrawerHidden,
  ]);
  const debouncedUpdateSidebarState = useDebouncedCallback(updateSidebarState, 400);

  useEffect(() => {
    updateSidebarState();
    window.addEventListener('resize', debouncedUpdateSidebarState);
    return () => {
      window.removeEventListener('resize', debouncedUpdateSidebarState);
    };
  }, [debouncedUpdateSidebarState, updateSidebarState]);

  return (
    <>
      <HasRoutePerm>
        <Tooltip title={!isSidebarCollapsed ? 'Collapse' : 'Expand'} placement="right">
          <IconButton
            size="small"
            onClick={() => toggleSidebar()}
            sx={[
              {
                position: isSidebarHidden ? 'absolute' : 'fixed',
                left: `calc(${sidebarWidth} - 0.75rem)`,
                top: (theme) => `calc(${theme.navBarHeight} + .5rem)`,
                width: '1.5rem',
                height: '1.5rem',
                zIndex: 1300,
                display: 'block',
                padding: '0',
                backgroundColor: 'common.white',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: (theme) => theme.palette.grey[200],
                transition: (theme) =>
                  theme.transitions.create('left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                },
                '& .svg-inline--fa': {
                  height: '16px',
                },
              },

              !isDrawerButtonVisible && {
                visibility: 'hidden',
              },
            ]}
            onMouseOver={() => setIsDrawerButtonVisible(true)}
            onMouseOut={() => setIsDrawerButtonVisible(false)}
          >
            <FontAwesomeIcon
              icon={!isSidebarCollapsed ? faChevronLeft : faChevronRight}
            />
          </IconButton>
        </Tooltip>
      </HasRoutePerm>

      <Drawer
        data-testid="project-sidebar"
        variant="permanent"
        open={!isSidebarCollapsed}
        onMouseOver={() => setIsDrawerButtonVisible(true)}
        onMouseOut={() => setIsDrawerButtonVisible(false)}
      >
        <HasRoutePerm>
          <List
            component="nav"
            sx={{
              color: 'grey.50',
              background: 'linear-gradient(161.05deg, #495164 0%, #2F3340 79.08%)',
              height: '100vh',
              position: isSidebarHidden ? 'relative' : 'fixed',
              width: sidebarWidth,
              transition: 'width 0.2s',
              overflow: 'hidden',
            }}
          >
            <LogoListItem />
            <CurrentProjectItem />
            <Box data-testid="sidebar-navigation-link-items">
              <NavigationLinkItems />
            </Box>
          </List>
        </HasRoutePerm>
      </Drawer>
    </>
  );
};
