import { useAtom } from 'jotai';
import { Item } from 'types/Item';
import { atomWithArraySet } from 'utils/atoms';
import { useForesiteSummary } from './foresite';
import { useItems } from './items';
import { useEffect } from 'react';
import { useBudgetAdjustedQuery } from 'features/TargetValueDesign/hooks/useBudgetAdjusted';

// Used by items and scenarios. Formerly known as `scenarios_filter`
// TODO: This atom should be just a state
const itemsFocusAtom = atomWithArraySet<number>([]);
itemsFocusAtom.debugLabel = 'itemsFocusAtom';

export const useItemsFocus = () => {
  const [itemsFocus, setItemFocus] = useAtom(itemsFocusAtom);
  const { items } = useItems();

  const resetItemsFocus = () => {
    setItemFocus({ nextValue: [] });
  };
  const unfocusItem = (itemId: number) => {
    setItemFocus({ nextValue: itemId, add: false });
  };

  const focusItem = (itemId: number) => {
    // Un select all Mutually Exclusive items
    const itemSelected = (items ?? []).find((item: Item) => item.id === itemId);
    for (const item of items ?? []) {
      if (
        itemSelected &&
        item.id !== itemSelected.id &&
        itemSelected.me_group_number !== null &&
        itemSelected.me_group_number === item.me_group_number &&
        itemsFocus.includes(item.id)
      ) {
        unfocusItem(item.id);
      }
    }
    setItemFocus({ nextValue: itemId, add: true });
  };

  useEffect(() => {
    // CPE-1995 When an item is focused and has no cost, it should be unfocused
    // get the items that are focused and have no cost
    const itemsFocusWithNoCost = itemsFocus.filter((itemId) => {
      const item = (items ?? []).find((item) => item.id === itemId);
      return item && !item.cost;
    });
    // unfocus them
    itemsFocusWithNoCost.forEach((itemId) => {
      unfocusItem(itemId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return {
    itemsFocus,
    focusItem,
    unfocusItem,
    resetItemsFocus,
  };
};

export const useItemFocusSummary = () => {
  const { items } = useItems();
  const { itemsFocus } = useItemsFocus();

  const { foresiteSummary, isForesiteSummaryFetching, isForesiteSummaryLoading } =
    useForesiteSummary();
  const { budgetAdjustedQuery } = useBudgetAdjustedQuery();

  const isBusy =
    isForesiteSummaryFetching ||
    isForesiteSummaryLoading ||
    !foresiteSummary ||
    budgetAdjustedQuery.isFetching ||
    budgetAdjustedQuery.isLoading;

  if (isBusy) {
    return { isBusy };
  }

  let costVariation = 0;
  let squareFootVariation = 0;

  const filteredItems = (items ?? []).filter((item: Item) =>
    itemsFocus.includes(item.id),
  );

  // Fix CPE-1741 & CPE-2005: truncate numbers as the backend does
  // see https://github.com/concntric/be-app/blob/b0d9c534795d9d5d93ee1149e17face25917e930/projects/models.py#L1459

  const revisedCost = Math.trunc(
    filteredItems.reduce(
      (acc: number, item: Item) => acc + item.cost,
      budgetAdjustedQuery.data?.cost ?? 0,
    ),
  );

  const revisedSqft = Math.trunc(
    filteredItems.reduce(
      (acc: number, item: Item) => acc + (item.estimated_sq_ft ?? 0),
      0,
    ) + (foresiteSummary.program_value ?? 0),
  );

  if (foresiteSummary) {
    costVariation = Math.trunc(
      (revisedCost * 100) / (budgetAdjustedQuery.data?.cost ?? 0),
    );
    squareFootVariation = Math.trunc(
      (revisedSqft * 100) / (foresiteSummary.program_value ?? 0),
    );

    if (isNaN(squareFootVariation)) squareFootVariation = 0;
    if (isNaN(costVariation)) costVariation = 0;
  }

  return { costVariation, squareFootVariation, revisedCost, revisedSqft, isBusy };
};
