import { useRef, useEffect } from 'react';
import { capitalize, isEmpty, keyBy } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { LoadingButton } from '@mui/lab';

import { useProject } from 'features/Projects/hook/project';
import { useProjectMemberInvite } from 'features/ManageUsers/hooks/projectMemberInvite';
import { useProjectMembers } from 'features/Projects/hook/members';
import { SelectLabel } from 'features/Estimate/components/SelectLabel/SelectLabel';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';
import { AccessScopeSelect } from 'features/ManageUsers/components/AccessScopeSelect/AccessScopeSelect';
import { useCurrentUser } from 'features/Auth/hook/session';
import { useCurrentCompany } from 'features/Company/hooks/company';
import { isAdminRole, isExternalUser } from 'utils/helpers';

type FormType = {
  email: string;
  role: number | null;
  accessScope: number | null;
};

export const InviteMemberForm = () => {
  const { currentCompany } = useCurrentCompany();
  const { sendInviteMutation } = useProjectMemberInvite();
  const { projectMembers } = useProjectMembers();
  const { currentUser } = useCurrentUser();

  const textFieldRef = useRef<HTMLElement>();

  const { successToast } = useToastDialogs();

  const errorMessage = capitalize(
    (sendInviteMutation.error as Error | undefined)?.message,
  );

  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();

  const rolesHash = keyBy(roles, 'id');

  const { project } = useProject();

  const InviteSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('E-mail is required')
      .notOneOf(
        projectMembers ? projectMembers.map(({ email }: { email: string }) => email) : [],
        'email already registered',
      ),
    role: Yup.number().required(),
    accessScope: Yup.number().nullable(),
  });

  useEffect(() => {
    // As requested by Mary, make sure that the active input gains focus (CPE-576)
    textFieldRef.current && textFieldRef.current.focus();
  }, []);

  // Function called when the user submits the form.
  const handleFormSubmit = (
    { email, role, accessScope }: FormType,
    { resetForm }: { resetForm: () => void },
  ) => {
    if (project?.id && role) {
      sendInviteMutation.mutate(
        {
          projectId: project.id,
          email,
          roleId: role,
          accessScopeId: isExternalUser(currentCompany, email) ? null : accessScope,
        },
        {
          onSuccess: () => {
            successToast({
              title: 'Project Member',
              text: 'Project member successfully invited',
            });
            resetForm();
          },
        },
      );
    }
  };

  const initialValues = { email: '', role: null, accessScope: null };

  return (
    <Formik<FormType>
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={InviteSchema}
      validateOnMount
    >
      {(formikProps) => {
        const {
          isValid,
          setFieldValue,
          handleSubmit,
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
        } = formikProps;

        const isExternal = isExternalUser(currentCompany, values.email);
        const role = values.role ? rolesHash[values.role] : null;
        const isAdminRoleSelected = role && isAdminRole(role);

        // External users can only be Owner or Designer
        if (isExternal && (isAdminRoleSelected || (role && !role.is_system_role))) {
          setFieldValue('role', null);
        }

        const isButtonDisabled =
          sendInviteMutation.isLoading ||
          !isValid ||
          values.role == null ||
          (!isExternal && values.accessScope === null);

        return (
          <form onSubmit={handleSubmit}>
            <Grid columnSpacing={2} container alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  inputRef={textFieldRef}
                  name="email"
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  value={values.email}
                  type="email"
                  disabled={sendInviteMutation.isLoading}
                  placeholder="Email"
                  fullWidth
                  autoFocus
                  label="Email"
                  error={!!((touched.email && errors.email) || errorMessage)}
                  helperText={(touched.email && errors.email) || errorMessage}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectLabel id="select-role-label">Role</SelectLabel>
                <Select
                  placeholder="Role"
                  labelId="select-role-label"
                  displayEmpty
                  name="role"
                  disabled={sendInviteMutation.isLoading}
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  renderValue={(value) => {
                    if (!roles || value == null) {
                      return (
                        <Typography sx={{ color: 'grey.400' }}>Select a role</Typography>
                      );
                    }
                    return roles.find((r) => r.id === value)?.name || '';
                  }}
                  startAdornment={
                    isEmpty(roles) && (
                      <Box sx={{ verticalAlign: 'middle' }}>
                        <CircularProgress size={24} />
                      </Box>
                    )
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                  sx={{ width: '100%', height: '2rem' }}
                >
                  {roles?.map((role) => {
                    // External users can only be Owner or Designer
                    const isDisabled =
                      isExternal && (isAdminRole(role) || !role.is_system_role);
                    const selectOption = (
                      <MenuItem
                        sx={{ width: '296px' }}
                        value={role.id}
                        disabled={isDisabled}
                        key={role.id}
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="textDefaultSemiBold">
                              {role.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                    return isDisabled ? (
                      <Tooltip
                        title={
                          isDisabled
                            ? 'External users can not be assigned to this role'
                            : ''
                        }
                        arrow
                        placement="top"
                        key={role.id}
                      >
                        <div>{selectOption}</div>
                      </Tooltip>
                    ) : (
                      selectOption
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  arrow
                  title={
                    isExternal
                      ? `Business Units are only for internal users, those which email domain is "@${currentCompany?.tld}"`
                      : ''
                  }
                >
                  <AccessScopeSelect
                    filterByMemberId={currentUser?.pk}
                    label="Business Unit"
                    placeholder="Business Unit"
                    value={isExternal ? '' : values.accessScope ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accessScope"
                    fullWidth
                    disabled={isExternal}
                  />
                </Tooltip>
              </Grid>
              <Grid container item xs={4} direction="column" justifyContent="flex-end">
                <LoadingButton
                  disabled={isButtonDisabled}
                  type="submit"
                  loading={sendInviteMutation.isLoading}
                  startIcon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                  sx={{ width: '100%' }}
                >
                  {sendInviteMutation.isLoading ? 'Sending...' : 'Send Invite'}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};
