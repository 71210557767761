import { PageHeader } from 'components/PageContainer/PageHeader';
import { projectSections, SectionType } from 'Urls';

export function ProjectPageHeader({ section }: { section: SectionType }) {
  return (
    <PageHeader icon={projectSections[section].icon}>
      {projectSections[section].label}
    </PageHeader>
  );
}
