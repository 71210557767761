import { useQueryClient } from '@tanstack/react-query';
import { useProject, useSelectedProjectId } from 'features/Projects/hook/project';
import {
  useComponents,
  useSaveComponent,
  useDeleteComponent,
  useChartComponentFocusId,
} from './components';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { queryKeys } from 'utils/reactQuery';

type UseCurrentProjectComponentsOptions = { includeUncategorized: boolean };

export const useCurrentProjectComponents = (
  options?: UseCurrentProjectComponentsOptions,
) => {
  const { project } = useProject();
  const {
    projectComponents: components,
    isProjectComponentsFetching: isComponentsFetching,
    isProjectComponentsLoading: isComponentsLoading,
  } = useComponents(project?.id, options?.includeUncategorized);
  return {
    components,
    isComponentsFetching,
    isComponentsLoading,
  };
};

export const useSaveCurrentProjectComponent = () => {
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { saveComponent, isSavingComponent } = useSaveComponent({
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'milestone' && query.queryKey[2] === 'subtotals',
      });
      queryClient.invalidateQueries(queryKeys.project(selectedProjectId).components);
      queryClient.invalidateQueries(queryKeys.project(selectedProjectId).componentAreas);
      invalidateTVDQueries(selectedProjectId);
      // TODO: invalidateQueries scenarios and items?
    },
  });
  return {
    saveComponent,
    isSavingComponent,
  };
};

export const useDeleteCurrentProjectComponent = () => {
  const { chartComponentFocusId, unfocusComponentId } = useChartComponentFocusId();
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { deleteComponent, isDeletingComponent } = useDeleteComponent({
    onSuccess: (
      _data: unknown,
      { componentId }: { componentId: number },
      _context: unknown,
    ) => {
      if (chartComponentFocusId === componentId) {
        unfocusComponentId();
      }
      invalidateTVDQueries(selectedProjectId);
      queryClient.invalidateQueries(queryKeys.project(selectedProjectId).components);

      const milestoneSubtotalKey = queryKeys.milestone(0).subtotals;
      const milestoneOwnerCostKey = queryKeys.milestone(0).ownerCosts;
      const milestoneEstimateKey = queryKeys.milestone(0).estimateMarkups;
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === milestoneSubtotalKey[0] &&
            query.queryKey[2] === milestoneSubtotalKey[2]) ||
          (query.queryKey[0] === milestoneOwnerCostKey[0] &&
            query.queryKey[2] === milestoneOwnerCostKey[2]) ||
          (query.queryKey[0] === milestoneEstimateKey[0] &&
            query.queryKey[2] === milestoneEstimateKey[2]),
      });

      // TODO: invalidateQueries scenarios and items?
    },
  });

  return {
    deleteComponent,
    isDeletingComponent,
  };
};
