import { SxProps, useTheme } from '@mui/material/styles';
import { getMemberFullnameOrEmail, getRangedHashFromNumber } from 'utils/helpers';
import { CircleChip } from './CircleChip';
import { Box, Theme, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';

type Member = {
  id: number;
  name: string;
  last_name: string;
  email?: string;
};
type MemberChipProps = {
  member: Member;
  sx?: SxProps<Theme>;
  disableTooltipHoverListener?: boolean;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
export const MemberChip = ({
  member,
  sx,
  disableTooltipHoverListener,
  isActive = true,
  onClick,
}: MemberChipProps) => {
  const theme = useTheme();
  const scenarioChipIndex = getRangedHashFromNumber(
    member.id,
    0,
    theme.palette.other.projectMembersChip.length,
  );
  // TODO: styles needs to be separated per element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chipSx: any = sx
    ? {
        width: 'width' in sx ? sx.width : null,
        height: 'height' in sx ? sx.height : null,
        borderRadius: 'borderRadius' in sx ? sx.borderRadius : null,
        '& .MuiChip-label': {
          fontSize: 'fontSize' in sx ? sx.fontSize : null,
          fontWeight: 'fontWeight' in sx ? sx.fontWeight : null,
        },
      }
    : null;

  const activeColor =
    sx && 'color' in sx
      ? sx.color
      : theme.palette.other.projectMembersChip[scenarioChipIndex].color;

  const activeBackgroundColor =
    sx && 'backgroundColor' in sx
      ? sx.backgroundColor
      : theme.palette.other.projectMembersChip[scenarioChipIndex].background;

  const label = member.name
    ? `${member.name[0]?.toUpperCase() || ''}${
        member.last_name?.[0]?.toUpperCase() || ''
      }`
    : member.email?.slice(0, 2).toUpperCase() || '';

  return (
    <Tooltip
      placement="top"
      title={getMemberFullnameOrEmail(member)}
      disableHoverListener={disableTooltipHoverListener}
    >
      <Box sx={sx} onClick={onClick}>
        <CircleChip
          sx={[
            {
              cursor: onClick ? 'pointer' : 'auto',
              background: isActive ? activeBackgroundColor : theme.palette.grey['100'],
              '& .MuiChip-label': {
                color: isActive ? activeColor : theme.palette.grey['700'],
              },
            },
            chipSx,
          ]}
          label={label}
        />
      </Box>
    </Tooltip>
  );
};
