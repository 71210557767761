import { useFormik } from 'formik';
import { User } from 'types/User';
import { FileWithPath } from 'react-dropzone';

// Credits https://dev.to/ankittanna/how-to-create-a-type-for-complex-json-object-in-typescript-d81
export type JSONValue =
  | null
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export const imagesMimeTypes = [
  'image/avif',
  'image/webp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
] as const;

export type ImageMimeType = (typeof imagesMimeTypes)[number];

export type Attachment = {
  id: number;
  entity_name: number;
  entity_id: number;
  url: string;
  uploaded_by: Omit<User, 'pk'> & {
    id: number;
  };
  is_deleted: boolean;
  deleted_at: string | null;
  file_name: string;
  file_type:
    | 'application/pdf'
    | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    | 'application/msword'
    | 'application/vnd.ms-excel'
    | ImageMimeType;
  file_size: number;
  s3_file_id: string;
  date_created: string | null;
  date_modified: string | null;
  tenant_company: number;
  metadata: JSONValue;
};

export type FileAttachment = {
  data: string; // file content or attachment url
} & (
  | (Attachment & {
      type: 'attachment';
    })
  | {
      id: string;
      file: FileWithPath;
      type: 'file';
    }
);

export type EntityTypeName = 'scenarioitem' | 'risk' | 'idea' | 'company' | 'bidpackage';

export type SetFieldValue = ReturnType<typeof useFormik>['setFieldValue'];

export type Nullable<T extends Record<string | number, unknown>> = {
  [K in keyof T]: T[K] | null;
};
