export const MuiCssBaseline = {
  styleOverrides: {
    ':root': {
      fontFeatureSettings: "'pnum' on, 'lnum' on",
    },

    'a:link, a:visited, a:hover, a:active': {
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
    mark: {
      backgroundColor: (theme) => theme.palette.blue[100],
    },

    // Make sure google places autocomplete box is rendered on top of MUI dialogs
    '.pac-container': {
      zIndex: '1500 !important',
    },
    // Hide google maps text overlays (copyright and terms of use)
    '.gmnoprint': {
      display: 'none',
    },
    // Hide google maps full screen toggle button
    '.gm-fullscreen-control': {
      display: 'none !important',
    },
  },
};
