import mixpanel, { Mixpanel as TMixpanel } from 'mixpanel-browser';
import { isProd } from 'utils/environment';

const shouldLogMixpanelEvents = Boolean(
  import.meta.env.VITE_FEATUREFLAG_MIXPANEL === 'true' || isProd(),
);

if (shouldLogMixpanelEvents) mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN);

type Actions = Pick<TMixpanel, 'identify' | 'track'> & {
  click: (target: string, to?: string) => void;
  pageLoaded: (target: string, to?: string) => void;
};

const actions: Actions = {
  identify: (id) => {
    if (!shouldLogMixpanelEvents) return;
    mixpanel.identify(id);
  },
  track: (name, props) => {
    if (!shouldLogMixpanelEvents) return;
    mixpanel.track(name, props);
  },
  click: (target, to) => {
    if (!shouldLogMixpanelEvents) return;
    if (to) mixpanel.track(`Click on ${target.toLowerCase()} from ${to.toLowerCase()}`);
    else mixpanel.track(`Click on ${target.toLowerCase()}`);
  },
  pageLoaded: (label) => {
    if (!shouldLogMixpanelEvents) return;
    Mixpanel.track(`Page ${label.toLowerCase()} loaded`);
  },
};

export const Mixpanel = actions;
