import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem } from '@mui/material';
import { IconMenuItemProps } from './types';

export const IconMenuItem = ({ children, icon, ...props }: IconMenuItemProps) => (
  <MenuItem {...props}>
    <Box mr={1} pb="2px" component={FontAwesomeIcon} icon={icon} />
    {children}
  </MenuItem>
);
