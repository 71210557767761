import {
  Box,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Chip,
  Tooltip,
  useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import { faCity, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Mixpanel } from 'services/Mixpanel';
import { useSidebar } from 'hooks/ui';

type SideBarListItemProps = {
  listIndex?: number;
  selected?: boolean;
  path?: string;
  icon?: IconProp;
  label: string;
  emptyLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isBeta?: boolean;
  isDataMissing?: boolean;
};

export const SideBarListItem = (props: SideBarListItemProps) => {
  const theme = useTheme();
  const boxWrapperProps = props.path
    ? {
        component: NavLink,
        to: props.path,
      }
    : {};
  const { isSidebarCollapsed } = useSidebar();

  return (
    <Tooltip title={isSidebarCollapsed ? props.label : ''} placement="right">
      <ListItem
        disablePadding
        sx={[
          {
            height: '2.5rem',
            margin: (theme) => `${theme.spacing(1.4)} ${theme.spacing(0.5)}`,
            marginTop: !props.listIndex || props.listIndex === 0 ? 0.5 : 1.4,
            borderRadius: '8px',
            width: 'calc(100% - 8px)',
            overflow: 'hidden',
          },
          !!props.selected && { backgroundColor: 'grey.700' },
        ]}
      >
        {props.selected && (
          <Box
            sx={{
              width: '3px',
              height: '1.5rem',
              backgroundColor: (theme) => theme.palette.primary.main,
              position: 'absolute',
              borderTopRightRadius: '3px',
              borderBottomRightRadius: '3px',
            }}
          ></Box>
        )}
        <Box {...boxWrapperProps} sx={{ flexGrow: 1 }}>
          <ListItemButton
            sx={{
              marginLeft: '0.5rem',
              marginRight: '.25rem',
              borderRadius: '8px',
            }}
            onClick={(e) => {
              Mixpanel.click(props.label, 'sidebar');
              props.onClick?.(e);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '2rem',
                marginLeft: '-0.75rem',
                '& .svg-inline--fa': {
                  color: (theme) => theme.palette.grey[50],
                },
              }}
            >
              <FontAwesomeIcon icon={props.icon || faCity} />
            </ListItemIcon>
            <ListItemText
              primary={
                props.isDataMissing ? props.emptyLabel ?? props.label : props.label
              }
              sx={{
                '& .MuiTypography-root': { fontSize: '0.875rem !important' },
              }}
            />

            {!!props.isBeta && (
              <Chip
                size="extraSmall"
                label="BETA"
                sx={{
                  color: 'grey.700',
                  backgroundColor: 'blue.400',
                  height: '1.25rem',
                  '& .MuiChip-label': {
                    fontWeight: 700,
                    px: '6px',
                  },
                }}
              />
            )}
            {!!props.isDataMissing && (
              <Tooltip title="Incomplete data">
                <FontAwesomeIcon
                  color={theme.palette.categoricYellow[700]}
                  icon={faTriangleExclamation}
                />
              </Tooltip>
            )}
          </ListItemButton>
        </Box>
      </ListItem>
    </Tooltip>
  );
};
