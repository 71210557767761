import { createBrowserHistory } from 'history';
import { Permissions } from 'features/Auth/Permissions';
import { lazily } from 'react-lazily';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBullseye,
  faCalculatorSimple,
  faChartLine,
  faChartScatter,
  faCubes,
  faDiagramSankey,
  faFileCheck,
  faFilePen,
  faFileChartColumn,
  faGaugeMax,
  faRulerTriangle,
  faTimelineArrow,
} from '@fortawesome/pro-regular-svg-icons';
import { LogoutView } from './views/auth/users/logout';
import { FeatureFlagsKeys, TenantFeatureFlagsKeys } from 'hooks/useFeatureFlags';
import { ReportsView } from 'views/reports';

const { VisionView } = lazily(() => import('views/projects/[projectId]/vision'));

const { ForeSiteView } = lazily(() => import('views/projects/[projectId]/foresite'));
const { Estimate } = lazily(() => import('views/projects/[projectId]/estimate'));
const { ComponentsSetupView } = lazily(
  () => import('views/projects/[projectId]/components'),
);
const { LoginView } = lazily(() => import('views/auth/users/login'));
const { CompleteSignUpView } = lazily(() => import('views/auth/users/complete'));
const { CalibrateByDivisionView } = lazily(
  () => import('views/projects/[projectId]/calibrate/division'),
);
const { CalibrateByProjectView } = lazily(
  () => import('views/projects/[projectId]/calibrate/index'),
);
const { ProjectNewOptions } = lazily(() => import('views/projects/new-options'));
const { ProjectBlankSetupView } = lazily(() => import('views/projects/blank'));
const { ProjectLegacySetupView } = lazily(() => import('views/projects/legacy/blank'));
const { ProjectLegacyUpdateView } = lazily(
  () => import('views/projects/legacy/[projectId]/index'),
);
const { ProjectLegacyComponentsView } = lazily(
  () => import('views/projects/legacy/[projectId]/components'),
);
const { ProjectLegacyEstimateView } = lazily(
  () => import('views/projects/legacy/[projectId]/estimate'),
);
const { ProjectDetailsView } = lazily(() => import('views/projects/[projectId]/index'));
const { ForbiddenView } = lazily(() => import('views/auth/ForbiddenView'));
const { TimelineSetupView } = lazily(() => import('views/projects/[projectId]/timeline'));
const { ProjectListView } = lazily(() => import('views/projects/index'));
const { NotFoundView } = lazily(() => import('views/auth/NotFound404View'));
const { PortfolioView } = lazily(() => import('views/Portfolio'));
const { LibraryView } = lazily(() => import('views/library'));
const { AddProjectMembersView } = lazily(
  () => import('views/projects/addProjectMembers'),
);
const { AreaSetupView } = lazily(() => import('views/projects/[projectId]/area-setup'));
const { TermsAndConditionsView } = lazily(() => import('views/terms-and-conditions'));
const { PrivacyPolicyView } = lazily(() => import('views/privacy-policy'));
const { RisksView } = lazily(() => import('views/projects/[projectId]/risks'));
const { ProjectOverview } = lazily(() => import('views/projects/[projectId]/overview'));
const { CustomCodesView } = lazily(() => import('views/custom-codes'));
const { ProcurementView } = lazily(
  () => import('views/projects/[projectId]/procurement'),
);
const { BidPackagesSetupView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/bid-packages-setup'),
);
const { PurchasePlanView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/purchase-plan'),
);
const { DocumentationView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/documentation'),
);
const { BidPackageDevelopmentView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/bid-package-development'),
);
const { CompanyProfilePage } = lazily(() => import('./views/companies/[companyId]'));
const { ManageUsers } = lazily(() => import('./views/users/manage'));
const { UserProfileView } = lazily(() => import('./views/users/[userId]'));
const { ResetPassword } = lazily(() => import('./views/auth/users/password'));
const { RecoveryView } = lazily(() => import('./views/auth/users/recovery'));
const { TargetValueDesignView } = lazily(() => import('views/TargetValueDesignView'));
const { BidPackageEditView } = lazily(
  () => import('views/projects/[projectId]/procurement/bid-package-details'),
);

export type UrlRoute = {
  name: string;
  path: string;
  component: () => JSX.Element | null;
  requiredPermission?: string;
  requiredFeatureFlags?: FeatureFlagsKeys[];
  requiredTenantFlags?: TenantFeatureFlagsKeys[];
};

export const History = createBrowserHistory();

export const Layout = {
  ROOT: '/',
  PRIVATE: '',
  PUBLIC: '/auth',
} as const;

// eslint-disable-next-line react-refresh/only-export-components
export const URL = {
  LOGIN: '/users/login',
  LOGOUT: '/users/logout',
  PASSWORD: '/users/password',
  COMPLETE_SIGNUP: '/users/complete',
  MANAGE_USERS: '/users/manage',
  RESET_PASSWORD: '/users/recovery',
  USER_PROFILE: '/users/:userId',
  PROJECT_SETUP: '/projects/blank',
  PROJECT_UPDATE: '/projects/:projectId',
  TIMELINE: '/projects/:projectId/timeline',
  COMPONENTS: '/projects/:projectId/components',
  OVERVIEW: '/projects/:projectId/overview',
  VISION: '/projects/:projectId/vision',
  TVD: '/projects/:projectId/tvd',
  ESTIMATE: '/projects/:projectId/estimate',
  INVITE_MEMBERS: '/projects/:projectId/invite-members',
  FORESITE: '/projects/:projectId/foresite',
  COMPARISON: '/projects/:projectId/comparison',
  CALIBRATE_DIVISION: '/projects/:projectId/calibrate/division',
  CALIBRATE: '/projects/:projectId/calibrate',
  AREA_SETUP: '/projects/:projectId/area-setup',
  RISKS: '/projects/:projectId/risks',
  PROCUREMENT: '/projects/:projectId/procurement',
  PROCUREMENT_WIZARD_BID_PACKAGES_SETUP:
    '/projects/:projectId/procurement/wizard/bid-packages-setup',
  PROCUREMENT_WIZARD_PURCHASE_PLAN:
    '/projects/:projectId/procurement/wizard/purchase-plan',
  PROCUREMENT_WIZARD_DOCUMENTATION:
    '/projects/:projectId/procurement/wizard/documentation',
  PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT:
    '/projects/:projectId/procurement/wizard/bid-package-development',
  PROCUREMENT_BID_PACKAGE_DETAILS:
    '/projects/:projectId/procurement/bid-package-details/:bidPackageId',
  PROJECT_LIST: '/projects',
  COMPANY_PROFILE: '/companies/:companyId',
  PORTFOLIO: '/portfolio',
  ABOUT: '/about',
  LANDING: '/',
  SERVICES: '/services',
  CONTACTS: '/contacts',
  PROJECT_NEW_OPTIONS: '/projects/new-options',
  PROJECT_LEGACY_SETUP: '/projects/legacy/blank',
  PROJECT_LEGACY_UPDATE: '/projects/legacy/:projectId',
  PROJECT_LEGACY_COMPONENTS: '/projects/legacy/:projectId/components',
  PROJECT_LEGACY_ESTIMATE: '/projects/legacy/:projectId/estimate',
  FORESITE_LIBRARY: '/library',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CUSTOM_CODES: '/custom-codes',
  FORBIDDEN: '/forbidden',
  REPORTS: '/projects/:projectId/reports',
} as const;

export const PublicURL = {
  LOGIN: Layout.PUBLIC + URL.LOGIN,
  LOGOUT: Layout.PUBLIC + URL.LOGOUT,
  PASSWORD: Layout.PUBLIC + URL.PASSWORD,
  COMPLETE_SIGNUP: Layout.PUBLIC + URL.COMPLETE_SIGNUP,
  RESET_PASSWORD: Layout.PUBLIC + URL.RESET_PASSWORD,
  OMNICLASS: '/omniclass.pdf',
  LANDING: 'https://concntric.com/',
  ABOUT: 'https://concntric.com/about/',
  SOLUTIONS: 'https://concntric.com/solutions/',
  CONTACTS: 'https://concntric.com/contact/',
} as const;

export const PrivateURL = {
  PROJECT_SETUP: Layout.PRIVATE + URL.PROJECT_SETUP,
  PROJECT_UPDATE: Layout.PRIVATE + URL.PROJECT_UPDATE,
  TIMELINE: Layout.PRIVATE + URL.TIMELINE,
  PROJECT_LIST: Layout.PRIVATE + URL.PROJECT_LIST,
  COMPONENTS: Layout.PRIVATE + URL.COMPONENTS,
  VISION: Layout.PRIVATE + URL.VISION,
  TVD: Layout.PRIVATE + URL.TVD,
  OVERVIEW: Layout.PRIVATE + URL.OVERVIEW,
  ESTIMATE: Layout.PRIVATE + URL.ESTIMATE,
  INVITE_MEMBERS: Layout.PRIVATE + URL.INVITE_MEMBERS,
  FORESITE: Layout.PRIVATE + URL.FORESITE,
  PROJECT_COMPARISON: Layout.PRIVATE + URL.COMPARISON,
  COMPANY_PROFILE: Layout.PRIVATE + URL.COMPANY_PROFILE,
  PORTFOLIO: Layout.PRIVATE + URL.PORTFOLIO,
  USER_PROFILE: Layout.PRIVATE + URL.USER_PROFILE,
  MANAGE_USERS: Layout.PRIVATE + URL.MANAGE_USERS,
  CALIBRATE: Layout.PRIVATE + URL.CALIBRATE,
  CALIBRATE_DIVISION: Layout.PRIVATE + URL.CALIBRATE_DIVISION,
  AREA_SETUP: Layout.PRIVATE + URL.AREA_SETUP,
  RISKS: Layout.PRIVATE + URL.RISKS,
  PROJECT_NEW_OPTIONS: Layout.PRIVATE + URL.PROJECT_NEW_OPTIONS,
  PROJECT_LEGACY_SETUP: Layout.PRIVATE + URL.PROJECT_LEGACY_SETUP,
  PROJECT_LEGACY_UPDATE: Layout.PRIVATE + URL.PROJECT_LEGACY_UPDATE,
  PROJECT_LEGACY_COMPONENTS: Layout.PRIVATE + URL.PROJECT_LEGACY_COMPONENTS,
  PROJECT_LEGACY_ESTIMATE: Layout.PRIVATE + URL.PROJECT_LEGACY_ESTIMATE,
  FORESITE_LIBRARY: Layout.PRIVATE + URL.FORESITE_LIBRARY,
  FORBIDDEN_VIEW: Layout.PRIVATE + URL.FORBIDDEN,
  TERMS_AND_CONDITIONS: Layout.PRIVATE + URL.TERMS_AND_CONDITIONS,
  PRIVACY_POLICY: Layout.PRIVATE + URL.PRIVACY_POLICY,
  CUSTOM_CODES: Layout.PRIVATE + URL.CUSTOM_CODES,
  PROCUREMENT: Layout.PRIVATE + URL.PROCUREMENT,
  PROCUREMENT_WIZARD_BID_PACKAGES_SETUP:
    Layout.PRIVATE + URL.PROCUREMENT_WIZARD_BID_PACKAGES_SETUP,
  PROCUREMENT_WIZARD_PURCHASE_PLAN: Layout.PRIVATE + URL.PROCUREMENT_WIZARD_PURCHASE_PLAN,
  PROCUREMENT_WIZARD_DOCUMENTATION: Layout.PRIVATE + URL.PROCUREMENT_WIZARD_DOCUMENTATION,
  PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT:
    Layout.PRIVATE + URL.PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT,
  PROCUREMENT_BID_PACKAGE_DETAILS: Layout.PRIVATE + URL.PROCUREMENT_BID_PACKAGE_DETAILS,
  REPORTS: Layout.PRIVATE + URL.REPORTS,
} as const;

export type PrivateURLKey = keyof typeof PrivateURL;

const BasePublicRoutes: UrlRoute[] = [
  {
    name: 'Setup New Password',
    path: PublicURL.PASSWORD,
    component: ResetPassword,
  },
  {
    name: 'Complete Sign Up',
    path: PublicURL.COMPLETE_SIGNUP,
    component: CompleteSignUpView,
  },
  {
    name: 'Reset Password',
    path: PublicURL.RESET_PASSWORD,
    component: RecoveryView,
  },
  {
    name: 'Login Page',
    path: PublicURL.LOGIN,
    component: LoginView,
  },
  {
    name: 'Logout Page',
    path: PublicURL.LOGOUT,
    component: LogoutView,
  },
];

export const PublicRoutes = BasePublicRoutes.map((pr) => ({
  ...pr,
  layout: Layout.PUBLIC,
}));

const procurementRequiredPerms: Partial<UrlRoute> = {
  requiredPermission: Permissions.MANAGE_PROJECT,
  requiredFeatureFlags: ['isProcurementEnabled'],
  requiredTenantFlags: ['procurement_available'],
};

// These should be in order
const basePrivateRoutes: UrlRoute[] = [
  {
    name: 'Timeline',
    path: PrivateURL.TIMELINE,
    component: TimelineSetupView,
  },
  {
    name: 'Components',
    path: PrivateURL.COMPONENTS,
    component: ComponentsSetupView,
  },
  {
    name: 'Vision',
    path: PrivateURL.VISION,
    component: VisionView,
  },
  {
    name: 'Target Value Design',
    path: PrivateURL.TVD,
    component: TargetValueDesignView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Overview',
    path: PrivateURL.OVERVIEW,
    component: ProjectOverview,
  },
  {
    name: 'ForeSite',
    path: PrivateURL.FORESITE,
    component: ForeSiteView,
  },
  {
    name: 'Estimate update',
    path: PrivateURL.ESTIMATE,
    component: Estimate,
    requiredPermission: Permissions.VIEW_SUBTOTALS_FILE,
  },
  {
    name: 'Reporting',
    path: PrivateURL.REPORTS,
    component: ReportsView,
    requiredPermission: Permissions.MANAGE_PROJECT,
    requiredFeatureFlags: ['isReportingEnabled'],
  },
  {
    name: 'Manage members',
    path: PrivateURL.INVITE_MEMBERS,
    component: AddProjectMembersView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Calibrate Compare by division',
    path: PrivateURL.CALIBRATE_DIVISION,
    component: CalibrateByDivisionView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Calibrate',
    path: PrivateURL.CALIBRATE,
    component: CalibrateByProjectView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Program update',
    path: PrivateURL.AREA_SETUP,
    component: AreaSetupView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Risk Register',
    path: PrivateURL.RISKS,
    component: RisksView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Procurement Wizard: Bid Packages Setup',
    path: PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGES_SETUP,
    component: BidPackagesSetupView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Procurement Wizard: Purchase plan',
    path: PrivateURL.PROCUREMENT_WIZARD_PURCHASE_PLAN,
    component: PurchasePlanView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Procurement Wizard: Documentation',
    path: PrivateURL.PROCUREMENT_WIZARD_DOCUMENTATION,
    component: DocumentationView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Procurement Wizard: Bid Package Development',
    path: PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT,
    component: BidPackageDevelopmentView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Procurement Bid Package Details',
    path: PrivateURL.PROCUREMENT_BID_PACKAGE_DETAILS,
    component: BidPackageEditView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Procurement',
    path: PrivateURL.PROCUREMENT,
    component: ProcurementView,
    ...procurementRequiredPerms,
  },
  {
    name: 'Users',
    path: PrivateURL.MANAGE_USERS,
    component: ManageUsers,
    // requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Blank Project - Setup',
    path: PrivateURL.PROJECT_SETUP,
    component: ProjectBlankSetupView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'New Project - Options',
    path: PrivateURL.PROJECT_NEW_OPTIONS,
    component: ProjectNewOptions,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Legacy Project - Setup',
    path: PrivateURL.PROJECT_LEGACY_SETUP,
    component: ProjectLegacySetupView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Legacy Project - Components setup',
    path: PrivateURL.PROJECT_LEGACY_COMPONENTS,
    component: ProjectLegacyComponentsView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Legacy Project - Estimate upload',
    path: PrivateURL.PROJECT_LEGACY_ESTIMATE,
    component: ProjectLegacyEstimateView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Legacy Project - Update',
    path: PrivateURL.PROJECT_LEGACY_UPDATE,
    component: ProjectLegacyUpdateView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Details',
    path: PrivateURL.PROJECT_UPDATE,
    component: ProjectDetailsView,
  },
  {
    name: 'My projects',
    path: PrivateURL.PROJECT_LIST,
    component: ProjectListView,
  },
  {
    name: 'Company Profile',
    path: PrivateURL.COMPANY_PROFILE,
    component: CompanyProfilePage,
  },
  {
    name: 'User Profile',
    path: PrivateURL.USER_PROFILE,
    component: UserProfileView,
  },
  {
    name: 'Portfolio',
    path: PrivateURL.PORTFOLIO,
    component: PortfolioView,
  },
  {
    name: 'Forbidden',
    path: PrivateURL.FORBIDDEN_VIEW,
    component: ForbiddenView,
  },
  {
    name: 'ForeSite Library',
    path: PrivateURL.FORESITE_LIBRARY,
    component: LibraryView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Terms and conditions',
    path: PrivateURL.TERMS_AND_CONDITIONS,
    component: TermsAndConditionsView,
  },
  {
    name: 'Privacy Policy',
    path: PrivateURL.PRIVACY_POLICY,
    component: PrivacyPolicyView,
  },
  {
    name: 'Custom Formats',
    path: PrivateURL.CUSTOM_CODES,
    component: CustomCodesView,
    requiredPermission: Permissions.MANAGE_PROJECT,
  },
  {
    name: 'Page Not Found',
    path: '/*',
    component: NotFoundView,
  },
];

export const PrivateRoutes = basePrivateRoutes.map((pr) => ({
  ...pr,
  layout: Layout.PRIVATE,
}));

export type Section = {
  label: string;
  emptyLabel?: string;
  url: string;
  icon?: IconProp;
  isBeta?: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const projectSections = {
  vision: {
    label: 'Vision',
    url: PrivateURL.VISION,
    icon: faChartLine,
  },
  foresite: {
    label: 'ForeSite',
    url: PrivateURL.FORESITE,
    icon: faDiagramSankey,
  },
  tvd: {
    label: 'Target Value Design',
    url: PrivateURL.TVD,
    icon: faBullseye,
  },
  overview: {
    label: 'Overview',
    url: PrivateURL.OVERVIEW,
    icon: faChartLine,
  },
  procurement: {
    label: 'Procurement',
    url: PrivateURL.PROCUREMENT,
    icon: faFileCheck,
    isBeta: true,
  },
  timeline: {
    label: 'Timeline',
    url: PrivateURL.TIMELINE,
    icon: faTimelineArrow,
  },
  calibrate: {
    label: 'Calibrate',
    url: PrivateURL.CALIBRATE,
    icon: faChartScatter,
  },
  risks: {
    label: 'Risk Register',
    url: PrivateURL.RISKS,
    icon: faGaugeMax,
  },
  details: {
    label: 'Details',
    url: PrivateURL.PROJECT_UPDATE,
    icon: faFilePen,
  },
  components: {
    label: 'Components',
    url: PrivateURL.COMPONENTS,
    icon: faCubes,
  },
  componentsSetup: {
    label: 'Component Setup',
    url: PrivateURL.COMPONENTS,
    icon: faCubes,
  },
  estimateUpdate: {
    label: 'Estimate Update',
    emptyLabel: 'Estimate Upload',
    url: PrivateURL.ESTIMATE,
    icon: faCalculatorSimple,
  },
  estimateSetup: {
    label: 'Estimate Setup',
    url: PrivateURL.ESTIMATE,
    icon: faCalculatorSimple,
  },
  area: {
    label: 'Program Update',
    url: PrivateURL.AREA_SETUP,
    icon: faRulerTriangle,
  },
  reports: {
    label: 'Reporting',
    url: PrivateURL.REPORTS,
    icon: faFileChartColumn,
  },
} as const;

export type SectionType = keyof typeof projectSections;

// eslint-disable-next-line react-refresh/only-export-components
export const getProjectSection = (url: PrivateURLKey) => {
  const privateUrl = PrivateURL[url];
  const section = Object.values(projectSections).find(
    (section) => section.url === privateUrl,
  ) as Section;
  return section?.emptyLabel ?? section?.label ?? '';
};
