export const DATE_FMT = 'MM/DD/YYYY';
export const DATE_FMT_PAYLOAD = 'YYYY-MM-DD';

export const DATE_FMT_LABEL = 'MMM DD, YYYY';
export const scrollbarWidth = 15;

// Local Storage keys
export const PENDING_PROJECT_REDIRECT = 'project-redirect-in-progress';
// Naming convention --> https://airbnb.io/javascript/#naming--uppercase
export const TYPOGRAPHY_VARIANTS = {
  fontFamily: 'fontFamily',
  h1: 'h1',
  h1SemiBold: 'h1SemiBold',
  h2: 'h2',
  h3: 'h3',
  h3Medium: 'h3Medium',
  h3SemiBold: 'h3SemiBold',
  h3Bold: 'h3Bold',
  h4: 'h4',
  h4Semibold: 'h4Semibold',
  h4Bold: 'h4Bold',
  h5: 'h5',
  h5SemiBold: 'h5SemiBold',
  h5Bold: 'h5Bold',
  body1: 'body1',
  textDefault: 'textDefault',
  textDefaultMedium: 'textDefaultMedium',
  textDefaultSemiBold: 'textDefaultSemiBold',
  textDefaultBold: 'textDefaultBold',
  textSmall: 'textSmall',
  textSmallBold: 'textSmallBold',
  textSmallSemiBold: 'textSmallSemiBold',
  textExtraSmall: 'textExtraSmall',
  textExtraSmallBold: 'textExtraSmallBold',
  textExtraSmallSemiBold: 'textExtraSmallSemiBold',
  textLarge: 'textLarge',
  textLargeBold: 'textLargeBold',
  textLargeSemiBold: 'textLargeSemiBold',
  textExtraLarge: 'textExtraLarge',
  textExtraLargeBold: 'textExtraLargeBold',
  textExtraLargeSemiBold: 'textExtraLargeSemiBold',
  monospaceDefault: 'monospaceDefault',
} as const;

export const BREAKPOINTS_KEYS = {
  xs: 'xs',
  xsplus: 'xsplus',
  sm: 'sm',
  smplus: 'smplus',
  md: 'md',
  mdplus: 'mdplus',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
};

export const MUI_BUTTON_VARIANTS = {
  contained: 'contained',
  blueGhost: 'blueGhost',
  blueText: 'blueText',
  greyGhost: 'greyGhost',
  greyText: 'greyText',
  redFilled: 'redFilled',
  greenFilled: 'greenFilled',
  yellowFilled: 'yellowFilled',
  yellowGhost: 'yellowGhost',
};

export const MUI_CHIP_VARIANTS = {
  blueGhost: 'blueGhost',
  outlined: 'outlined',
  green: 'green',
  red: 'red',
};

export const estimateTemplateType = {
  excel: 'excel',
  beckDestini: 'beckDestini',
  winestxls: 'winestxls',
  sage: 'sage',
};

export const ITERATIVE_MARKUP_ITERATION_LIMIT = 100;
export const ITERATIVE_MARKUP_PRECISION = 0.01;

export const ITEM_IMAGES_LIMIT_AMOUNT = 10;

export const MAX_FILENAME_LENGTH = 100;

export const FORESITE_ITEMS_PER_PAGE = 20;

// Regex to validate urls
export const WEB_URL_REGULAR_EXPRESSION =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9]+(?:[\-\.][a-zA-Z0-9]+)*(?:\.[a-zA-Z]{2,}|\.[a-zA-Z]{2,5})(?:\/[^\s/]+)*\/?$/;

// Regex to validate URL Domains: https://regexr.com/3au3g
export const WEB_URL_DOMAIN_REGULAR_EXPRESSION =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const ADMIN_GROUP_NAME = 'Admin';
