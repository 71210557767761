import { useSessionActions } from 'features/Auth/hook/session';
import { useEffect } from 'react';
import { History, PublicURL } from 'Urls';
import log from 'loglevel';

export function LogoutView() {
  const { clearSessionData } = useSessionActions();
  useEffect(() => {
    log.debug('Log user out');
    clearSessionData();
    setTimeout(() => {
      History.push(PublicURL.LOGIN);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
