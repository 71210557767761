import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { AxiosRequestConfig } from 'axios';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { useTenantFeatureFlags } from 'hooks/useFeatureFlags';
import { isEmpty, isFunction } from 'lodash-es';
import * as Sentry from '@sentry/react';
import { DesignMilestone } from 'types/DesignMilestones';
import { estimateTemplateType } from 'utils/constants';
import { mixed, number, object, string } from 'yup';
import { useActiveMilestone } from './milestone';
import { Subtotals } from 'types/Estimate';
import { ProjectMilestoneSummary } from 'types/Project';
import log from 'loglevel';
import { useCurrentUser } from 'features/Auth/hook/session';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { queryKeys } from 'utils/reactQuery';

export const useDownloadTemplate = () => {
  const { tenantFeatureFlags } = useTenantFeatureFlags();
  const downloadTemplate = (milestoneId: number, fileName: string) => {
    object({
      milestoneId: number().required().integer().positive(),
      fileName: string().required(),
    }).validate({ milestoneId, fileName });

    let endPoint;
    if (tenantFeatureFlags.TENANT_IS_USING_CUSTOM_TEMPLATE) {
      endPoint = Resources.ESTIMATE_CUSTOM_TEMPLATE;
    } else {
      endPoint = Resources.SUBTOTALS_UPLOAD.replace('<int:pk>', milestoneId.toString());
    }
    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'blob',
    };
    return ApiService.get(endPoint, config)
      .then((response) => {
        if (!response) {
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const header = response.headers['content-disposition'];
        const rawExtension = header?.split('extension=')?.[1] ?? '.xlsx';
        const extension = rawExtension.includes('.') ? rawExtension : '.' + rawExtension;
        link.setAttribute('download', fileName + extension);
        document.body.appendChild(link);
        link.click();
        return response;
      })
      .catch((error) => {
        log.error(error instanceof Error ? error.message : error);
        // eslint-disable-next-line promise/no-nesting
        (error.response?.data as Blob | undefined)?.text().then((text) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const jsonResponse = JSON.parse(text) as any;
          // eslint-disable-next-line promise/always-return
          if (jsonResponse.message) {
            Sentry.captureMessage(jsonResponse.message);
          }
        });
      });
  };

  return { downloadTemplate };
};

const endpoints = {
  [estimateTemplateType.excel]: Resources.SUBTOTALS_UPLOAD,
  [estimateTemplateType.beckDestini]: Resources.SUBTOTALS_UPLOAD_BECK_DESTINI,
  [estimateTemplateType.winestxls]: Resources.SUBTOTALS_UPLOAD_WINESTXLS,
  [estimateTemplateType.sage]: Resources.SUBTOTALS_UPLOAD_SAGE,
};

type UploadTemplateMutationArgument = {
  projectId: number;
  milestoneId: number;
  userId: number;
  file: File;
  templateType: string;
};
export const useUploadTemplate = () => {
  const queryClient = useQueryClient();
  const invalidateTVDQueries = useInvalidateTVDQueries();
  const { mutate: uploadEstimate, isLoading: isUploadingEstimate } = useMutation(
    ({
      projectId,
      milestoneId,
      userId,
      file,
      templateType,
    }: UploadTemplateMutationArgument) => {
      object({
        projectId: number().required().integer().positive(),
        milestoneId: number().required().integer().positive(),
        userId: number().required().integer().positive(),
        file: mixed().required(),
        templateType: string().required(),
      }).validate({ projectId, milestoneId, userId, file, templateType });

      const form = new FormData();
      form.append('file', file, file.name);
      form.append('design_milestone', milestoneId.toString());
      form.append('current_user', userId.toString());

      const url = endpoints[templateType];

      const endpoint = url.replace('<int:pk>', projectId.toString());
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return ApiService.post(endpoint, form, config).then((res) => res.data);
    },
    {
      onSuccess: (_, { projectId, milestoneId }) => {
        invalidateQueriesAfterEstimateChange({
          queryClient,
          activeMilestone: { id: milestoneId },
          selectedProjectId: projectId,
          invalidateTVDQueries,
        });
      },
    },
  );

  return { uploadEstimate, isUploadingEstimate };
};

const invalidateQueriesAfterEstimateChange = ({
  queryClient,
  activeMilestone,
  selectedProjectId,
  invalidateTVDQueries,
}: {
  queryClient: QueryClient;
  activeMilestone: Pick<DesignMilestone | ProjectMilestoneSummary, 'id'>;
  selectedProjectId: number;
  invalidateTVDQueries: (projectId: number) => void;
}) => {
  queryClient.invalidateQueries(queryKeys.milestone(activeMilestone.id).subtotals);
  queryClient.invalidateQueries(queryKeys.milestone(activeMilestone.id).estimateMarkups);
  queryClient.invalidateQueries(queryKeys.project(selectedProjectId).components);
  queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
  queryClient.invalidateQueries(queryKeys.project(selectedProjectId).milestones);
  queryClient.invalidateQueries(queryKeys.project(selectedProjectId).items);
  queryClient.invalidateQueries(queryKeys.project(selectedProjectId).componentAreas);
  queryClient.invalidateQueries(queryKeys.legacyProject(selectedProjectId).milestone);
  invalidateTVDQueries(selectedProjectId);
};

export const useUploadLastProjectTemplate = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { activeMilestone } = useActiveMilestone();
  const { currentUser } = useCurrentUser();
  const { uploadEstimate, isUploadingEstimate: isUploadingLastProjectEstimate } =
    useUploadTemplate();
  const queryClient = useQueryClient();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const uploadLastProjectEstimate = (
    { file, templateType }: { file: File; templateType: string },
    callbacks: { [key: string]: (...params: unknown[]) => void },
  ) => {
    if (!selectedProjectId || isEmpty(activeMilestone) || isEmpty(currentUser)) {
      throw new Error('Estimate could not be uploaded: missing context data');
    }
    if (activeMilestone && currentUser) {
      uploadEstimate(
        {
          projectId: selectedProjectId,
          milestoneId: (activeMilestone as DesignMilestone).id,
          userId: currentUser.pk,
          file,
          templateType,
        },
        {
          ...callbacks,
          onSuccess: (...params) => {
            invalidateQueriesAfterEstimateChange({
              queryClient,
              activeMilestone,
              selectedProjectId,
              invalidateTVDQueries,
            });

            if (isFunction(callbacks.onSuccess)) {
              callbacks.onSuccess(...params);
            }
          },
        },
      );
    }
  };

  return { uploadLastProjectEstimate, isUploadingLastProjectEstimate };
};

export const useSubtotals = (milestone?: DesignMilestone) => {
  const subtotalsQuery = useQuery(
    queryKeys.milestone(milestone?.id).subtotals,
    ({ signal }) => {
      const endPoint = Resources.SUBTOTALS_BY_MILESTONE_ID.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        milestone!.id.toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Subtotals);
    },
    {
      staleTime: Infinity,
      enabled: !!milestone?.id,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );

  return { subtotalsQuery };
};

export const useSelectedProjectSubtotals = () => {
  const { activeMilestone } = useActiveMilestone();
  const { subtotalsQuery: selectedProjectSubtotalsQuery } = useSubtotals(
    activeMilestone as DesignMilestone | undefined,
  );

  return {
    selectedProjectSubtotalsQuery,
  };
};

export const useClearMilestone = () => {
  const { activeMilestone } = useActiveMilestone();
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: clearEstimate, isLoading: isClearingEstimate } = useMutation(
    () => {
      if (activeMilestone) {
        const endpoint = Resources.ESTIMATE_CLEAR.replace(
          '<int:design_milestone_pk>',
          activeMilestone.id.toString(),
        );
        return ApiService.delete(endpoint).then((res) => res.data);
      } else {
        throw new Error('There is no active milestone selected');
      }
    },
    {
      onSettled: () => {
        if (activeMilestone && selectedProjectId) {
          invalidateQueriesAfterEstimateChange({
            queryClient,
            activeMilestone,
            selectedProjectId,
            invalidateTVDQueries,
          });
        } else {
          log.error(
            'useClearMilestone: There are no active milestone and project selected',
          );
        }
      },
    },
  );

  return {
    clearEstimate,
    isClearingEstimate,
  };
};
