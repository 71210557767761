import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { atom, useAtom } from 'jotai';
import { isEmpty, orderBy, partition } from 'lodash-es';
import log from 'loglevel';

import { ComponentBaseUnitWithCount, Component } from 'types/Component';
import { DesignMilestone } from 'types/DesignMilestones';
import { useSaveComponentBaseUnitsCount } from './useSaveComponentBaseUnitsCount';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSelectedProjectId } from 'features/Projects/hook/project';

// Atom used to select an Item Component to be edited
const editingComponentIdAtom = atom<number | null>(null);
const chartComponentFocusIdAtom = atom(0);

export const useChartComponentFocusId = () => {
  const [chartComponentFocusId, setChartComponentFocusId] = useAtom(
    chartComponentFocusIdAtom,
  );
  const unfocusComponentId = () => setChartComponentFocusId(0);
  return { chartComponentFocusId, setChartComponentFocusId, unfocusComponentId };
};

export const useEditingComponent = (projectId?: number | null) => {
  const [editingComponentId, setEditingComponentId] = useAtom(editingComponentIdAtom);
  const { projectComponents } = useComponents(projectId);
  const editingComponent =
    !!editingComponentId && !!projectId && projectComponents
      ? projectComponents.find((component) => component.id === editingComponentId)
      : null;
  return {
    editingComponentId,
    setEditingComponentId,
    editingComponent,
  };
};

type RQCallbacks = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: ((...data: any[]) => void) | undefined;
};

export const useComponents = (
  projectId?: number | null,
  includeUncategorized = false,
) => {
  const invalidateQueries = useInvalidateTVDQueries();
  const theme = useTheme();

  const {
    data,
    isLoading: isProjectComponentsLoading,
    isFetching: isProjectComponentsFetching,
  } = useQuery(
    queryKeys.project(projectId).components,
    ({ signal }) => {
      const endPoint =
        Resources.ALL_COMPONENTS_BY_PROJECT_ID.replace(
          '<int:pk>',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          projectId!.toString(),
        ) + `?include_uncategorized=true`;

      return ApiService.get(endPoint, { signal }).then((res) => res.data as Component[]);
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
      onSuccess: () => {
        if (projectId) {
          invalidateQueries(projectId);
        }
      },
    },
  );

  const projectComponents = useMemo(() => {
    const [uncategorized, categorized] = partition(data, 'is_uncategorized');

    const processComponent = (c: Component, index: number) => ({
      ...c,
      name: c.is_uncategorized ? 'Overall Project' : c.name,
      color: theme.palette.other.getComponentColor(index, c.is_uncategorized),
    });

    const extraComponents = includeUncategorized
      ? uncategorized.map(processComponent)
      : [];

    const components = orderBy(categorized, 'id', 'asc').map(processComponent);

    return [...orderBy(components, 'component_order', 'asc'), ...extraComponents];
  }, [data, includeUncategorized, theme.palette.other]);

  return {
    projectComponents,
    isProjectComponentsLoading,
    isProjectComponentsFetching,
  };
};

export const useReorderComponents = () => {
  const queryClient = useQueryClient();

  const reorderComponentsMutation = useMutation(
    ({ components, projectId }: { components: Component[]; projectId: number }) => {
      const endPoint = Resources.ALL_COMPONENTS_BY_PROJECT_ID.replace(
        '<int:pk>',
        projectId?.toString(),
      );

      return ApiService.patch(endPoint, { components }).then(
        (res) => res.data as Component[],
      );
    },
    {
      onMutate: async ({ components, projectId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys.project(projectId).components);
        const previousComponents = queryClient.getQueryData<Component[]>(
          queryKeys.project(projectId).components,
        );
        if (previousComponents) {
          const newComponents = Array.from(previousComponents);
          components.forEach((component) => {
            const index = newComponents.findIndex((c) => c.id === component.id);
            newComponents[index].component_order = component.component_order;
          });

          const [uncategorized, categorized] = partition(
            newComponents,
            'is_uncategorized',
          );

          queryClient.setQueryData(queryKeys.project(projectId).components, [
            ...orderBy(categorized, 'component_order', 'asc'),
            ...uncategorized,
          ]);
        }
        return { previousComponents };
      },
      onError: (error, { projectId }, context) => {
        if (context?.previousComponents) {
          queryClient.setQueryData(
            queryKeys.project(projectId).components,
            context.previousComponents,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
    },
  );

  return {
    reorderComponentsMutation,
  };
};

export function useFindComponentColor() {
  const { selectedProjectId } = useSelectedProjectId();
  const { projectComponents } = useComponents(selectedProjectId, true);

  const findComponentColor = (componentId: number) => {
    return projectComponents?.find((c) => c.id === componentId)?.color;
  };
  return { findComponentColor };
}

const isComponent = (componentData: Partial<Component>): componentData is Component =>
  !isEmpty(componentData) && !!componentData.id;

type SaveComponentArgument = {
  component: Partial<Component>;
  projectId: null | number;
  milestones: DesignMilestone[];
  baseUnit: ComponentBaseUnitWithCount;
};

export const useSaveComponent = (callbacks: RQCallbacks = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess } = callbacks;
  const { saveComponentBaseUnitsCount } = useSaveComponentBaseUnitsCount() as {
    saveComponentBaseUnitsCount: (data: unknown) => void;
  };

  const { mutate: saveComponent, isLoading: isSavingComponent } = useMutation(
    ({ component: componentData, projectId }: SaveComponentArgument) => {
      let request;

      if (!projectId) {
        throw Error('Expected projectId argument');
      }
      if (isComponent(componentData)) {
        const endPoint = Resources.COMPONENT_BY_ID.replace(
          '<int:pk>',
          componentData.id.toString(),
        );
        request = ApiService.patch(endPoint, componentData);
      } else {
        const endPoint = Resources.ALL_COMPONENTS_BY_PROJECT_ID.replace(
          '<int:pk>',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          projectId!.toString(),
        );
        request = ApiService.post(endPoint, componentData);
      }
      return request.then((res) => res.data);
    },
    {
      onMutate: async ({ component, projectId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys.project(projectId).components);
        const previousComponents = queryClient.getQueryData<Component[]>(
          queryKeys.project(projectId).components,
        );
        if (previousComponents) {
          let nextComponents: Component[];
          if (isComponent(component)) {
            nextComponents = previousComponents.map((previousComponent) =>
              previousComponent.id === component.id
                ? { ...previousComponent, ...component }
                : previousComponent,
            );
          } else {
            nextComponents = [
              ...previousComponents,
              { ...(component as Component), id: 0 },
            ] satisfies Component[];
          }

          queryClient.setQueryData(
            queryKeys.project(projectId).components,
            nextComponents,
          );
        }

        return { previousComponents };
      },
      onError: (error, { projectId }, context) => {
        if (context?.previousComponents) {
          queryClient.setQueryData(
            queryKeys.project(projectId).components,
            context.previousComponents,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: (
        data,
        error,
        { component: componentData, projectId, milestones, baseUnit },
      ) => {
        queryClient.invalidateQueries(queryKeys.project(projectId).components);
        if (!isComponent(componentData)) {
          const milestoneSubtotalKey = queryKeys.milestone(0).subtotals;
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === milestoneSubtotalKey[0] &&
              query.queryKey[2] === milestoneSubtotalKey[2],
          });
        }
        if (!error && baseUnit?.count) {
          saveComponentBaseUnitsCount({
            componentId: data.id,
            baseUnit,
            milestones,
            isNew: componentData.id !== data.id,
            projectId,
          });
        }
      },
      ...(onSuccess ? { onSuccess } : {}),
    },
  );

  return {
    isSavingComponent,
    saveComponent,
  };
};

export const useDeleteComponent = (callbacks: RQCallbacks = {}) => {
  const queryClient = useQueryClient();
  const { onSuccess } = callbacks;
  const { mutate: deleteComponent, isLoading: isDeletingComponent } = useMutation(
    ({
      componentId,
      projectId,
    }: {
      componentId?: number | null;
      projectId?: number | null;
    }) => {
      if (!projectId || !componentId) {
        throw Error('Expected componentId and projectId arguments');
      }
      const endPoint = Resources.COMPONENT_BY_ID.replace(
        '<int:pk>',
        componentId.toString(),
      );

      return ApiService.delete(endPoint).then((res) => res.data);
    },
    {
      onMutate: async ({ componentId, projectId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys.project(projectId).components);

        const previousComponents = queryClient.getQueryData<Component[]>(
          queryKeys.project(projectId).components,
        );

        if (previousComponents) {
          queryClient.setQueryData(
            queryKeys.project(projectId).components,
            previousComponents.filter((component) => component.id !== componentId),
          );
        }

        return { previousComponents };
      },
      onError: (error, { projectId }, context) => {
        if (context?.previousComponents) {
          queryClient.setQueryData(
            queryKeys.project(projectId).components,
            context.previousComponents,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: (_data, _error, { projectId }) => {
        queryClient.invalidateQueries(queryKeys.project(projectId).components);
      },
      ...(onSuccess ? { onSuccess } : {}),
    },
  );

  return {
    isDeletingComponent,
    deleteComponent,
  };
};
