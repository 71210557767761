import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, Stack } from '@mui/material';
import { ExportFileService } from 'api/ExportFileService';
import { DebouncedTextField } from 'components/DebouncedTextField/DebouncedTextField';
import { PageContainer } from 'components/PageContainer/PageContainer';
import { ProjectPageHeader } from 'components/PageContainer/ProjectPageHeader';
import { ViewTypeSelector } from 'features/Reports/components/ViewTypeSelector';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMemo, useState } from 'react';

import { ReportCardList } from 'features/Reports/components/ReportCardList';
import { ReporTableList } from 'features/Reports/components/ReportTableList';
import { ReportItem, ReportViewType } from 'types/Reports';

const data: ReportItem[] = [
  {
    id: 'VOREPORT',
    name: 'Value Optioneering',
    description: "Get a summary and also a detailed report of your project's Items.",
    formats: ['xlsx', 'pdf'],
    onDownload: ExportFileService.download,
  },
];

export const ReportsView = () => {
  const [viewType, setViewType] = useLocalStorage<ReportViewType>('list', 'grid');
  const [textFilter, setTextFilter] = useState('');

  const rows = useMemo(
    () =>
      data.filter(
        ({ name, description }) =>
          name.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()) ||
          description.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()),
      ),
    [textFilter],
  );

  return (
    <PageContainer fullWidth sx={{ pb: 2 }}>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center', gap: 1 }}
      >
        <ProjectPageHeader section="reports" />
        <ViewTypeSelector viewType={viewType} onChange={setViewType} sx={{ mb: 1 }} />
      </Stack>

      <DebouncedTextField
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        placeholder="Quick search"
        sx={{ mr: 1 }}
      />

      <ReportsList reports={rows} view={viewType} />
    </PageContainer>
  );
};

type ReportListProps = {
  view: ReportViewType;
  reports: ReportItem[];
};
const ReportsList = ({ view, reports }: ReportListProps) => {
  const ListComponent = {
    list: <ReporTableList reports={reports} />,
    grid: <ReportCardList reports={reports} />,
  } as const;
  return ListComponent[view];
};
