import { atom, useAtom } from 'jotai';
import {
  ToastDialog,
  ToastParams,
  ConfirmToastParams,
} from 'hooks/useToastDialogs.types';
import { useCallback } from 'react';

// TODO: This atom should be just a state
const toastAtom = atom<ToastDialog | null>(null);

export const useToastDialogs = () => {
  const [toast, setToast] = useAtom(toastAtom);
  const resetToast = useCallback(() => {
    setToast(null);
  }, [setToast]);

  const successToast = useCallback(
    ({ autoClose = true, ...params }: ToastParams) =>
      setToast({ type: 'success', params: { ...params, autoClose } }),
    [setToast],
  );

  const infoToast = useCallback(
    ({ autoClose = true, ...params }: ToastParams) =>
      setToast({ type: 'info', params: { ...params, autoClose } }),
    [setToast],
  );

  const errorToast = useCallback(
    ({ autoClose = true, title = 'Error', ...params }: ToastParams) =>
      setToast({ type: 'error', params: { ...params, title, autoClose } }),
    [setToast],
  );

  const confirmToast = useCallback(
    (params: ConfirmToastParams) => setToast({ type: 'confirm', params }),
    [setToast],
  );

  return { toast, confirmToast, infoToast, successToast, resetToast, errorToast };
};
