import { useCurrentProjectComponents } from 'features/ProjectComponents/hooks/currentProjectComponents';
import { useProject } from './project';
import { useMilestones } from 'features/Estimate/hooks/milestone';
import { PrivateURLKey } from 'Urls';
import { isEmpty } from 'lodash-es';
import { isLegacyOrDraftProject } from 'mappings/project';
import { useSubtotals } from 'features/Estimate/hooks/estimate';
import usePermissions from 'features/Auth/hook/usePermissions';

const optionalSteps = ['TIMELINE', 'COMPONENTS', 'ESTIMATE'] as const;
export type OptionalProjectData = (typeof optionalSteps)[number];

type ProjectSetupSteps = Extract<PrivateURLKey, OptionalProjectData>;

export const useProjectMissingData = (): {
  isMissingDataLoading: boolean;
  hasMissingData: boolean;
  missingData: ProjectSetupSteps[];
} => {
  const { project, isProjectLoading } = useProject();
  const { components, isComponentsLoading } = useCurrentProjectComponents();
  const { milestones, isMilestonesLoading } = useMilestones();
  const isProjectStatusLegacy = isLegacyOrDraftProject(project);
  // To double check that milestone has valid costs i.e. > 0
  const { subtotalsQuery: selectedProjectSubtotalsQuery } = useSubtotals(
    milestones.find((milestone) => milestone.has_estimate) || milestones[0],
  );

  const { ifHasPerm, Permissions } = usePermissions();

  const totals = selectedProjectSubtotalsQuery.data?._total;
  const totalCost = totals?._total?.cost ?? 0;

  const isMissingDataLoading =
    isProjectLoading ||
    isComponentsLoading ||
    isMilestonesLoading ||
    // If the user has no permission to view the subtotals file, we don't want to check if we are loading it.
    // Related to CPE-2773
    ifHasPerm(
      Permissions.VIEW_SUBTOTALS_FILE,
      selectedProjectSubtotalsQuery.isInitialLoading,
      false,
    );

  const predicates: Record<OptionalProjectData, () => boolean> = {
    TIMELINE: () => isEmpty(milestones),
    COMPONENTS: () => isEmpty(components) && !isProjectStatusLegacy,
    ESTIMATE: () =>
      !project ||
      // The totalCost check fixes when the estimate is uploaded with 0 cost
      // project.milestones_cost_count needs to be checked when the user can't get estimates
      // Related to CPE-2773 & CPE-3407
      ifHasPerm(
        Permissions.VIEW_SUBTOTALS_FILE,
        !totalCost,
        !project.milestones_cost_count,
      ),
  };
  const missingData = optionalSteps.filter((step) => predicates[step]());

  return { isMissingDataLoading, hasMissingData: !isEmpty(missingData), missingData };
};
