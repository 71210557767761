import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { omit } from 'lodash-es';
import { AccessScope, AccessScopeTree } from 'types/User';
import { queryKeys } from 'utils/reactQuery';

export const useAccessScopes = () => {
  const accessScopeQuery = useQuery(
    queryKeys.accessScopes,
    ({ signal }) => {
      return ApiService.get(Resources.ACCESS_SCOPES, { signal }).then(
        (res) => res.data as AccessScope[],
      );
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { accessScopeQuery };
};

const mapAccessScopesByParent = (accessScopes: AccessScope[]) =>
  accessScopes.reduce((acc, accessScope) => {
    const map = { ...acc };
    if (!Array.isArray(map[accessScope.parent ?? 0])) {
      map[accessScope.parent ?? 0] = [];
    }
    return {
      ...map,
      [accessScope.parent ?? 0]: map[accessScope.parent ?? 0].concat(accessScope),
    };
  }, {} as Record<number, AccessScope[]>);

const makeAccessScopeTree = (
  currentAccessScope: AccessScopeTree | AccessScope,
  parentMap: Record<number, AccessScope[]>,
  level = 0,
): AccessScopeTree => {
  const tree = { ...currentAccessScope, level } as AccessScopeTree;
  if (parentMap[currentAccessScope.id]) {
    tree.children = parentMap[currentAccessScope.id].map((scope) =>
      makeAccessScopeTree(scope, parentMap, level + 1),
    );
  }
  return tree;
};

export const useAccessScopesTree = () => {
  const { accessScopeQuery } = useAccessScopes();

  let data: AccessScopeTree | undefined;
  if (accessScopeQuery.data) {
    const rootScope = accessScopeQuery.data.find((scope) => scope.parent === null);
    if (rootScope) {
      const mappedAccessScopeByParent = mapAccessScopesByParent(accessScopeQuery.data);
      data = makeAccessScopeTree(rootScope, mappedAccessScopeByParent);
    }
  }
  return { accessScopeTreeQuery: { ...accessScopeQuery, data } };
};

export const useCreateAccessScope = () => {
  const queryClient = useQueryClient();

  const createAccessScopeMutationQuery = useMutation(
    (accessScope: Omit<AccessScope, 'id'>) => {
      return ApiService.post(Resources.ACCESS_SCOPES, {
        ...accessScope,
        members: accessScope.members.map(({ id }) => id),
      }).then((res) => res.data as AccessScope[]);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.accessScopes);
      },
    },
  );
  return { createAccessScopeMutationQuery };
};

export const useUpdateAccessScope = () => {
  const queryClient = useQueryClient();

  const updateAccessScopeMutationQuery = useMutation(
    (accessScope: AccessScope) => {
      const endPoint = Resources.ACCESS_SCOPE.replace('<int:pk>', String(accessScope.id));

      const payload = {
        ...omit(accessScope, 'users'),
        members: accessScope.members.map(({ id }) => id),
      };

      return ApiService.patch(endPoint, payload).then(
        (res) => res.data as { id: number },
      );
    },
    {
      onMutate: async (accessScope) => {
        await queryClient.cancelQueries(queryKeys.accessScopes);
        const previousAccessScopes = queryClient.getQueryData(queryKeys.accessScopes);
        if (previousAccessScopes) {
          queryClient.setQueryData(
            queryKeys.accessScopes,
            (oldAccessScopes?: AccessScope[]) =>
              oldAccessScopes?.map((oldAccessScope) =>
                oldAccessScope.id === accessScope.id
                  ? { ...oldAccessScope, ...accessScope }
                  : oldAccessScope,
              ),
          );
        }
        return previousAccessScopes;
      },
      onError: (error, _accessScope, previousPermissions) => {
        if (previousPermissions) {
          queryClient.setQueryData(queryKeys.accessScopes, previousPermissions);
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.accessScopes);
      },
    },
  );
  return { updateAccessScopeMutationQuery };
};

export const useDeleteAccessScope = () => {
  const queryClient = useQueryClient();

  const deleteAccessScopeMutationQuery = useMutation(
    (accessScopeId: number) => {
      const endPoint = Resources.ACCESS_SCOPE.replace('<int:pk>', String(accessScopeId));

      return ApiService.delete(endPoint).then((res) => res.data);
    },
    {
      onMutate: async (accessScopeId) => {
        await queryClient.cancelQueries(queryKeys.accessScopes);
        const previousAccessScopes = queryClient.getQueryData(queryKeys.accessScopes);
        if (previousAccessScopes) {
          queryClient.setQueryData(
            queryKeys.accessScopes,
            (oldAccessScopes?: AccessScope[]) =>
              oldAccessScopes?.filter(
                (oldAccessScope) => oldAccessScope.id !== accessScopeId,
              ),
          );
        }
        return previousAccessScopes;
      },
      onError: (error, _accessScope, previousPermissions) => {
        if (previousPermissions) {
          queryClient.setQueryData(queryKeys.accessScopes, previousPermissions);
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.accessScopes);
      },
    },
  );
  return { deleteAccessScopeMutationQuery };
};
