import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { ModelPermission, Role } from 'types/User';
import { queryKeys } from 'utils/reactQuery';
import { useSelectedRoleDetails } from './useSelectedRoleDetails';
import smallLogo from 'assets/img/concntric-logo-sm-light.svg';

export const systemRoleCreator = {
  id: 0,
  name: 'ConCntric',
  email: 'support@concntric.com',
  last_name: 'Inc',
  avatar: smallLogo,
};

export const usePermissionRoles = () => {
  const permissionRolesQuery = useQuery(
    queryKeys.permissionRoles,
    ({ signal }) => {
      return ApiService.get(Resources.PERMISSION_ROLES, { signal }).then(
        (res) => res.data as Role[],
      );
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { permissionRolesQuery };
};

export const usePermissionRolePermissions = () => {
  const { selectedRoleDetailsId } = useSelectedRoleDetails();
  const permissionRolePermissionsQuery = useQuery(
    queryKeys.permissionRolePermissions(selectedRoleDetailsId),
    ({ signal }) => {
      const endPoint = Resources.PERMISSION_ROLE_PERMISSIONS.replace(
        '<int:pk>',
        String(selectedRoleDetailsId),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ModelPermission[],
      );
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!selectedRoleDetailsId,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { permissionRolePermissionsQuery };
};

export const usePermissionRoleClone = () => {
  const queryClient = useQueryClient();

  const permissionRoleCloneMutationQuery = useMutation(
    (roleId: number) => {
      const endPoint = Resources.PERMISSION_ROLE_CLONE.replace(
        '<int:pk>',
        String(roleId),
      );
      return ApiService.post(endPoint);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.permissionRoles);
      },
    },
  );
  return { permissionRoleCloneMutationQuery };
};

export const useSavePermissionRolePermissions = () => {
  const queryClient = useQueryClient();

  const savePermissionRolePermissionsMutationQuery = useMutation(
    ({ roleId, permissions }: { roleId: number; permissions: ModelPermission[] }) => {
      const endPoint = Resources.PERMISSION_ROLE_PERMISSIONS.replace(
        '<int:pk>',
        String(roleId),
      );
      return ApiService.post(endPoint, permissions);
    },
    {
      onMutate: async ({ roleId, permissions }) => {
        await queryClient.cancelQueries(queryKeys.permissionRolePermissions(roleId));
        const previousPermissions = queryClient.getQueryData(
          queryKeys.permissionRolePermissions(roleId),
        );
        queryClient.setQueryData(
          queryKeys.permissionRolePermissions(roleId),
          permissions,
        );
        return previousPermissions;
      },
      onError: (error, { roleId }, previousPermissions) => {
        if (previousPermissions) {
          queryClient.setQueryData(
            queryKeys.permissionRolePermissions(roleId),
            previousPermissions,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: (_response, _error, { roleId }) => {
        queryClient.invalidateQueries(queryKeys.permissionRolePermissions(roleId));
      },
    },
  );
  return { savePermissionRolePermissionsMutationQuery };
};

export const useSavePermissionRole = () => {
  const queryClient = useQueryClient();

  const savePermissionRoleMutationQuery = useMutation(
    (role: Role) => {
      const endPoint = Resources.PERMISSION_ROLE.replace('<int:pk>', String(role.id));
      return ApiService.patch(endPoint, {
        ...role,
        members: role.members.map(({ id }) => id),
      });
    },
    {
      onMutate: async (role) => {
        await queryClient.cancelQueries(queryKeys.permissionRoles);
        const previousPermissionRoles = queryClient.getQueryData(
          queryKeys.permissionRoles,
        );

        if (previousPermissionRoles) {
          queryClient.setQueryData(
            queryKeys.permissionRoles,
            (oldPermissionRoles?: Role[]) =>
              oldPermissionRoles?.map((oldPermissionRole) =>
                oldPermissionRole.id === role.id
                  ? { ...oldPermissionRole, ...role }
                  : oldPermissionRole,
              ),
          );
        }

        return previousPermissionRoles;
      },
      onError: (error, _role, previousPermissionRoles) => {
        if (previousPermissionRoles) {
          queryClient.setQueryData(queryKeys.permissionRoles, previousPermissionRoles);
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.permissionRoles);
      },
    },
  );
  return { savePermissionRoleMutationQuery };
};

export const usePermissionRoleDelete = () => {
  const queryClient = useQueryClient();

  const permissionRoleDeleteMutationQuery = useMutation(
    (roleId: number) => {
      const endPoint = Resources.PERMISSION_ROLE.replace('<int:pk>', String(roleId));
      return ApiService.delete(endPoint);
    },
    {
      onMutate: async (roleId) => {
        await queryClient.cancelQueries(queryKeys.permissionRoles);
        const previousPermissionRoles = queryClient.getQueryData(
          queryKeys.permissionRoles,
        );

        if (previousPermissionRoles) {
          queryClient.setQueryData(
            queryKeys.permissionRoles,
            (oldPermissionRoles?: Role[]) =>
              oldPermissionRoles?.filter(
                (oldPermissionRole) => oldPermissionRole.id !== roleId,
              ),
          );
        }

        return previousPermissionRoles;
      },
      onError: (error, _role, previousPermissionRoles) => {
        if (previousPermissionRoles) {
          queryClient.setQueryData(queryKeys.permissionRoles, previousPermissionRoles);
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.permissionRoles);
      },
    },
  );
  return { permissionRoleDeleteMutationQuery };
};
