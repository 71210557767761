import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { Item } from 'types/Item';
import log from 'loglevel';
import { queryKeys } from 'utils/reactQuery';
import { orderBy } from 'lodash-es';

export const useItems = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const itemsQuery = useQuery(
    queryKeys.project(selectedProjectId).items,
    ({ signal }) => {
      const endPoint = Resources.ALL_ITEMS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        selectedProjectId as unknown as string,
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) =>
          orderBy(res.data, 'number', 'asc').map((item) => {
            if (item.status === 'VOID') {
              return {
                ...item,
                name: 'Void',
                proposed_by_name: '',
                due_date: null,
                priority: 'MEDIUM',
                estimated_sq_ft: 0,
                lower_limit: 0,
                upper_limit: 0,
              };
            } else {
              return item;
            }
          }) as Item[],
      );
    },
    {
      enabled: !!selectedProjectId,
      refetchOnWindowFocus: false,
      // We will manually trigger every time we need to refetch the items list
      staleTime: Infinity,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );

  return {
    ...itemsQuery,
    isInitialLoading: itemsQuery.isInitialLoading,
    items: itemsQuery.data?.filter((item) => !item.is_deleted),
    allItems: itemsQuery.data,
    isItemsLoading: itemsQuery.isLoading,
    isItemsFetching: itemsQuery.isFetching,
    itemsRefetch: itemsQuery.refetch,
    isItemsRefetching: itemsQuery.isRefetching,
  };
};

export const useUpdateItems = () => {
  const queryClient = useQueryClient();

  const updateItemsMutation = useMutation(
    ({ items, projectId }: { items: Item[]; projectId: number | null }) => {
      const endPoint = Resources.ALL_ITEMS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        projectId as unknown as string,
      );

      return ApiService.patch(endPoint, { items }).then((res) => res.data as Item[]);
    },
    {
      onMutate: async ({ items, projectId }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKeys.project(projectId).items);
        const previousItems = queryClient.getQueryData<Item[]>(
          queryKeys.project(projectId).items,
        );
        if (previousItems) {
          const newItems = Array.from(previousItems);
          items.forEach((item) => {
            const index = newItems.findIndex((c) => c.id === item.id);
            newItems[index] = { ...newItems[index], ...item };
          });

          queryClient.setQueryData(
            queryKeys.project(projectId).items,
            orderBy(newItems, 'item_order', 'asc'),
          );
        }
        return { previousItems };
      },
      onError: (error, { projectId }, context) => {
        if (context?.previousItems) {
          queryClient.setQueryData(
            queryKeys.project(projectId).items,
            context.previousItems,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
    },
  );

  return {
    updateItemsMutation,
  };
};
