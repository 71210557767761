import { useEffect, useRef } from 'react';
import { useCurrentUser } from 'features/Auth/hook/session';
import { Mixpanel } from './Mixpanel';

export function UserGuiding() {
  const { currentUser } = useCurrentUser();
  const hasBeenLoaded = useRef(false);
  useEffect(() => {
    if (hasBeenLoaded.current) return;
    const script = document.createElement('script');
    script.innerHTML = `
      (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','496607103ID');
    `;
    document.body.appendChild(script);
    hasBeenLoaded.current = true;
    return () => {
      // Clean up the injected script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      Mixpanel.identify(currentUser.pk + '');
      window.userGuiding.identify(currentUser.pk + '', {
        email: currentUser.email,
        name: [currentUser.profile.name, currentUser.profile.last_name].join(' '),
        env: process.env.NODE_ENV,
      });
    }
  }, [currentUser]);

  return <></>;
}
