import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { usePlaceholderData } from 'components/ForeSiteChart/hooks/usePlaceholderData';
import { useChartComponentFocusId } from 'features/ProjectComponents/hooks/components';
import { useCurrentProjectComponents } from 'features/ProjectComponents/hooks/currentProjectComponents';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { useProjectMissingData } from 'features/Projects/hook/useProjectMissingData';
import { atom, useAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import {
  ComponentSeries,
  Foresite,
  ForesiteAPIResult,
  ForesiteFiltering,
  ForesiteSummary,
} from 'types/ForeSite';
import { Item } from 'types/Item';
import { queryKeys } from 'utils/reactQuery';
import { useCustomizeTimelineSettings } from 'features/TimelineSetup/hooks/useCustomizeTimelineSettings';
import { useCallback } from 'react';

export const useForesiteSummary = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const {
    data: foresiteSummary,
    isLoading: isForesiteSummaryLoading,
    isFetching: isForesiteSummaryFetching,
    isError: isForesiteSummaryError,
  } = useQuery(
    queryKeys.project(selectedProjectId).foresiteSummary,
    ({ signal }) => {
      const endPoint = Resources.FORESITE_SUMMARY_BY_ID.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ForesiteSummary,
      );
    },
    {
      staleTime: Infinity,
      enabled: !!selectedProjectId,
    },
  );

  return {
    foresiteSummary,
    isForesiteSummaryLoading,
    isForesiteSummaryFetching,
    isForesiteSummaryError,
  };
};

const useAddForesiteFilteringCapabilities = () => {
  const { chartComponentFocusId } = useChartComponentFocusId();
  return (data?: ForesiteAPIResult) => {
    if (isEmpty(data)) {
      // Hot fix CPE-2351: we don't want to add filtering capabilities to empty data
      return data;
    }
    const filtering: ForesiteFiltering = {
      isFilterEnabled: chartComponentFocusId !== 0,
      filterSerie: (serie: ComponentSeries) => {
        return chartComponentFocusId === 0 || serie.id === chartComponentFocusId;
      },
      filterItem: (item: Item) =>
        chartComponentFocusId === 0 || item.component === chartComponentFocusId,
      getFilteredData: <Data>(dataById: Record<number, Data>) =>
        dataById[chartComponentFocusId],
      dimension: 'components',
      dimensionMember: chartComponentFocusId,
    };
    return {
      ...data,
      ...filtering,
    } as Foresite;
  };
};

export const usePatchForesiteResponse = () => {
  const { components } = useCurrentProjectComponents({
    includeUncategorized: true,
  });
  const { setting } = useCustomizeTimelineSettings();

  return useCallback(
    (foresite?: ForesiteAPIResult) => {
      if (!foresite) {
        return foresite;
      }
      // CPE-2267 workaround make sure that the components series is in the same order as the components
      // We end up with different colors for the same component
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const expectedOrder = components!.map((component) => component.id);
      const otherMilestones = setting.gmpLock.enabled
        ? foresite.other_milestones
        : foresite.other_milestones?.filter((m) => m.name !== 'GMP');
      return {
        ...foresite,
        other_milestones: otherMilestones,
        series: foresite.series
          .sort(
            (a: ComponentSeries, b: ComponentSeries) =>
              expectedOrder.indexOf(a.id) - expectedOrder.indexOf(b.id),
          )
          .map((serie) => {
            const data = setting.gmpLock.enabled
              ? serie.data
              : serie.data?.filter((m) => m.name !== 'GMP');
            return {
              ...serie,
              data,
              is_uncategorized: components?.find((member) => member.id === serie.id)
                ?.is_uncategorized,
            };
          }),
      };
    },
    [components, setting.gmpLock.enabled],
  );
};

export const useForesiteCost = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const addFilteringCapabilities = useAddForesiteFilteringCapabilities();
  const patchForesiteResponse = usePatchForesiteResponse();
  const { hasMissingData } = useProjectMissingData();
  const placeholderData = usePlaceholderData();
  const { query } = useCustomizeTimelineSettings();

  const enabled = !!selectedProjectId && !hasMissingData && query.isFetched;
  const {
    data: foresiteCost,
    isLoading: isForesiteCostLoading,
    isInitialLoading: isForesiteCostInitialLoading,
    isFetching: isForesiteCostFetching,
  } = useQuery(
    queryKeys.project(selectedProjectId).foresiteCost,
    ({ signal }) => {
      const endPoint = Resources.FORESITE_COST_BY_ID_V2.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ForesiteAPIResult,
      );
    },
    {
      staleTime: Infinity,
      enabled,
    },
  );

  return {
    foresiteCost: addFilteringCapabilities(
      hasMissingData ? placeholderData : patchForesiteResponse(foresiteCost),
    ) as Foresite,
    isForesiteCostFetching,
    isForesiteCostLoading,
    isForesiteCostInitialLoading,
  };
};

export const useForesiteProgram = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const addFilteringCapabilities = useAddForesiteFilteringCapabilities();
  const { hasMissingData } = useProjectMissingData();
  const placeholderData = usePlaceholderData();
  const { query } = useCustomizeTimelineSettings();
  const patchForesiteResponse = usePatchForesiteResponse();

  const enabled = !!selectedProjectId && !hasMissingData && query.isFetched;
  const {
    data: foresiteProgram,
    isLoading: isForesiteProgramLoading,
    isInitialLoading: isForesiteProgramInitialLoading,
    isFetching: isForesiteProgramFetching,
  } = useQuery(
    queryKeys.project(selectedProjectId).foresiteProgram,
    ({ signal }) => {
      const endPoint = Resources.FORESITE_PROGRAM_BY_ID_V2.replace(
        '<int:pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ForesiteAPIResult,
      );
    },
    {
      staleTime: Infinity,
      enabled,
    },
  );

  return {
    foresiteProgram: addFilteringCapabilities(
      hasMissingData ? placeholderData : patchForesiteResponse(foresiteProgram),
    ),
    isForesiteProgramFetching,
    isForesiteProgramLoading,
    isForesiteProgramInitialLoading,
  };
};

const foreSiteIgnoreOverallComponentAtom = atom(false);

export const useForeSiteIgnoreOverallComponent = () => {
  const [foreSiteIgnoreOverallComponent, setForeSiteIgnoreOverallComponent] = useAtom(
    foreSiteIgnoreOverallComponentAtom,
  );

  return { foreSiteIgnoreOverallComponent, setForeSiteIgnoreOverallComponent };
};
