import { ReactNode } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { joinSx } from 'utils/helpers';
import { TypographyKeys } from 'style/typography';

export function PageHeader({
  sx,
  icon,
  iconSize,
  children,
  variant = 'h4Semibold',
}: {
  icon?: IconProp;
  iconSize?: SizeProp;
  children: ReactNode;
  sx?: SxProps<Theme>;
  variant?: TypographyKeys;
}) {
  return (
    <Typography
      component="h1"
      variant={variant}
      sx={joinSx({ mb: 2, display: 'inline-block' }, sx)}
    >
      {icon ? (
        <Box sx={{ color: 'blue.700', mr: 1 }} component="span">
          <FontAwesomeIcon size={iconSize} icon={icon} />
        </Box>
      ) : null}
      {children}
    </Typography>
  );
}
