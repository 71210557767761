import { ButtonClasses, Theme } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    contained: true;
    blueGhost: true;
    blueText: true;
    greyGhost: true;
    greyText: true;
    redFilled: true;
    greenFilled: true;
    yellowFilled: true;
    yellowGhost: true;
    lightBlueGhost: true;
  }

  interface ButtonPropsSizeOverrides {
    extraSmall: true;
    small: true;
    medium: true;
    large: true;
  }
}

const filledButton = (theme: Theme) => ({
  color: theme.palette.common.white,
  padding: '6px 8px',
  borderRadius: '8px',
  boxShadow: 'none',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  textTransform: 'none',
  textDecorationLine: 'none !important',
  fontWeight: 600,

  '& .MuiButton-startIcon svg': {
    fontSize: '0.875rem',
  },
  '& .MuiButton-endIcon svg': {
    fontSize: '0.875rem',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.white,
  },
});

const ghostButton = (theme: Theme) => ({
  backgroundColor: theme.palette.common.white,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.grey[200],
  fontWeight: 600,
  '&.Mui-disabled': {
    borderColor: theme.palette.grey[100],
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[200],
  },
});

type MuiButtonStyleOverrides = Partial<
  OverridesStyleRules<keyof ButtonClasses, 'MuiButton', Theme>
>;

const DEFAULT_SIZE = 'small';
const buttonSizesHeightsMap = {
  extraSmall: '1.5rem',
  small: '2rem',
  medium: '2.5rem',
  large: '3rem',
};

export const MuiButton = {
  defaultProps: {
    // disableRipple: true,
    size: DEFAULT_SIZE,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      ...filledButton(theme),
      height: buttonSizesHeightsMap[ownerState.size ?? DEFAULT_SIZE],
      backgroundColor: theme.palette.primary.main,
      fontVariantNumeric: 'lining-nums',
      '&:hover': {
        backgroundColor: theme.palette.blue[800],
      },
      '&:active': {
        backgroundColor: theme.palette.blue[900],
      },
      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.blue[100]}`,
      },
      'a:link&, a:visited&': {
        color: theme.palette.common.white,
      },
    }),
  } as MuiButtonStyleOverrides,
  variants: [
    {
      props: { variant: 'blueGhost' },
      style: ({ theme }: { theme: Theme }) => ({
        ...ghostButton(theme),
        color: theme.palette.primary.main,
        'a&': {
          color: theme.palette.primary.main,
        },
        'a:link&, a:visited&': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.blue[50],
          borderColor: theme.palette.blue[800],
          color: theme.palette.blue[800],
        },
        '&:active': {
          backgroundColor: theme.palette.blue[100],
          borderColor: theme.palette.blue[900],
          color: theme.palette.blue[900],
        },
      }),
    },
    {
      props: { variant: 'blueText' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'transparent',
        color: theme.palette.primary.main,
        fontWeight: 600,
        'a&': {
          color: theme.palette.primary.main,
        },
        'a:link&, a:visited&': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.blue[50],
          borderColor: theme.palette.blue[800],
          color: theme.palette.blue[800],
        },
        '&:active': {
          backgroundColor: theme.palette.blue[100],
          borderColor: theme.palette.blue[900],
          color: theme.palette.blue[900],
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.grey[100],
          backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[200],
        },
      }),
    },
    {
      props: { variant: 'greyGhost' },
      style: ({ theme }: { theme: Theme }) => ({
        ...ghostButton(theme),
        color: theme.palette.grey.main,
        'a&': {
          color: theme.palette.grey.main,
        },
        'a:link&, a:visited&': {
          color: theme.palette.grey.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
        },
        '&:focus': {
          borderColor: theme.palette.grey[400],
          boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
        },
      }),
    },
    {
      props: { variant: 'greyText' },
      style: ({ theme }: { theme: Theme }) => ({
        backgroundColor: theme.palette.common.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.common.white,
        color: theme.palette.grey.main,
        fontWeight: 600,
        'a&': {
          color: theme.palette.grey.main,
        },
        'a:link&, a:visited&': {
          color: theme.palette.grey.main,
        },
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[200],
          borderColor: theme.palette.grey[300],
        },
        '&:focus': {
          borderColor: theme.palette.grey[400],
          boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.common.white,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.grey[200],
        },
      }),
    },
    {
      props: { variant: 'redFilled' },
      style: ({ theme }: { theme: Theme }) => ({
        ...filledButton(theme),
        backgroundColor: theme.palette.cautionRed[700],
        '&:hover': {
          backgroundColor: theme.palette.cautionRed[800],
        },
        '&:active': {
          backgroundColor: theme.palette.cautionRed[900],
        },
        '&:focus': {
          boxShadow: `0px 0px 0px 2px ${theme.palette.cautionRed[100]}`,
        },
      }),
    },
    {
      props: { variant: 'greenFilled' },
      style: ({ theme }: { theme: Theme }) => ({
        ...filledButton(theme),
        backgroundColor: theme.palette.successGreen[700],
        '&:hover': {
          backgroundColor: theme.palette.successGreen[800],
        },
        '&:active': {
          backgroundColor: theme.palette.successGreen[900],
        },
        '&:focus': {
          boxShadow: `0px 0px 0px 2px ${theme.palette.successGreen[100]}`,
        },
      }),
    },
    {
      props: { variant: 'yellowFilled' },
      style: ({ theme }: { theme: Theme }) => ({
        ...filledButton(theme),
        backgroundColor: theme.palette.categoricYellow[800],
        '&:hover': {
          backgroundColor: theme.palette.categoricYellow[900],
        },
        '&:active': {
          backgroundColor: theme.palette.categoricYellow[900],
        },
        '&:focus': {
          boxShadow: `0px 0px 0px 2px ${theme.palette.categoricYellow[100]}`,
        },
      }),
    },
    {
      props: { variant: 'yellowGhost' },
      style: ({ theme }: { theme: Theme }) => ({
        ...ghostButton(theme),
        color: theme.palette.categoricYellow[800],
        borderColor: theme.palette.categoricYellow[300],
        'a&': {
          color: theme.palette.categoricYellow[800],
        },
        'a:link&, a:visited&': {
          color: theme.palette.categoricYellow[800],
        },
        '&:hover': {
          backgroundColor: theme.palette.categoricYellow[100],
          borderColor: theme.palette.categoricYellow[500],
          color: theme.palette.categoricYellow[900],
        },
        '&:active': {
          backgroundColor: theme.palette.categoricYellow[200],
          borderColor: theme.palette.categoricYellow[900],
          color: theme.palette.categoricYellow[900],
        },
      }),
    },
    {
      props: { variant: 'lightBlueGhost' },
      style: ({ theme }: { theme: Theme }) => ({
        ...ghostButton(theme),
        backgroundColor: theme.palette.blue[50],
        color: theme.palette.blue[700],
        borderColor: theme.palette.blue[100],
        'a&': {
          color: theme.palette.blue[700],
        },
        'a:link&, a:visited&': {
          color: theme.palette.blue[700],
        },
        '&:hover': {
          backgroundColor: theme.palette.blue[100],
          borderColor: theme.palette.blue[200],
          color: theme.palette.blue[800],
        },
        '&:active': {
          backgroundColor: theme.palette.blue[50],
          borderColor: theme.palette.blue[700],
        },
        '&:focus': {
          borderColor: theme.palette.blue[200],
          boxShadow: `0px 0px 0px 2px ${theme.palette.blue[50]}`,
        },
        '&.Mui-disabled': {
          color: theme.palette.blue[200],
          backgroundColor: theme.palette.blue[50],
          borderColor: theme.palette.blue[50],
        },
      }),
    },
  ],
};
