import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { History, Layout } from 'Urls';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { useClearProjectData } from 'features/Projects/hook/project';
import { User } from 'types/User';
import { LocationState } from 'types/globals';
import log from 'loglevel';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePwa } from 'components/PwaProvider/usePwa';
import { isProd } from 'utils/environment';
import Cookies from 'js-cookie';
import { UserCompany } from 'types/Company';
import { getCurrentTenantSlug } from 'utils/helpers';
import { queryKeys } from 'utils/reactQuery';

const USERNAME_LS_KEY = 'concntric-login-last-username';
const CURRENT_USER_LS_KEY = 'concntric-current-user';
export const TOKEN_SS_KEY = 'concntric-token';

type Session = {
  access: string | null;
  refresh: string | null;
  persist?: boolean;
};

const currentUserAtom = atomWithStorage<User | null>(CURRENT_USER_LS_KEY, null);
currentUserAtom.debugLabel = 'currentUserAtom';

// Get the 3 last elements of the hostname
const domain = new URL(window.location.href).hostname.split('.').slice(-3).join('.');

const cookieConfig = {
  domain: `.${domain}`,
  path: '/',
  secure: isProd(),
};

export const useSession = () => {
  const hasSession = !!Cookies.get(TOKEN_SS_KEY);

  const setSession = (session: Session) => {
    if (session.access) {
      Cookies.set(TOKEN_SS_KEY, session.access, cookieConfig);
    }
  };

  const clearSession = () => {
    log.debug('Clearing session');
    Cookies.remove(TOKEN_SS_KEY, cookieConfig);
  };

  return { setSession, hasSession, clearSession };
};

const useClearCacheData = () => {
  const queryClient = useQueryClient();
  const clearCacheData = () => {
    queryClient.cancelQueries();
    queryClient.clear();
  };
  return {
    clearCacheData,
  };
};

export const useSessionActions = () => {
  const location = useLocation<LocationState>();
  const { setSession, clearSession } = useSession();
  const { clearProjectData } = useClearProjectData();
  const storedUsername = localStorage.getItem(USERNAME_LS_KEY);
  const { loadCurrentUser, clearCurrentUser, validateCurrentUserDomain } =
    useCurrentUser();
  const { clearCacheData } = useClearCacheData();
  const {
    updateServiceWorker,
    needRefresh: [needRefresh, setNeedRefresh],
  } = usePwa();
  const { isPWAEnabled } = useFeatureFlags();

  const clearSessionData = () => {
    clearSession();
    clearProjectData();
    clearCurrentUser();
    clearCacheData();
  };

  const login = async (email: string, password: string, rememberMe = false) => {
    const sessionData = await ApiService.post(Resources.AUTH, {
      email,
      password,
    }).then(({ data }) => data as Session);

    setSession({ ...sessionData, persist: rememberMe });
    await validateCurrentUserDomain();
    await loadCurrentUser();
    if (needRefresh && isPWAEnabled) {
      setNeedRefresh(false);
      await updateServiceWorker(true);
      window.location.reload();
    }
    setTimeout(() => {
      const next = location.state?.from?.pathname ? location.state.from : Layout.ROOT;
      History.push(next);
    }, 0);
  };

  // const refreshToken = async () => {
  //   if (hasSession) {
  //     // What does this return when the token has expired?
  //     try {
  //       const refreshedSession = await ApiService.post(Resources.REFRESH_AUTH).then(
  //         ({ data }) => data as Session,
  //       );
  //       setSession(refreshedSession);
  //     } catch (e) {
  //       History.push(PublicURL.LOGOUT);
  //     }
  //   }
  // };`

  return { clearSessionData, storedUsername, login };
};

const getCurrentUserCompanies = async (signal?: AbortSignal) => {
  try {
    const responseData = await ApiService.get(Resources.CURRENT_USER_COMPANIES, {
      signal,
    }).then(({ data }) => data);
    return responseData as UserCompany[];
  } catch (error) {
    log.error(
      error instanceof Error
        ? error.message
        : error || 'Current user companies could not be loaded',
    );
  }
};

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);

  const validateCurrentUserDomain = async () => {
    const userCompanies = await getCurrentUserCompanies();
    if (userCompanies && userCompanies.length) {
      if (
        !getCurrentTenantSlug() ||
        !userCompanies.some((company) => company.slug === getCurrentTenantSlug())
      ) {
        const url = new URL(window.location.href);
        const baseHostname = url.hostname.split('.').slice(-3).join('.');
        url.hostname = `${userCompanies[0].slug}.${baseHostname}`;
        log.debug('Redirecting user to a valid tenant: ' + url.toString());
        return window.location.replace(url.toString());
      }
      log.debug('Logged user is in a valid tenant');
    } else {
      throw new Error(
        'Can not redirect user to valid domain: there are no companies associated to this user',
      );
    }
  };

  const loadCurrentUser = async () => {
    try {
      const responseData = await ApiService.get(Resources.CURRENT_USER, {}).then(
        ({ data }) => data as User,
      );
      setCurrentUser(responseData);
    } catch (error) {
      log.error(
        error instanceof Error
          ? error.message
          : error || 'Current user could not be loaded',
      );
    }
  };

  const clearCurrentUser = () => {
    setCurrentUser(null);
  };

  return { currentUser, loadCurrentUser, clearCurrentUser, validateCurrentUserDomain };
};

export const useCurrentUserCompanies = () => {
  const currentUserCompaniesQuery = useQuery(
    queryKeys.currentUserCompanies,
    ({ signal }) => getCurrentUserCompanies(signal),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return { currentUserCompaniesQuery };
};
