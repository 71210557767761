import { Grid, Theme } from '@mui/material';
import { PublicURL } from 'Urls';
import { useSidebar } from 'hooks/ui';

import { APP_VERSION, GIT_HASH } from 'utils/environment';

type FooterProps = {
  isPublicLayout?: boolean;
};
export const Footer = (props: FooterProps) => {
  const { sidebarWidth } = useSidebar();

  const calcWidth = (theme: Theme) => {
    return props.isPublicLayout
      ? '100vw'
      : `calc(100vw - ${sidebarWidth} - ${theme.spacing(3)})`;
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: (theme) => theme.spacing(1),
        marginLeft: 0,
        position: 'absolute',
        bottom: 0,
        width: calcWidth,
      }}
    >
      <Grid item>
        <a href={PublicURL.ABOUT} target="_BLANK">
          About us
        </a>
      </Grid>
      <Grid item>
        <a href={PublicURL.SOLUTIONS} target="_BLANK">
          Solutions
        </a>
      </Grid>
      <Grid item>
        <a href={PublicURL.CONTACTS} target="_BLANK">
          Contacts
        </a>
      </Grid>
      <Grid item>
        <a href={PublicURL.OMNICLASS} target="_BLANK">
          Omniclass Disclaimer
        </a>
      </Grid>
      <Grid item sx={{ marginLeft: 'auto' }}>
        <a href={PublicURL.LANDING} target="_BLANK">
          &copy; {new Date().getFullYear()} ConCntric, Inc &nbsp;
          {`v${APP_VERSION}(${GIT_HASH})`}
        </a>
      </Grid>
    </Grid>
  );
};
