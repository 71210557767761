import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { AxiosRequestConfig } from 'axios';
import { Project } from 'types/Project';
import { addSearchParamsToUrl } from 'utils/helpers';

export class ExportFileService {
  static download(project: Project, type = 'xlsx') {
    if (typeof project.id !== 'number' || project.id < 0) {
      throw Error();
    }
    let url = Resources.FORESITE_PDF_DOWNLOAD.replace('<int:pk>', project.id.toString());

    if (type) {
      url = addSearchParamsToUrl({
        url,
        searchParams: {
          type,
        },
      });
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'blob',
    } satisfies AxiosRequestConfig;

    return ApiService.get(url, config).then((response) => {
      if (!response) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${project.name} - Foresite.${type ?? 'pdf'}`);
      document.body.appendChild(link);
      link.click();
      return response;
    });
  }
}
