import { useSetting } from 'hooks/useSetting';
import { CustomizeTimelineSettings } from 'types/Setting';

export function useCustomizeTimelineSettings() {
  return useSetting<CustomizeTimelineSettings>('customize-timeline', {
    gmpLock: {
      enabled: true,
    },
  });
}
