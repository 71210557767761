import { useCurrentUser } from 'features/Auth/hook/session';
import { atom, Getter, Setter, useAtom, WritableAtom } from 'jotai';
import { atomWithReducer, RESET } from 'jotai/utils';
import { useEffect } from 'react';
import { User } from 'types/User';
import { ObjectValues } from 'types/helpers';

// https://jotai.org/docs/advanced-recipes/atom-creators#atom-with-toggle
export const atomWithToggle = (initialValue?: boolean) => {
  const booleanAtom = atom(
    initialValue,
    (get: Getter, set: Setter, nextValue?: boolean) => {
      const update = nextValue ?? !get(booleanAtom);
      set(booleanAtom, update);
    },
  );

  return booleanAtom;
};

const defaultComparisonFn = (a: unknown, b: unknown) => a === b;

// If the neeValue exists in the array is removed, if not, it is added
export const atomWithArrayToggle = <K>(
  initialValue: K[],
  comparisonFn = defaultComparisonFn,
) => {
  const arrayAtom = atom(initialValue, (get, set, nextValue: K | K[]) => {
    const currentValues = get(arrayAtom);
    const index = currentValues.findIndex((value) => comparisonFn(value, nextValue));
    if (index >= 0) {
      const filteredArray = [...currentValues];
      filteredArray.splice(index, 1);
      set(arrayAtom, filteredArray);
    } else {
      set(arrayAtom, currentValues.concat(nextValue));
    }
  });

  return arrayAtom;
};

// Add or remove values to an array explicitly, not allowing repeated values
// If an array is passed as a value type, it is set as the new value
export const atomWithArraySet = <T>(initialValue: T[]) => {
  const arrayAtom = atom(initialValue, (get, set, { nextValue, add = true }) => {
    if (Array.isArray(nextValue)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(arrayAtom, nextValue as any);
    } else {
      const currentValues = get(arrayAtom);
      if (add) {
        if (!currentValues.includes(nextValue)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          set(arrayAtom, currentValues.concat(nextValue) as any);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        set(arrayAtom, currentValues.filter((value) => value !== nextValue) as any);
      }
    }
  });

  return arrayAtom;
};

const removeItemFromArray = <K>(array: K[], index: number) => {
  const filteredArray = [...array];
  filteredArray.splice(index, 1);
  return filteredArray;
};

const ReduceActions = {
  TOGGLE: 'toggle',
  ADD: 'add',
  REMOVE: 'remove',
  CLEAR: 'clear',
  SET: 'set',
} as const;

type ReduceActionsType = ObjectValues<typeof ReduceActions>;
type Action<K> = { type: ReduceActionsType; value?: K | K[] };

export const atomArrayWithReducer = <K>(initialValue: K[] = []) => {
  const arrayAtom = atomWithReducer<K[], Action<K>>(
    initialValue,
    (prevArr: K[], action?: Action<K>) => {
      const type = action?.type ?? 'toggle';
      const value = action?.value;
      const index = Array.isArray(value)
        ? null
        : prevArr.findIndex((prev) => prev === value);

      if (
        value !== undefined &&
        index !== null &&
        index < 0 &&
        (type === 'add' || type === 'toggle')
      ) {
        return prevArr.concat(value);
      } else if (
        value !== undefined &&
        index !== null &&
        index >= 0 &&
        (type === 'remove' || type === 'toggle')
      ) {
        return removeItemFromArray(prevArr, index);
      } else if (type === 'clear') {
        return [];
      } else if (value !== undefined && type === 'set') {
        return Array.isArray(value) ? value : [value];
      }

      return prevArr;
    },
  );
  return arrayAtom as WritableAtom<K[], [Action<K>], void>;
};

export const useAtomArrayWithReducerActions = <K>(
  arrayAtomWithReducer: WritableAtom<K[], [Action<K>], void>,
) => {
  const [array, dispatch] = useAtom(arrayAtomWithReducer);
  const toggle = (value: K) => {
    dispatch({ value, type: 'toggle' });
  };

  const clear = () => dispatch({ type: 'clear' });
  const set = (value: K | K[]) => dispatch({ value, type: 'set' });
  const remove = (value: K) => dispatch({ value, type: 'remove' });
  const add = (value: K) => dispatch({ value, type: 'add' });

  return { array, toggle, clear, set, add, remove };
};

export const atomWithOptionalLocalStorage = (key: string, initialValue: unknown) => {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return JSON.parse(item);
    }
    return initialValue;
  };
  const baseAtom = atom(getInitialValue());
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update) => {
      const { value, persist } =
        typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, value);
      if (persist) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, JSON.stringify(initialValue));
      }
    },
  );
  return derivedAtom;
};

type UseAtomWithUserIdType<T, K> = (atom: K) => [value: T, setValue: (value: T) => void];
type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

type AtomWithStorage<V> = WritableAtom<V, [SetStateActionWithReset<V>], V>;
type AtomValue =
  | { isStorageAtomValue: boolean; [key: number]: unknown | null }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
  | null;

export const useAtomWithUserId: UseAtomWithUserIdType<
  AtomValue,
  AtomWithStorage<AtomValue>
> = (atom) => {
  const [atomData, setAtomData] = useAtom(atom) as unknown as [
    atomData: AtomValue,
    setAtomData: (value: unknown) => void,
  ];
  const { currentUser } = useCurrentUser() as unknown as { currentUser?: User };
  const setValue = (value: unknown) => {
    if (currentUser?.pk) {
      const prevData = atomData?.isStorageAtomValue ? atomData : {};
      setAtomData({
        ...prevData,
        [currentUser.pk]: value,
        isStorageAtomValue: true,
      });
    }
  };
  // To set the initialValue
  useEffect(() => {
    if (atomData !== undefined && !atomData?.isStorageAtomValue) {
      setValue(atomData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atomData]);
  const value =
    currentUser?.pk && atomData && atomData[currentUser.pk] !== undefined
      ? atomData[currentUser.pk]
      : null;
  return [value, setValue];
};
