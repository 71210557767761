import { isEmpty } from 'lodash-es';
import { useState, useEffect, useMemo } from 'react';
import avatar from 'assets/img/faces/face-0.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Grid, IconButton, Menu, Typography } from '@mui/material';

import { faClockNine, faRedo } from '@fortawesome/pro-light-svg-icons';
import { useCurrentUser } from 'features/Auth/hook/session';
import { ProjectMember } from 'types/ProjectMember';
import { useProjectMemberInvite } from 'features/ManageUsers/hooks/projectMemberInvite';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { IconMenuItem } from 'components/IconMenuItem/IconMenuItem';
import { DeleteMenuItem } from 'components/IconMenuItem/DeleteMenuItem';
import { getMemberFullnameOrEmail } from 'utils/helpers';
import { usePermissionRoles } from 'features/ManageUsers/hooks/permissionRoles';

type ProjectMemberRowProps = {
  member: ProjectMember;
  onRemoveMember: (member: ProjectMember) => void;
};

export const ProjectMemberRow = ({ member, onRemoveMember }: ProjectMemberRowProps) => {
  // Global state
  const { currentUser } = useCurrentUser();

  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();

  const { sendInviteMutation } = useProjectMemberInvite();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  // Local state
  const [roleName, setRoleName] = useState('');
  useEffect(() => {
    if (!roles || !member) {
      return;
    }
    const role =
      !isEmpty(member.roles) && roles.find((role) => role.id === member.roles[0]);
    if (role) {
      setRoleName(role.name);
    }
  }, [member, roles]);

  const isCurrentUser = useMemo(
    () => currentUser && currentUser.email === member.email,
    [member, currentUser],
  );
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  return (
    <Grid container sx={{ marginBottom: (theme) => theme.spacing(2) }}>
      <Grid item xs={8}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={avatar}
            sx={{
              width: '24px',
              height: '24px',
              borderRadius: '30px',
              marginRight: '5px',
            }}
          />
          <Typography
            noWrap
            sx={{
              // Leave room for the chip if invite is pending
              maxWidth: member.is_invite_pending ? '50%' : '100%',
              textOverflow: 'ellipsis',
            }}
          >
            {getMemberFullnameOrEmail(member)} {isCurrentUser && '(You)'}
          </Typography>
          {member.is_invite_pending && (
            <Chip
              size="small"
              sx={{
                marginLeft: (theme) => theme.spacing(1),
                backgroundColor: 'categoricYellow.100',
                color: 'categoricYellow.900',
                '.MuiChip-icon': {
                  color: 'categoricYellow.900',
                },
              }}
              icon={
                <Box
                  component={FontAwesomeIcon}
                  icon={faClockNine}
                  sx={{ height: '0.7em' }}
                />
              }
              label="Pending response"
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={2} justifyContent="end">
        <Box sx={{ mr: 3 }}>{roleName}</Box>
      </Grid>
      {!isCurrentUser ? (
        <Grid item xs={2} justifyContent="end">
          <IconButton
            id="ellipsis-button"
            aria-controls={isMenuOpen ? 'actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={(event) => setMenuAnchorEl(event.currentTarget)}
            sx={{ padding: '3px 10px', color: 'grey.400' }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
          </IconButton>
          <Menu
            id="actions-menu"
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={() => setMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'ellipsis-button',
            }}
          >
            {/* To be Implemented on CPE-603
              {roles.map((role) => (
              <MenuItem key={role.id} value={role.name}>
                {role.name}
              </MenuItem>
            ))} */}
            {member.is_invite_pending ? (
              <IconMenuItem
                disabled={sendInviteMutation.isLoading}
                icon={faRedo}
                onClick={() => {
                  if (!selectedProjectId) return;
                  sendInviteMutation.mutate(
                    {
                      projectId: selectedProjectId,
                      email: member.email,
                      roleId: member.roles[0],
                      accessScopeId: member.access_scopes[0].id,
                    },
                    {
                      onSuccess: () => {
                        setMenuAnchorEl(null);
                        successToast({
                          title: 'Project Member Invitation',
                          text: 'Invitation successfully sent',
                        });
                      },
                    },
                  );
                }}
              >
                Resend invite
              </IconMenuItem>
            ) : null}
            <DeleteMenuItem onClick={() => onRemoveMember(member)}>Remove</DeleteMenuItem>
          </Menu>
        </Grid>
      ) : null}
    </Grid>
  );
};
