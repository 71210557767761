import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';

import { ReportDownloadButtons } from 'features/Reports/components/ReportDownloadButtons';
import { ReportItem, ReportListProps } from 'types/Reports';

export const ReporTableList = ({ reports }: ReportListProps) => {
  const columns: GridColDef<ReportItem>[] = [
    {
      field: 'name',
      headerName: 'Report',
      flex: 1,
      width: 100,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      sortable: false,
    },
    {
      field: 'formats',
      headerName: 'Formats',
      width: 250,
      sortable: false,
      renderCell: (params) => <ReportDownloadButtons report={params.row} />,
    },
  ];

  return (
    <DataGridPremium
      rows={reports}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      hideFooter
      sx={{
        mt: 2,
      }}
    />
  );
};
