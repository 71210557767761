import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser, useCurrentUserCompanies } from 'features/Auth/hook/session';
import { Company, UserCompany } from 'types/Company';
import { queryKeys } from 'utils/reactQuery';
import { useAttachmentsByEntity, useUploadAttachments } from 'hooks/attachments';

const companyKey = ['companyProfile'];
// TODO: Check where are we using this and if it's still needed
export const useCompany = () => {
  const { currentCompany } = useCurrentCompany();
  const {
    data: company,
    isLoading: isCompanyLoading,
    isFetching: isCompanyFetching,
  } = useQuery(
    companyKey,
    ({ signal }) => {
      if (!currentCompany) throw new Error('Current company not found');
      const endPoint = Resources.COMPANY_BY_ID.replace(
        '<int:pk>',
        String(currentCompany.id),
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as Company);
    },
    {
      staleTime: Infinity,
      enabled: !!currentCompany,
    },
  );
  return { company, isCompanyLoading, isCompanyFetching };
};

export const useCurrentCompany = () => {
  const { currentUserCompaniesQuery } = useCurrentUserCompanies();
  const { currentUser } = useCurrentUser();

  const currentUrl = new URL(window.location.href);
  const subdomain = currentUrl.host.split('.')[0];

  let currentCompany: UserCompany | undefined = currentUserCompaniesQuery.data?.find(
    ({ slug }) => slug === subdomain,
  );
  if (
    !currentUser ||
    !currentCompany ||
    !currentUser.profile.companies.includes(currentCompany.id)
  ) {
    currentCompany = undefined;
  }

  return {
    currentCompany,
  };
};

export function useCurrentUserCompanyLogo() {
  const { currentCompany } = useCurrentCompany();

  const { attachmentsByEntityQuery } = useAttachmentsByEntity({
    entityName: 'company',
    entityId: currentCompany?.id,
  });
  const companyLogoQuery = {
    ...attachmentsByEntityQuery,
    data: attachmentsByEntityQuery.data?.[attachmentsByEntityQuery.data.length - 1],
  };
  return {
    companyLogoQuery,
  };
}

export const useSaveCompanyProfile = () => {
  const queryClient = useQueryClient();
  const { currentCompany } = useCurrentCompany();

  const saveCompanyProfileMutation = useMutation(
    (updatedCompany: Partial<Company>) => {
      if (!currentCompany)
        return Promise.reject(new Error('Current company profile not found'));
      const endPoint = Resources.COMPANY_BY_ID.replace(
        '<int:pk>',
        String(currentCompany.id),
      );
      return ApiService.patch(endPoint, updatedCompany).then(
        (res) => res.data as Company,
      );
    },
    {
      onMutate: async (updatedCompany) => {
        await queryClient.cancelQueries(queryKeys.currentUserCompanies);

        const previousCompanies = queryClient.getQueryData(
          queryKeys.currentUserCompanies,
        ) as UserCompany[];
        if (previousCompanies) {
          queryClient.setQueryData(
            queryKeys.currentUserCompanies,
            (oldCompanies?: Company[]) => {
              return oldCompanies?.map((oldCompany) =>
                oldCompany.id === updatedCompany.id
                  ? { ...oldCompany, ...updatedCompany }
                  : oldCompany,
              );
            },
          );
        }

        return { previousCompanies };
      },
      onError: (error, _linkId, context) => {
        if (context?.previousCompanies) {
          queryClient.setQueryData(
            queryKeys.currentUserCompanies,
            context.previousCompanies,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.currentUserCompanies);
        queryClient.invalidateQueries(queryKeys.userProfile);
      },
    },
  );
  return { saveCompanyProfileMutation };
};

export const useSaveCompanyLogo = () => {
  const queryClient = useQueryClient();
  const { currentCompany } = useCurrentCompany();
  const { uploadAttachmentsMutation } = useUploadAttachments();
  const { currentUser } = useCurrentUser();

  const saveCompanyLogoMutation = useMutation(
    (file: File) => {
      if (!currentCompany) throw new Error('User current company not set');
      return uploadAttachmentsMutation.mutateAsync({
        entityName: 'company',
        entityId: currentCompany.id,
        files: [{ file, name: 'logo' }],
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKeys.companyLogo(currentUser?.email), data);
      },
    },
  );
  return { saveCompanyLogoMutation };
};
