import { PrivateURL } from 'Urls';
import usePermissions from 'features/Auth/hook/usePermissions';
import { Permissions } from 'features/Auth/Permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { SxProps, Theme } from '@mui/material';
import { NavBarButton } from 'components/NavBarButton/NavBarButton';

type NewProjectButtonProps = {
  sx: SxProps<Theme>;
};

export const NewProjectButton = (props: NewProjectButtonProps) => {
  const { HasPerm } = usePermissions();

  const location = useLocation();

  return (
    <HasPerm permission={Permissions.ADD_PROJECT}>
      <NavBarButton
        to={PrivateURL.PROJECT_NEW_OPTIONS}
        icon={<FontAwesomeIcon icon={faPlus} />}
        {...props}
        isActive={location.pathname === PrivateURL.PROJECT_NEW_OPTIONS}
      >
        Start project
      </NavBarButton>
    </HasPerm>
  );
};
