import { Paper, PaperProps } from '@mui/material';
import { joinSx } from 'utils/helpers';

type AuthPagePaperProps = PaperProps;

export function AuthPagePaper({ sx, ...props }: AuthPagePaperProps) {
  return (
    <Paper
      {...props}
      sx={joinSx(
        (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          top: theme.spacing(8),
          width: 'min(456px, 90vw)',
          padding: theme.spacing(5),
          borderRadius: '10px',
          boxShadow:
            '0px 9px 38px rgba(11, 82, 91, 0.07), 0px 7.66234px 23.7319px rgba(11, 82, 91, 0.0321005), 0px 6.91784px 16.9409px rgba(11, 82, 91, 0.022576), 0px 4.61181px 9.64801px rgba(11, 82, 91, 0.0131721)',
        }),
        sx,
      )}
    ></Paper>
  );
}
