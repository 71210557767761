import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/project';
import { ItemOrScenarioStatus } from 'types/Item';
import { Scenario } from 'types/Scenario';
import { atomArrayWithReducer, useAtomArrayWithReducerActions } from 'utils/atoms';
import { useItemsFocus } from './itemsFocus';
import log from 'loglevel';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { atom, useAtom } from 'jotai';

const scenariosFocusAtom = atomArrayWithReducer<number>([]);
scenariosFocusAtom.debugLabel = 'scenariosFocusAtom';
const scenariosHoverAtom = atom<Scenario | null>(null);
scenariosHoverAtom.debugLabel = 'scenariosHoverAtom';

export const useScenarios = () => {
  const { selectedProjectId } = useSelectedProjectId();

  const { itemsFocus } = useItemsFocus();

  const {
    data: allScenarios,
    isLoading: isScenariosLoading,
    isFetching: isScenariosFetching,
    refetch: scenariosRefetch,
    isRefetching: isScenariosRefetching,
  } = useQuery(
    queryKeys.project(selectedProjectId).scenarios,
    ({ signal }) => {
      const endPoint = Resources.ALL_SCENARIOS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedProjectId!.toString(),
      );
      const params = {
        // Scenario status key
        status: itemsFocus,
      };
      return ApiService.get(endPoint, { signal, params }).then(
        (res) => res.data as Scenario[],
      );
    },
    {
      enabled: !!selectedProjectId,
      placeholderData: [],
      refetchOnWindowFocus: false,
      // We will manually trigger every time we need to refetch the scenarios list
      staleTime: Infinity,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );

  const scenarios = allScenarios ?? [];

  const isScenariosBusy =
    isScenariosLoading || isScenariosFetching || isScenariosRefetching;

  return {
    scenarios,
    isScenariosLoading,
    isScenariosFetching,
    scenariosRefetch,
    isScenariosRefetching,
    isScenariosBusy,
  };
};

export const useScenario = (scenarioId: number | undefined) => {
  const { scenarios, isScenariosBusy } = useScenarios();

  return {
    scenario: scenarios?.find((scenario: Scenario) => scenario.id === scenarioId),
    isScenariosBusy,
  };
};

export const useScenariosFocus = () => {
  const {
    array: scenariosFocus,
    toggle,
    clear: scenariosFocusClear,
    set: setScenarioFocus,
    add: addScenarioFocus,
    remove,
  } = useAtomArrayWithReducerActions(scenariosFocusAtom);
  const [scenarioHover, setScenarioHover] = useAtom(scenariosHoverAtom);

  const { resetItemsFocus } = useItemsFocus();

  const toggleScenariosFocus = (scenarioId: number) => {
    // will be removed
    if (scenariosFocus.includes(scenarioId)) {
      resetItemsFocus();
    }
    toggle(scenarioId);
  };

  const removeScenarioFocus = (scenarioId: number) => {
    resetItemsFocus();
    remove(scenarioId);
  };

  return {
    scenariosFocus,
    toggleScenariosFocus,
    scenariosFocusClear,
    setScenarioFocus,
    addScenarioFocus,
    removeScenarioFocus,
    scenarioHover,
    setScenarioHover,
  };
};

const isNewScenario = (scenarioData: Partial<Scenario>) =>
  !scenarioData || scenarioData.id === undefined;

export const useSaveScenario = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const queryClient = useQueryClient();
  const { assignItemsToScenario } = useAssignItemsToScenario();
  const { itemsFocus, resetItemsFocus } = useItemsFocus();
  const { setScenarioFocus } = useScenariosFocus();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: saveScenario, isLoading: isSaveScenarioLoading } = useMutation(
    (scenarioData: Partial<Scenario>) => {
      let request;
      if (isNewScenario(scenarioData)) {
        if (selectedProjectId !== null) {
          const endPoint = Resources.ALL_SCENARIOS_BY_PROJECT_ID.replace(
            '<int:project_pk>',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selectedProjectId!.toString(),
          );
          request = ApiService.post(endPoint, scenarioData);
        } else {
          throw new Error('There is not selected Project');
        }
      } else {
        const endPoint = Resources.SCENARIO_BY_ID.replace(
          '<int:pk>',
          (scenarioData.id as number).toString(),
        );

        request = ApiService.patch(endPoint, scenarioData);
      }
      return request.then((res) => res.data);
    },
    {
      onMutate: async (scenarioData) => {
        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).scenarios);
        const previousScenarios = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).scenarios,
        );

        if (previousScenarios && !isNewScenario(scenarioData)) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            (oldScenarios?: Scenario[]) =>
              oldScenarios?.map((oldScenario) =>
                oldScenario.id === scenarioData.id
                  ? { ...oldScenario, ...scenarioData }
                  : oldScenario,
              ),
          );
        }
        return previousScenarios;
      },
      onSuccess: (result: Scenario, scenarioData) => {
        if (isNewScenario(scenarioData)) {
          const scenarioId = result.id;
          assignItemsToScenario({ scenarioId, items: itemsFocus });
          setScenarioFocus([scenarioId]);
          resetItemsFocus();
        }
      },
      onError: (error, _scenarioData, previousScenarios) => {
        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            previousScenarios,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
        invalidateTVDQueries(selectedProjectId);
        // TODO: invalidate items?
      },
    },
  );

  return { saveScenario, isSaveScenarioLoading };
};

export const useDeleteScenario = () => {
  const queryClient = useQueryClient();
  const { resetItemsFocus } = useItemsFocus();
  const { removeScenarioFocus } = useScenariosFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: deleteScenario, isLoading: isDeleteScenarioLoading } = useMutation(
    (scenarioId: number) => {
      const endPoint = Resources.SCENARIO_BY_ID.replace(
        '<int:pk>',
        scenarioId.toString(),
      );
      return ApiService.delete(endPoint, {}).then((res) => res.data);
    },
    {
      onMutate: async (scenarioId) => {
        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).scenarios);
        const previousScenarios = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).scenarios,
        ) as Scenario[] | undefined;

        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            (oldScenarios: Scenario[] | undefined) =>
              oldScenarios?.filter((oldScenario) => oldScenario.id !== scenarioId),
          );
        }
        return previousScenarios;
      },
      onSuccess: (_data, scenarioId) => {
        successToast({
          title: 'Scenario deleted',
          text: 'Scenario has been deleted successfully',
        });
        removeScenarioFocus(scenarioId);
        resetItemsFocus();
      },
      onError: (error, _scenarioId, previousScenarios) => {
        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            previousScenarios,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).items);
        invalidateTVDQueries(selectedProjectId);
      },
    },
  );

  return { deleteScenario, isDeleteScenarioLoading };
};

type AssignItemsToScenarioArgs = {
  scenarioId: number;
  items: number[];
};

export const useAssignItemsToScenario = () => {
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();

  const { mutate: assignItemsToScenario } = useMutation(
    ({ scenarioId, items }: AssignItemsToScenarioArgs) => {
      const endPoint = Resources.BATCH_ASSIGN_BY_SCENARIO_ID.replace(
        '<int:scenario_pk>',
        scenarioId.toString(),
      );
      return ApiService.put(endPoint, { items }).then((res) => res.data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).items);
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
      },
    },
  );
  return { assignItemsToScenario };
};

export const useApproveScenario = () => {
  const queryClient = useQueryClient();
  const { removeScenarioFocus } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: approveScenario } = useMutation(
    ({ scenarioId, milestoneId }: { scenarioId: number; milestoneId: number | null }) => {
      const endPoint = Resources.APPROVE_SCENARIO_BY_ID.replace(
        '<int:pk>',
        scenarioId.toString(),
      );
      return ApiService.patch(endPoint, {
        milestone: milestoneId,
      }).then((res) => res.data);
    },
    {
      onMutate: async ({ scenarioId }) => {
        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).scenarios);
        const previousScenarios = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).scenarios,
        );

        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            (oldScenarios: Scenario[] | undefined) =>
              oldScenarios?.map((oldScenario) =>
                oldScenario.id === scenarioId
                  ? { ...oldScenario, status: 'APPROVED' as ItemOrScenarioStatus }
                  : oldScenario,
              ),
          );
        }
        return previousScenarios;
      },
      onSuccess: (_data, { scenarioId }) => {
        successToast({
          title: 'Scenario approved',
          text: 'Scenario has been approved successfully',
        });
        removeScenarioFocus(scenarioId);
        resetItemsFocus();
      },
      onError: (error, _scenarioId, previousScenarios) => {
        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            previousScenarios,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).items);
        invalidateTVDQueries(selectedProjectId);
      },
    },
  );
  return { approveScenario };
};

export const useRejectScenario = () => {
  const queryClient = useQueryClient();
  const { removeScenarioFocus } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { selectedProjectId } = useSelectedProjectId();
  const { successToast } = useToastDialogs();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const { mutate: rejectScenario } = useMutation(
    ({ scenarioId }: { scenarioId: number }) => {
      const endPoint = Resources.REJECT_SCENARIO_BY_ID.replace(
        '<int:pk>',
        scenarioId.toString(),
      );
      return ApiService.patch(endPoint).then((res) => res.data);
    },
    {
      onMutate: async ({ scenarioId }) => {
        removeScenarioFocus(scenarioId);
        resetItemsFocus();

        await queryClient.cancelQueries(queryKeys.project(selectedProjectId).scenarios);
        const previousScenarios = queryClient.getQueryData(
          queryKeys.project(selectedProjectId).scenarios,
        );

        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            (oldScenarios: Scenario[] | undefined) =>
              oldScenarios?.map((oldScenario) =>
                oldScenario.id === scenarioId
                  ? { ...oldScenario, status: 'REJECTED' as ItemOrScenarioStatus }
                  : oldScenario,
              ),
          );
        }
        return previousScenarios;
      },
      onSuccess: () => {
        successToast({
          title: 'Scenario rejected',
          text: 'Scenario has been rejected successfully',
        });
      },
      onError: (error, _scenarioId, previousScenarios) => {
        if (previousScenarios) {
          queryClient.setQueryData(
            queryKeys.project(selectedProjectId).scenarios,
            previousScenarios,
          );
        }
        log.error(error instanceof Error ? error.message : error);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).scenarios);
        queryClient.invalidateQueries(queryKeys.project(selectedProjectId).items);
        invalidateTVDQueries(selectedProjectId);
      },
    },
  );
  return { rejectScenario };
};
