import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import log from 'loglevel';

import { LicenseInfo } from '@mui/x-license-pro';
import { Router, Switch } from 'react-router-dom';
import { Layout, History } from 'Urls';

import 'keen-slider/keen-slider.min.css';

import theme from './style/theme';

import GA from 'services/GoogleAnalytics';

import { Sentry } from 'services/Sentry';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Provider as JotaiProvider } from 'jotai';

import { queryClient } from 'utils/reactQuery';
import { IntercomProvider } from 'react-use-intercom';
import { PrivateRoute } from 'components/PrivateRoute';
import { PublicRoute } from 'components/PublicRoute';
import { isDev, isProd } from 'utils/environment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserGuiding } from './services/UserGuiding';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import PwaProvider from 'components/PwaProvider/PwaProvider';
import { AtomsDevtools } from 'components/AtomsDevtools/AtomsDevtools';
import { Init } from 'components/Init/Init';

if (isDev()) setUseWhatChange({ active: true });

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const logLevel = import.meta.env.VITE_LOG_LEVEL || 'info';
log.setLevel(logLevel);
window.log = log;

root.render(
  <StrictMode>
    <IntercomProvider appId={import.meta.env.VITE_INTERCOM}>
      <PwaProvider>
        <Sentry>
          <JotaiProvider>
            <UserGuiding />
            <AtomsDevtools>
              <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <DndProvider backend={HTML5Backend}>
                      <Router history={History}>
                        {isProd() && GA.init() && <GA.RouteTracker />}
                        <Init />
                        <Switch>
                          <PublicRoute path={Layout.PUBLIC} />
                          <PrivateRoute path={Layout.PRIVATE} />
                          <PrivateRoute path={Layout.ROOT} />
                        </Switch>
                      </Router>
                    </DndProvider>
                  </ThemeProvider>
                </LocalizationProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </AtomsDevtools>
          </JotaiProvider>
        </Sentry>
      </PwaProvider>
    </IntercomProvider>
  </StrictMode>,
);
