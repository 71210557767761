import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import axios from 'axios';
import { ItemDetailsId } from 'features/Foresite/hooks/ui';
import { RiskOrDraft } from 'types/Risk';
import { isEqual } from 'lodash';
import { CostGroupStandard } from 'types/CostGroup';
import { Dimension } from 'types/ForeSite';
import { SettingConfigKey } from 'types/Setting';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount >= 3) return false;
        if (error instanceof Error && axios.isAxiosError(error.cause)) {
          return ![404, 409].includes(error.cause.response?.status as number);
        }
        return true;
      },
    },
  },
});

export const queryKeys = {
  currentUserCompanies: ['currentUserCompanies'],
  permissionRoles: ['permissionRoles'],
  accessScopes: ['accessScopes'],
  companyUsers: ['companyUsers'],
  permissionRolePermissions: (roleId?: number) => ['permissionRolePermissions', roleId],
  projects: ['projects'],
  project: (projectId: number | null | undefined) => {
    const base = ['project', projectId] as const;
    return {
      details: base,
      milestones: [...base, 'milestones'],
      risks: [...base, 'risks'],
      items: [...base, 'items'],
      components: [...base, 'components'],
      componentAreas: [...base, 'componentAreas'],
      scenarios: [...base, 'scenarios'],
      bidCostGroupsWithMilestone: [...base, 'bidCostGroupsWithMilestone'],
      bidPackages: [...base, 'bidPackages'],
      procurementSpecifications: [...base, 'procurementSpecifications'],
      procurementStatuses: [...base, 'procurementStatuses'],
      foresiteCost: [...base, 'foresiteCost'],
      foresiteProgram: [...base, 'foresiteProgram'],
      foresiteSummary: [...base, 'foresiteSummary'],
      tvdForesite: [...base, 'tvdForesite'],
      tvdForesiteBudgetAdjusted: [...base, 'tvdForesite', 'budgetAdjusted'],
      tvdForesiteTargetDimension: (
        targetDimension: Dimension,
        perSquareFootQueryParam: string,
      ) => [...base, 'tvdForesite', targetDimension, perSquareFootQueryParam],
      tvdCalibrate: [...base, 'tvdCalibrate'],
      calibrateByProject: [...base, 'calibrateByProject'],
      calibrateByDivision: [...base, 'calibrateByDivision'],
      calibrateByDivisionComparison: (
        costGroupStandard: CostGroupStandard | 'CF',
        compareProjectIds?: number[],
      ) => [
        ...base,
        'calibrateByDivisionComparison',
        costGroupStandard,
        compareProjectIds,
      ],
      calibrateByDivisionNormalization: (
        compareProjectIds: number[] | undefined,
        normalizeByTime: boolean | undefined,
        normalizeByLocation: boolean | undefined,
      ) => [
        ...base,
        'calibrateByDivisionNormalization',
        compareProjectIds,
        normalizeByTime,
        normalizeByLocation,
      ],
      normalizationTimes: (otherProjectsIds: number[]) => [
        ...base,
        'normalizationTimes',
        ...otherProjectsIds,
      ],
      dimensionMemberParams: (dimensionType: string | undefined) => {
        const dimensionBase = [
          ...base,
          'TvdDimensionMemberParams',
          dimensionType,
        ] as const;
        return {
          base: dimensionBase,
          withId: (dimensionId: number | undefined) => [...dimensionBase, dimensionId],
        } as const;
      },

      dimensionsUsage: [...base, 'dimensionsUsage'],
      members: [...base, 'members'],
      settings: (key: SettingConfigKey) => {
        const settingsBase = [...base, 'settings', key];
        return {
          base: settingsBase,
          mutation: [...settingsBase, 'mutation'],
        };
      },
    } as const;
  },
  legacyProject: (legacyProjectId: number | null | undefined) => {
    const base = ['legacyProject', legacyProjectId] as const;
    return {
      milestone: [...base, 'milestone'],
    } as const;
  },
  risk: (riskId: RiskOrDraft['id'] | undefined) => {
    const base = ['risk', riskId] as const;
    return {
      details: base,
      comments: [...base, 'comments'],
    } as const;
  },
  milestone: (milestoneId: number | null | undefined) => {
    const base = ['milestone', milestoneId] as const;
    return {
      estimateMarkups: [...base, 'estimateMarkups'],
      subtotals: [...base, 'subtotals'],
      ownerCosts: [...base, 'ownerCosts'],
      ownerCost: (ownerCostId: number | null | undefined) => [
        ...base,
        'ownerCosts',
        ownerCostId,
      ],
    } as const;
  },
  item: (itemId?: ItemDetailsId | null) => {
    const base = ['item', itemId] as const;
    return {
      details: base,
      subItems: [...base, 'subItems'],
      mutuallyExclusiveItems: [...base, 'mutuallyExclusiveItems'],
      comments: [...base, 'comments'],
    } as const;
  },
  subItem: (subItemId?: number) => {
    const base = ['subItem', subItemId] as const;
    return {
      details: base,
    } as const;
  },
  approvalItem: (approvalItemId?: number | null) => {
    const base = ['approvalItem', approvalItemId] as const;
    return {
      details: base,
      subItems: [...base, 'subItems'],
    } as const;
  },
  idea: (ideaId?: number | false | 'new' | null) => {
    const base = ['idea', ideaId] as const;
    return {
      details: base,
    } as const;
  },
  bidPackage: (bidPackageId: number | null) => {
    const base = ['bidPackage', bidPackageId] as const;
    return {
      details: base,
      comments: [...base, 'comments'],
      links: [...base, 'links'],
    } as const;
  },
  companyProfile: ['companyProfile'],
  company: (companyId: number | undefined) => {
    const base = ['company', companyId] as const;
    return {
      customCostGroupDefinitions: [...base, 'customCostGroupDefinitions'],
      customCostGroupDefinition: (definition: number | null | undefined) => [
        ...base,
        'customCostGroupDefinition',
        definition,
      ],
      ideas: [...base, 'ideas'],
    } as const;
  },
  projectDetailField: (fieldType: string) => [
    'projectCustomField',
    fieldType.toLowerCase(),
  ],
  attachmentsByEntity: (entityName: string, entityId: number) => [
    entityName,
    entityId,
    'attachments',
  ],
  riskCategories: ['riskCategories'],
  deliveryContractTypes: ['deliveryContractTypes'],
  companyLogo: (currentUserEmail: string | undefined) => [
    'companyLogo',
    currentUserEmail,
  ],
  costGroup: (standard: CostGroupStandard) => ['costGroup', standard],
  categories: ['categories'],
  itemUnits: ['itemUnits'],
  componentBaseUnits: ['componentBaseUnits'],
  componentBaseUnitCount: (componentId: number | undefined) => {
    const base = ['componentBaseUnitCount', componentId] as const;
    return {
      details: base,
    } as const;
  },
  userProfile: ['userProfile'],
  notifications: ['notifications'],
  portfolioSummaryData: ['portfolioSummaryData'],
  portfolioProjectsBudgetDelta: (projectIds: number[]) => [
    'portfolioProjectsBudgetDelta',
    projectIds.join(),
  ],
  portfolioProjectValueOptions: (projectIds: number[]) =>
    ['portfolioProjectValueOptions'].concat(projectIds.length ? projectIds.join() : []),
  portfolioProjectFeeOverTime: (projectIds: number[]) =>
    ['portfolioProjectFeeOverTime'].concat(projectIds.length ? projectIds.join() : []),
  portfolioDiversity: ['portfolioDiversity'],
  costGroupSystems: ['costGroupSystems'],
  costGroupCategories: ['costGroupCategories'],
  tenantFeatureFlags: ['tenantFeatureFlags'],
  roles: ['roles'],
} as const;

const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const queryKeysToPersist = [
  queryKeys.categories,
  queryKeys.costGroup('MF'),
  queryKeys.costGroup('UF'),
  queryKeys.itemUnits,
  queryKeys.componentBaseUnits,
];

persistQueryClient({
  queryClient,
  persister: syncStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => {
      return !!queryKeysToPersist.find((qk) => isEqual(qk, queryKey));
    },
  },
});

export const persistedQueryOptions = {
  // as query is persisted, should not have staleTime: Infinity
  staleTime: 24 * 60 * 60 * 1000, // 1 day
  refetchOnWindowFocus: false,
};
