import { useMutation, useQuery } from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { ApiService } from 'api/ApiService';
import { queryClient, queryKeys } from 'utils/reactQuery';
import { UserProfile } from 'types/User';
import log from 'loglevel';

export const useUserProfile = () => {
  const { data } = useQuery(
    queryKeys.userProfile,
    ({ signal }) => {
      const endPoint = Resources.CURRENT_USER_PROFILE;
      return ApiService.get(endPoint, { signal }).then((res) => res.data as UserProfile);
    },
    {
      staleTime: Infinity,
      placeholderData: {} as UserProfile,
      onError: (error) => log.error(error instanceof Error ? error.message : error),
    },
  );
  return data;
};

export const useSaveUserProfile = () => {
  const endPoint = Resources.CURRENT_USER_PROFILE;

  const {
    mutate: saveUserProfile,
    isLoading: isSavingUserProfileChanges,
    isError: errorUpdatingProfile,
    isSuccess: successfullyUpdate,
  } = useMutation(
    (userProfile: Partial<UserProfile>) => {
      const request = ApiService.patch(endPoint, userProfile);
      return request.then((res) => res.data as UserProfile);
    },
    {
      onSuccess: (updatedUserProfile) => {
        queryClient.setQueryData(queryKeys.userProfile, () => updatedUserProfile);
      },
      onError: (error) => {
        log.error(error instanceof Error ? error.message : error);
      },
    },
  );

  return {
    saveUserProfile,
    isSavingUserProfileChanges,
    errorUpdatingProfile,
    successfullyUpdate,
  };
};
